import * as THREE from 'three'

export enum BoxGeometryVerticeIndex {
  BottomLeft = 0,
  BottomCenter = 1,
  TopLeft = 2,
  TopCenter = 3,
  BottomRight = 4,
  TopRight = 5,
}

export const createTwoSidedBoxGeometry = (boxSize = 50) => {
  // Create Box Mesh
  const boxGeometry = new THREE.Geometry()

  //Create two perpendicular squares (as if they were two neighboring surfaces of a cube)
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, -boxSize, -boxSize)) // back bottom left
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, -boxSize, -boxSize)) // back bottom right
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, boxSize, -boxSize)) // back top left
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, boxSize, -boxSize)) // back top right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, -boxSize, boxSize)) // front bottom right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, boxSize, boxSize)) // front top right

  // Create back side via two triangles
  boxGeometry.faces.push(new THREE.Face3(
    BoxGeometryVerticeIndex.BottomLeft,
    BoxGeometryVerticeIndex.BottomCenter,
    BoxGeometryVerticeIndex.TopLeft,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    BoxGeometryVerticeIndex.BottomCenter,
    BoxGeometryVerticeIndex.TopCenter,
    BoxGeometryVerticeIndex.TopLeft,
  ))

  // Create right side
  boxGeometry.faces.push(new THREE.Face3(
    BoxGeometryVerticeIndex.BottomCenter,
    BoxGeometryVerticeIndex.BottomRight,
    BoxGeometryVerticeIndex.TopCenter,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    BoxGeometryVerticeIndex.TopCenter,
    BoxGeometryVerticeIndex.BottomRight,
    BoxGeometryVerticeIndex.TopRight,
  ))

  boxGeometry.computeFaceNormals()
  boxGeometry.computeVertexNormals()

  // Map texture (= video) to previously defined faces
  boxGeometry.faceVertexUvs[0][0] = [new THREE.Vector2(0, 0), new THREE.Vector2(0.5, 0), new THREE.Vector2(0, 1)]
  boxGeometry.faceVertexUvs[0][1] = [new THREE.Vector2(0.5, 0), new THREE.Vector2(0.5, 1), new THREE.Vector2(0, 1)]
  boxGeometry.faceVertexUvs[0][2] = [new THREE.Vector2(0.5, 0), new THREE.Vector2(1, 0), new THREE.Vector2(0.5, 1)]
  boxGeometry.faceVertexUvs[0][3] = [new THREE.Vector2(0.5, 1), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1)]

  return boxGeometry
}

export enum FourSidedBoxGeometryVertexIndex {
  FrontTopLeft = 0,
  BackTopLeft = 1,
  BackBottomLeft = 2,
  FrontBottomLeft = 3,
  BackTopRight = 4,
  BackBottomRight = 5,
  FrontBottomRight = 6,
  FrontTopRight = 7,
}

export const createFourSidedBoxGeometry = (boxSize = 50): THREE.Geometry => {
  // Create Box Mesh
  const boxGeometry = new THREE.Geometry()

  // Front top left
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, 0, 0))
  // Back top left
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, 0, -boxSize))
  // Back bottom left
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, -boxSize, -boxSize))
  // Front bottom left
  boxGeometry.vertices.push(new THREE.Vector3(-boxSize, -boxSize, 0))
  // Back top right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, 0, -boxSize))
  // Back bottom right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, -boxSize, -boxSize))
  // Front bottom right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, -boxSize, 0))
  // Front top right
  boxGeometry.vertices.push(new THREE.Vector3(boxSize, 0, 0))

  // Left side
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.FrontTopLeft,
    FourSidedBoxGeometryVertexIndex.BackTopLeft,
    FourSidedBoxGeometryVertexIndex.FrontBottomLeft,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.BackTopLeft,
    FourSidedBoxGeometryVertexIndex.BackBottomLeft,
    FourSidedBoxGeometryVertexIndex.FrontBottomLeft,
  ))

  // Back side
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.BackTopLeft,
    FourSidedBoxGeometryVertexIndex.BackTopRight,
    FourSidedBoxGeometryVertexIndex.BackBottomLeft,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.BackBottomLeft,
    FourSidedBoxGeometryVertexIndex.BackTopRight,
    FourSidedBoxGeometryVertexIndex.BackBottomRight,
  ))

  // Bottom side
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.FrontBottomLeft,
    FourSidedBoxGeometryVertexIndex.BackBottomLeft,
    FourSidedBoxGeometryVertexIndex.BackBottomRight,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.FrontBottomLeft,
    FourSidedBoxGeometryVertexIndex.BackBottomRight,
    FourSidedBoxGeometryVertexIndex.FrontBottomRight,
  ))

  // Right side
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.BackBottomRight,
    FourSidedBoxGeometryVertexIndex.BackTopRight,
    FourSidedBoxGeometryVertexIndex.FrontTopRight,
  ))
  boxGeometry.faces.push(new THREE.Face3(
    FourSidedBoxGeometryVertexIndex.BackBottomRight,
    FourSidedBoxGeometryVertexIndex.FrontTopRight,
    FourSidedBoxGeometryVertexIndex.FrontBottomRight,
  ))

  boxGeometry.computeFaceNormals()
  boxGeometry.computeVertexNormals()

  // Define texture Uvs
  boxGeometry.faceVertexUvs[0][0] = [
    new THREE.Vector2(0.6666, 0.5),
    new THREE.Vector2(1, 0.5),
    new THREE.Vector2(0.6666, 0),
  ]
  boxGeometry.faceVertexUvs[0][1] = [
    new THREE.Vector2(1, 0.5),
    new THREE.Vector2(1, 0),
    new THREE.Vector2(0.6666, 0),
  ]

  boxGeometry.faceVertexUvs[0][2] = [
    new THREE.Vector2(0, 1),
    new THREE.Vector2(0.6666, 1),
    new THREE.Vector2(0, 0.5),
  ]
  boxGeometry.faceVertexUvs[0][3] = [
    new THREE.Vector2(0, 0.5),
    new THREE.Vector2(0.6666, 1),
    new THREE.Vector2(0.6666, 0.5),
  ]

  boxGeometry.faceVertexUvs[0][4] = [
    new THREE.Vector2(0, 0),
    new THREE.Vector2(0, 0.5),
    new THREE.Vector2(0.6666, 0.5),
  ]
  boxGeometry.faceVertexUvs[0][5] = [
    new THREE.Vector2(0, 0),
    new THREE.Vector2(0.6666, 0.5),
    new THREE.Vector2(0.6666, 0),
  ]

  boxGeometry.faceVertexUvs[0][6] = [
    new THREE.Vector2(0.6666, 0.5),
    new THREE.Vector2(0.6666, 1),
    new THREE.Vector2(1, 1),
  ]
  boxGeometry.faceVertexUvs[0][7] = [
    new THREE.Vector2(0.6666, 0.5),
    new THREE.Vector2(1, 1),
    new THREE.Vector2(1, 0.5),
  ]

  return boxGeometry
}

export const createBoxGeometry = (boxSize? : number, getFourSided = false): THREE.Geometry => {
  return getFourSided ? createFourSidedBoxGeometry(boxSize) : createTwoSidedBoxGeometry(boxSize)
}
