import React, { useEffect, useState } from 'react'
import { Loader } from './Loader'
import type VideoController from '../videoController'

export const LoaderForVideo = ({
  showLoaderForVideo = true,
  videoController,
  videoIsReady,
}: {
  showLoaderForVideo?: boolean,
  videoController?: VideoController,
  videoIsReady?: boolean,
}) => {
  // Set default to true if video is not set (in other words: video is loading)
  const [showLoader, setShowLoader] = useState(!Boolean(videoIsReady))

  useEffect(() => {
    setShowLoader(!Boolean(videoIsReady))

    if (videoController) {
      const bufferStart = (e: Event) => {
        setShowLoader(true)
      }
      const bufferEnd = (e: Event) => {
        setShowLoader(false)
      }

      videoController.subscribeToBuffering(bufferStart, bufferEnd)

      const video = videoController.video
      if (video) {
        const eventListenerConfig: AddEventListenerOptions = {
          passive: true,
        }

        video.addEventListener('waiting', bufferStart, eventListenerConfig)
        video.addEventListener('stalled', bufferStart, eventListenerConfig)
        video.addEventListener('seeking', bufferStart, eventListenerConfig)
        video.addEventListener('seeked', bufferEnd, eventListenerConfig)
        video.addEventListener('canplay', bufferEnd, eventListenerConfig)
        video.addEventListener('canplaythrough', bufferEnd, eventListenerConfig)
        video.addEventListener('timeupdate', bufferEnd, eventListenerConfig)
      }

      return () => {
        videoController.unsubscribeFromBuffering(bufferStart, bufferEnd)
        setShowLoader(true)
        if (video) {
          video.removeEventListener('waiting', bufferStart)
          video.removeEventListener('stalled', bufferStart)
          video.removeEventListener('seeking', bufferStart)
          video.removeEventListener('seeked', bufferEnd)
          video.removeEventListener('canplay', bufferEnd)
          video.removeEventListener('canplaythrough', bufferEnd)
          video.removeEventListener('timeupdate', bufferEnd)
        }
      }
    }
  }, [videoController, videoController && videoController.video, videoIsReady])

  if (showLoaderForVideo && showLoader) {
    return <Loader />
  } else {
    return null
  }
}
