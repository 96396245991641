export const isMobile = (): boolean => Boolean(
  isAndroid() ||
  navigator.userAgent.match(/webOS/i) ||
  isAppleMobile() ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
)

export const isIOS = (): boolean => isIPad() || isIPhone()

export const isIPad = (): boolean => Boolean(navigator.userAgent.match(/iPad/i) ||
  (navigator.userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document))

export const isIPadOs = (): boolean => navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1

export const isIPhone = (): boolean => Boolean(navigator.userAgent.match(/iPhone/i) ||
  (navigator.userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document))

export const isSafari = (): boolean =>
  /constructor/i.test(window.HTMLElement.toString()) ||
  (function (p: any) { return p.toString() === '[object SafariRemoteNotification]' })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification))

export const isAppleMobile = (): boolean => isIPad() || isIPadOs() || isIPhone()

export const isAndroid = (): boolean => Boolean(navigator.userAgent.match(/Android/i))

export const isEdge = (): boolean => window.navigator.userAgent.indexOf("Edge") > -1

export const isFirefox = (): boolean => Boolean(navigator.userAgent.match(/Firefox/i))

export const isIE = (): boolean => /MSIE|Trident/.test(window.navigator.userAgent)
