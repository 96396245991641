import React from 'react'
import PropTypes from 'prop-types'
import { ControlBarForHtmlVideo } from './controlBarForHtmlVideo'
import RVideo from '@soccerwatch/react-player'

const propTypes = {
  ...ControlBarForHtmlVideo.propTypes,
  reactPlayerRef: PropTypes.shape({ current: PropTypes.instanceOf(RVideo) }).isRequired,
}

export const ControlBarForReactPlayer: React.FC<PropTypes.InferProps<typeof propTypes>> = ({ reactPlayerRef, ...otherProps }) => 
  <ControlBarForHtmlVideo
    {...otherProps}
    getVideo={() => reactPlayerRef.current ? reactPlayerRef.current.getInternalPlayer() : null}
  />

ControlBarForReactPlayer.propTypes = propTypes

export default ControlBarForReactPlayer