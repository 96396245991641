import PropTypes from 'prop-types'
import React, { Component } from 'react';
import VideoController from '../videoController';
import { Point } from 'paper';
import type VideoCanvasController from '../videoCanvasControllers/videoCanvasController';
import type { VideoCanvasMouseEvent, VideoCanvasKeyboardEvent, VideoCanvasTouchEvent } from '../videoCanvasControllers/VideoCanvasEvent'

export type MouseEventHandler = (event: VideoCanvasMouseEvent, position: paper.Point) => void

export type KeyEventHandler = (event: VideoCanvasKeyboardEvent) => void

export type TouchEventHandler = (event: VideoCanvasTouchEvent) => void

const PluginCanvasControllerPropTypes = {
  videoController: PropTypes.instanceOf(VideoController).isRequired,
  videoCanvasController: PropTypes.instanceOf(React.Component).isRequired,
  evaluatePlugins: PropTypes.func,
  size: PropTypes.instanceOf(Point),
}

export enum OfferedKeyboardEventHandlerNames {
  onKeyPress = 'onKeyPress',
  onKeyDown = 'onKeyDown',
  onKeyUp = 'onKeyUp',
}

export enum OfferedMouseEvendHandlerNames {
  onMouseDown = 'onMouseDown',
  onMouseUp = 'onMouseUp',
  onMouseDrag = 'onMouseDrag',
  onMouseMove = 'onMouseMove',
  onMouseEnter = 'onMouseEnter',
  onMouseLeave = 'onMouseLeave',
}

export enum OfferedTouchEventHandlerNames {
  onTouchStart = 'onTouchStart',
  onTouchMove = 'onTouchMove',
  onTouchEnd = 'onTouchEnd',
  onTouchCancel = 'onTouchCancel',
}

export const OfferedEventHandlerNames = { ...OfferedKeyboardEventHandlerNames, ...OfferedMouseEvendHandlerNames, ...OfferedTouchEventHandlerNames }

export type OfferedKeyboardEventHandlers = {
  -readonly [k in keyof typeof OfferedKeyboardEventHandlerNames]: KeyEventHandler
}

export type OfferedMouseEventHandlers = {
  -readonly [k in keyof typeof OfferedMouseEvendHandlerNames]: MouseEventHandler
}

export type OfferedTouchEventHandlers = {
  -readonly [k in keyof typeof OfferedTouchEventHandlerNames]: TouchEventHandler
}

export type OfferedEventHandlers = OfferedKeyboardEventHandlers & OfferedMouseEventHandlers & OfferedTouchEventHandlers

export type PluginCanvasControllerStateType = {}

export type PluginCanvasControllerPropsType = PropTypes.InferProps<typeof PluginCanvasControllerPropTypes> & { videoCanvasController: VideoCanvasController }

export abstract class PluginCanvasController<P = {}, S = {}, SS = any> extends Component<P & PluginCanvasControllerPropsType, S & PluginCanvasControllerStateType, SS> {
  static propTypes = PluginCanvasControllerPropTypes

  static defaultProps: Partial<PluginCanvasControllerPropsType> = {
    evaluatePlugins: () => null,
    size: new Point(0, 0),
  }

  abstract canvas: HTMLCanvasElement

  abstract handleMouseEvent: MouseEventHandler
  abstract handleKeyEvent: KeyEventHandler
  abstract handleTouchEvent: TouchEventHandler

  onMouseDown: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)
  onMouseDrag: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)
  onMouseUp: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)
  onMouseMove: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)
  onMouseEnter: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)
  onMouseLeave: MouseEventHandler = (event, position) => this.handleMouseEvent(event, position)

  onKeyPress: KeyEventHandler = (event) => this.handleKeyEvent(event)
  onKeyDown: KeyEventHandler = (event) => this.handleKeyEvent(event)
  onKeyUp: KeyEventHandler = (event) => this.handleKeyEvent(event)

  onTouchStart: TouchEventHandler = (event) => this.handleTouchEvent(event)
  onTouchMove: TouchEventHandler = (event) => this.handleTouchEvent(event)
  onTouchEnd: TouchEventHandler = (event) => this.handleTouchEvent(event)
  onTouchCancel: TouchEventHandler = (event) => this.handleTouchEvent(event)

  forceRerender() {
    this.setState({})
  }
}

// Object.values(OfferedEventHandlers).forEach(eventHandlerName => {
//   PluginCanvasController.prototype[eventHandlerName] = PluginCanvasController.prototype.handleMouseEvent
// })
