import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

export const apiList = async (url, minEntrys, header) => {
  let dataArray = []
  let response

  try {
    response = (await axios.get(url, header)).data
    dataArray = dataArray.concat(response.data)
    // Fetch all cities and use nextToken as long as necessary
    while (response.nextToken) {
      const checkLastChar = url.charAt(url.length - 1) === '/'
      if (!checkLastChar) {
        url = url + '/'
      }

      if (url.search('/Tag/')) {
        url = url.replace('/Tag/', '/TagList/')
      }

      // Replace Slashes
      response = (await axios.get(url + response.nextToken.replace(/\//g, '%2F'), header)).data
      dataArray = dataArray.concat(response.data)
      if (minEntrys && minEntrys < dataArray.length) {
        break
      }
    }
    return dataArray
  } catch (err) {
    return Promise.reject(new Error(err))
  }
}
