import paper, { Point } from 'paper'

export const getPaperPointFromEvent = (event: { clientX: number, clientY: number }) => new Point(event.clientX, event.clientY)

export const getVectorsFromRectangle = (rectangle: ClientRect | DOMRect) => ({
  topLeft: new Point(rectangle.left, rectangle.top),
  topRight: new Point(rectangle.right, rectangle.top),
  bottomLeft: new Point(rectangle.left, rectangle.bottom),
  bottomRight: new Point(rectangle.right, rectangle.bottom),
  dimensions: new Point(rectangle.width, rectangle.height),
})
