import React from 'react'
import type PropTypes from 'prop-types'

// Imports from paper are weird with TypeScript: the `paper`
// object already is an instance of paper.PaperScope.
// For this reason, the imports need to be "hacked".
import paper from 'paper'
const { Point, Size, Layer } = (paper as any).__proto__

import { PluginCanvasController, PluginCanvasControllerPropsType, MouseEventHandler, KeyEventHandler, TouchEventHandler } from './pluginCanvasController'
import type { VideoCanvasMouseEvent } from '../videoCanvasControllers/VideoCanvasEvent'

export type ModifiedTouch = (Touch | React.Touch) & {
  point: paper.Point,
  positionInCanvas: paper.Point,
  positionInVideo: paper.Point,
  isOnDrawingCanvas: boolean,
}

export type ModifiedTouchList = (TouchList | React.TouchList) & {
  item: (index: number) => ModifiedTouch
}

export type ModifiedTouchEvent = (TouchEvent | React.TouchEvent) & {
  touches: ModifiedTouchList,
  targetTouches: ModifiedTouchList,
  changedTouches: ModifiedTouchList,
}

export type TouchEventSpecialData = {
  touches?: ModifiedTouchList,
  targetTouches?: ModifiedTouchList,
  changedTouches?: ModifiedTouchList,
}

export type ExtendedPaperEvent = paper.Event & { type?: string, event?: Event }

const PaperjsPluginCanvasControllerPropTypes = {
  ...PluginCanvasController.propTypes,
}

export type PaperjsPluginCanvasControllerPropsType = PropTypes.InferProps<typeof PaperjsPluginCanvasControllerPropTypes>

export type PaperjsPluginCanvasControllerStateType = {

}

export class PaperjsPluginCanvasController extends PluginCanvasController<PaperjsPluginCanvasControllerPropsType, PaperjsPluginCanvasControllerStateType> {
  static propTypes = PaperjsPluginCanvasControllerPropTypes

  canvasRef = React.createRef<HTMLCanvasElement>()

  paperLayers: { [k: string]: paper.Layer } = {}

  paperScope: paper.PaperScope

  get canvas (): HTMLCanvasElement | null {
    return this.canvasRef.current ? this.canvasRef.current : null
  }

  componentDidMount = () => {
    this.paperScope = new paper.PaperScope()
    this.paperScope.setup(this.canvas)

    this.setupVideoLayer()

    // force component re-render once paper has been set up to update plugins
    this.setState({})
  }

  componentWillUnmount () {
    if (this.paperScope) {
      this.paperScope.remove()
      delete this.paperScope
    }
  }

  componentDidUpdate = (oldProps: PaperjsPluginCanvasControllerPropsType & PluginCanvasControllerPropsType) => {
    if (oldProps.size !== this.props.size && this.props.size && this.paperScope?.view && (oldProps.size.x !== this.props.size.x || oldProps.size.y !== this.props.size.y)) {
      this.paperScope.view.viewSize = new Size(this.props.size)
    }
  }

  checkIfIsNormalMouseEvent = (event: MouseEvent | React.MouseEvent | paper.MouseEvent): event is MouseEvent | React.MouseEvent => {
    const castedEvent = event as MouseEvent
    return Boolean(castedEvent.clientX && castedEvent.clientY)
  }

  private wrapGeneralEventInPaperEvent = <K extends paper.Event>(event: Event | React.SyntheticEvent, paperEvent: K & ExtendedPaperEvent): K & ExtendedPaperEvent => {
    const nativeEvent = event instanceof Event ? event : event.nativeEvent

    paperEvent.event = nativeEvent
    paperEvent.preventDefault = nativeEvent.preventDefault.bind(nativeEvent)
    paperEvent.stopPropagation = nativeEvent.stopPropagation.bind(nativeEvent)
    paperEvent.stop = () => {
      nativeEvent.preventDefault()
      nativeEvent.stopPropagation()
    }
    paperEvent.type = event.type

    return paperEvent
  }

  private wrapAnyMouseEventInPaperMouseEvent = (
    event: MouseEvent | React.MouseEvent | paper.MouseEvent,
    position: paper.Point = null,
    videoCanvasEvent?: VideoCanvasMouseEvent,
  ): paper.MouseEvent => {
    if (this.checkIfIsNormalMouseEvent(event)) {
      position = position instanceof paper.Point ? position : new paper.Point(event.clientX, event.clientY)
    }

    const paperMouseEvent = event instanceof paper.MouseEvent ? event : this.wrapGeneralEventInPaperEvent(event, new paper.MouseEvent())
    paperMouseEvent.point = position

    // Save videoCanvasEvent to paperEvent so event handlers in paper canvas can access both positions
    if (videoCanvasEvent) {
      // @ts-ignore
      paperMouseEvent.videoCanvasEvent = videoCanvasEvent
    }

    return paperMouseEvent
  }

  private wrapAnyKeyEventInPaperMouseEvent = (event: KeyboardEvent | React.KeyboardEvent | paper.KeyEvent): paper.KeyEvent => {
    let paperKeyEvent = event instanceof paper.KeyEvent ? event : this.wrapGeneralEventInPaperEvent(event, new paper.KeyEvent())

    // key should only be what would be visible in an input, e.g. NOT "shift"
    paperKeyEvent.character = event.key.length > 1 ? '' : event.key
    paperKeyEvent.key = event.key.toLowerCase()

    return paperKeyEvent
  }

  private wrapTouchEventInPaperTouchEvent = (event: TouchEvent): ReturnType<PaperjsPluginCanvasController['wrapGeneralEventInPaperEvent']> & TouchEventSpecialData => {
    return this.wrapGeneralEventInPaperEvent(event, new paper.Event())
  }

  handleMouseEvent: MouseEventHandler = (videoCanvasEvent, position) => {
    const evt = videoCanvasEvent.nativeEvent
    const paperEvent = this.wrapAnyMouseEventInPaperMouseEvent(evt, position, videoCanvasEvent)
    let type = paperEvent.type
    if (evt.type === 'mousemove' && evt.buttons > 0) {
      type = 'mousedrag'
    }
    this.paperScope?.tool?.emit(type, paperEvent)
  }

  handleKeyEvent: KeyEventHandler = (wrappedEvent) => {
    const paperEvent = this.wrapAnyKeyEventInPaperMouseEvent(wrappedEvent.nativeEvent)
    this.paperScope?.tool?.emit(paperEvent.type, paperEvent)
  }

  handleTouchEvent: TouchEventHandler = (wrappedEvent) => {
    const paperEvent = this.wrapTouchEventInPaperTouchEvent(wrappedEvent.nativeEvent)
    this.paperScope?.tool?.emit(paperEvent.type, paperEvent)
  }

  setupVideoLayer = async () => {
    const setupSpecialLayer = (): paper.Layer => {
      const specialLayer = new Layer()
      specialLayer.position = new Point(0, 0)
      specialLayer.data.role = 'special'

      return specialLayer
    }

    const setupVideoLayer = (children: paper.Item[], oldMatrix?: paper.Matrix): paper.Layer => {
      const videoLayer: paper.Layer = new Layer()
      videoLayer.applyMatrix = false

      children.forEach(child => videoLayer.addChild(child))

      if (oldMatrix) {
        videoLayer.matrix = oldMatrix
      }

      return videoLayer
    }

    let oldVideoLayerMatrix: paper.Matrix

    if (this.paperLayers.videoLayer) {
      oldVideoLayerMatrix = this.paperLayers.videoLayer.matrix
      this.paperLayers.videoLayer.remove()
      delete this.paperLayers.videoLayer
    }

    this.paperLayers.specialLayer = setupSpecialLayer()
    this.paperLayers.videoLayer = setupVideoLayer([this.paperLayers.specialLayer], oldVideoLayerMatrix)

    this.paperScope.project.insertLayer(0, this.paperLayers.videoLayer)
    // this.paperLayers.videoLayer.activate()
  }

  render () {
    // if (this.paperLayers.videoLayer) {
    //   this.paperLayers.videoLayer.activate()
    // }

    const plugins = this.props.videoCanvasController && this.props.videoController && this.paperLayers ?
      this.props.evaluatePlugins(this.props.videoCanvasController, this.props.videoCanvasController.state, this.props.videoController.video, this.paperScope, this.paperLayers.specialLayer) :
      null

    return <>
      <canvas
        ref={this.canvasRef}
        style={{
          display: 'none',
        }}

        // Otherwise, some screens (like smart phones) would recieve a pixel ratio of 2
        // (meaning canvas width and height double)
        data-paper-hidpi='off'
      />
      <div className='plugin-container-outer'>
        <div className='plugins plugin-container-inner'>
          {plugins}
        </div>
      </div>
    </>
  }
}
