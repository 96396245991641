import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import './progressText.css'

const numberToDurationString = (number: number, displayMilliseconds: boolean): string => {
  const milliseconds = Math.floor((number * 1000) % 1000)
  const seconds = Math.floor(number % 60)
  const minutes = Math.floor(number / 60)
  let durationParts = [minutes, seconds]
  if (minutes > 60) {
    durationParts = [
      Math.floor(minutes / 60),
      minutes % 60,
      seconds,
    ]
  }

  if (displayMilliseconds) {
    durationParts.push(milliseconds)
  }

  return durationParts.map((num, index) =>
    num.toString().padStart(index === durationParts.length - 1 && displayMilliseconds ? 3 : 2, '0'),
  ).join(':')
}

const propTypes = {
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  displayMilliseconds: PropTypes.bool.isRequired,
}

export const ProgressText: React.FC<InferProps<typeof propTypes>> = ({ duration, currentTime, displayMilliseconds }) => {
  if (duration !== undefined && currentTime !== undefined) {
    return <div className="progressText">
      <span>{numberToDurationString(currentTime, displayMilliseconds)}</span>
      <span>/</span>
      <span>{numberToDurationString(duration, displayMilliseconds)}</span>
    </div>
  } else {
    return null
  }
}

ProgressText.propTypes = propTypes

export default ProgressText
