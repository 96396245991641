import { createBoxGeometry } from './videoBox'
import * as THREE from 'three'
import { DeviceOrientationController } from '../../vr-player/DeviceOrientationController'
import { Corners, enforceRectangle } from '../../vr-player/enforceRectangle'
import { videoBoxUvsToGameFieldPosition } from '../../vr-player/threeUvToGlobal'
import { soccerFieldCorners2 as fieldCorners } from './exampleFieldCroners'
import { createDrawingCylinder } from './drawingCylinder'
import { addDevToolsToScene } from './devHelpers'

export const rotateBoxMesh = (
  box: THREE.Mesh | THREE.LineSegments,
  isQuarterBoxPanorama: boolean,
) => {
  if (!isQuarterBoxPanorama) {
    const angleDeg = -25
    const angleRad = THREE.Math.degToRad(angleDeg)
    box.rotation.z = angleRad
    box.rotation.x = angleRad

    // Save rotation and position data in geometry instead of mesh
    box.updateMatrix()
    box.updateMatrixWorld()
    box.geometry.applyMatrix(box.matrixWorld)
    box.position.set(0, 0, 0)
    box.rotation.set(0, 0, 0)
    box.scale.set(1, 1, 1)
    box.updateMatrix()
    box.updateMatrixWorld()
  }
}

const createBoxAndAddToScene = (
  scene: THREE.Scene,
  texture: THREE.Texture,
  isQuarterBoxPanorama: boolean,
  renderOrder: number = 0,
): THREE.Mesh => {
  const boxGeometry = createBoxGeometry(undefined, isQuarterBoxPanorama)

  const box = new THREE.Mesh(
    boxGeometry,
    new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      depthTest: true,
      side: isQuarterBoxPanorama ? THREE.BackSide : THREE.FrontSide,
    }),
  )

  rotateBoxMesh(box, isQuarterBoxPanorama)

  if (renderOrder) {
    box.renderOrder = renderOrder
  }

  scene.add(box)

  return box
}

const createRectangle = (
  { topLeft, bottomLeft, bottomRight, topRight }: {
    topLeft: THREE.Vector3,
    topRight: THREE.Vector3,
    bottomLeft: THREE.Vector3,
    bottomRight: THREE.Vector3,
  },
): THREE.Geometry => {
  const geometry = new THREE.Geometry()

  geometry.setFromPoints([topLeft, topRight, bottomRight, bottomLeft])
  geometry.faces.push(new THREE.Face3(0, 2, 1))
  geometry.faces.push(new THREE.Face3(0, 3, 2))

  geometry.computeFaceNormals()
  geometry.computeVertexNormals()
  geometry.computeBoundingBox()

  // Map texture (= video) to previously defined faces
  geometry.faceVertexUvs[0][0] = [new THREE.Vector2(0, 1), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1)]
  geometry.faceVertexUvs[0][1] = [new THREE.Vector2(0, 1), new THREE.Vector2(0, 0), new THREE.Vector2(1, 0)]

  return geometry
}

const addDrawingSurfaceToScene = (
  geometry: THREE.Geometry,
  scene: THREE.Scene,
  texture: THREE.Texture,
  isQuarterBoxPanorama: boolean,
  renderOrder?: number,
  opacity: number = 0,
): THREE.Mesh => {
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    depthTest: false,
    side: isQuarterBoxPanorama ? THREE.DoubleSide : THREE.FrontSide,
  })
  const mesh = new THREE.Mesh(geometry, material)

  if (renderOrder) {
    mesh.renderOrder = renderOrder
  }

  scene.add(mesh)

  return mesh
}

export const addVideoToScene = (
  scene: THREE.Scene, isQuarterBoxPanorama: boolean, video?: HTMLVideoElement,
): THREE.Mesh => {
  const videoTexture = video ? new THREE.VideoTexture(video) : new THREE.Texture()
  const videoBox = createBoxAndAddToScene(scene, videoTexture, isQuarterBoxPanorama, 0)
  return videoBox
}

const addPluginCanvasToScene = ({
  scene,
  pluginCanvas,
  trapezoidCorners,
  renderOrder,
  isQuarterBoxPanorama,
  gridOpacity = 0,
  drawOnField,
  drawOnCylinder,
}: {
  scene: THREE.Scene,
  pluginCanvas: HTMLCanvasElement,
  trapezoidCorners: Corners,
  renderOrder: number,
  isQuarterBoxPanorama: boolean,
  gridOpacity?: number,
  drawOnField: boolean,
  drawOnCylinder: boolean,
}) => {
  const paperjsCanvasTexture = new THREE.CanvasTexture(pluginCanvas)
  paperjsCanvasTexture.minFilter = THREE.LinearFilter

  let drawingSurfaceGeometry: THREE.Geometry

  if (drawOnField) {
    drawingSurfaceGeometry = createRectangle(trapezoidCorners)
  } else if (drawOnCylinder) {
    drawingSurfaceGeometry = createDrawingCylinder()
  } else {
    drawingSurfaceGeometry = createBoxGeometry(undefined, isQuarterBoxPanorama)
  }

  const drawingSurfaceMesh = addDrawingSurfaceToScene(
    drawingSurfaceGeometry, scene, paperjsCanvasTexture, isQuarterBoxPanorama, renderOrder, gridOpacity,
  )

  // If drawing surface is actual video
  if (!drawOnCylinder && !drawOnField) {
    rotateBoxMesh(drawingSurfaceMesh, isQuarterBoxPanorama)
  }

  return { drawingSurfaceMesh, paperjsCanvasTexture, drawingSurfaceGeometry }
}

const configureCamera = (camera: THREE.PerspectiveCamera) => {
  // Set the scene size
  const WIDTH = window.innerWidth
  const HEIGHT = window.innerHeight
  // Get the DOM element to attach to

  // Create a WebGL renderer, camera
  // and a scene
  //scene.background = new THREE.Color( 0xFFFFFF );

  // Add the camera to the scene.
  camera.fov = 45
  camera.aspect = WIDTH / HEIGHT
  camera.near = 1
  camera.far = 1000
  camera.position.z = 0.001
  camera.rotateY(-Math.PI / 4)
}

export const setupPluginCanvas = ({
  scene,
  camera,
  videoBox,
  pluginCanvas,
  showOriginalCornerPositions,
  isQuarterBoxPanorama,
  vrGridOpacity = 0,
  positionsInVideoAsVectors,
  drawOnField,
  drawOnCylinder,
}: {
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera,
  videoBox: THREE.Mesh,
  pluginCanvas: HTMLCanvasElement,
  showOriginalCornerPositions: boolean,
  isQuarterBoxPanorama: boolean,
  vrGridOpacity?: number,
  positionsInVideoAsVectors?: Array<THREE.Vector2>,
  drawOnField?: boolean,
  drawOnCylinder?: boolean
}) => {
  const renderOrder = 10

  const actualCorners = positionsInVideoAsVectors ? videoBoxUvsToGameFieldPosition(videoBox, positionsInVideoAsVectors) : fieldCorners

  const rectCorners = enforceRectangle(actualCorners, camera.position)
  const {
    drawingSurfaceMesh: paperjsBox,
    paperjsCanvasTexture,
    drawingSurfaceGeometry,
  } = addPluginCanvasToScene({
    scene,
    pluginCanvas,
    trapezoidCorners: rectCorners,
    renderOrder,
    isQuarterBoxPanorama,
    gridOpacity: vrGridOpacity,
    drawOnField,
    drawOnCylinder,
  })

  const { wireframe, onRender } = addDevToolsToScene(scene, camera, {
    videoGridOpacity: vrGridOpacity,
    renderOrder,
    drawingSurfaceGeometry,
    isQuarterBoxPanorama,
    corners: actualCorners,
    showOriginalCornerPositions,
  })

  return { paperjsBox, paperjsCanvasTexture, paperjsCanvasWireframe: wireframe, onRender }
}

export const setupScene = (
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera,
  canvas: HTMLCanvasElement,
  dragCanvasKey: number,
) => {
  configureCamera(camera)
  scene.add(camera)

  // Start the renderer.
  const renderer = new THREE.WebGLRenderer({
    canvas,
  })

  const controls = new DeviceOrientationController(camera, renderer.domElement)
  controls.moveCameraOn = dragCanvasKey
  controls.connect()

  // create a point light
  const light = new THREE.AmbientLight(0xFFFFFF) // soft white light
  scene.add(light)

  return { renderer, controls }
}
