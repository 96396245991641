export default {
  nav: {
    dashboard: 'Dashboard',
    account: 'Account',
    login: 'Login',
    remote: 'Remote',
    onsite: 'on Site'
  },
  login: {
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    noAcc: 'No Staige.tv account ?',
    hasAcc: 'You have a Staige.tv account?',
    passLost: 'Password forgotten?',
    massages: {
      success: 'login successful',
      failed: 'login failed',
      logout: 'successfully logged out',
      userNotFound: 'User could not be found',
      tooManyRequests: 'Too many login attempts, try again later',
      wrongMailPw: 'Password is Incorrect'
    }
  },
  dashboard: {
    toVideo: {
      title: 'Enter a game ID to edit the tags.',
      inputPlaceholder: 'Match ID',
      btn: 'on site',
      btnRemote: 'remote'
    }
  },
  sidebar: {
    btn: {
      delete: 'Delete',
      highlight: 'Highlight',
      goalOfWeek: 'Goal of the week'
    },
    swapTeam: 'Change Team Tags',
    swapAiTags: 'Change Team AI-Tags',
    clubALeftInfoText: 'On which side does the home team play?',
    clubALeft: 'Left',
    clubARight: 'Right',
    messages: {
      successSwap: 'Team Tags were swapped',
      successDeleteTag: 'Tag was deleted',
      errorDeleteTag: 'An error has occurred during deletion',
      setHighlightTag: 'Highlight was set',
      removeHighlightTag: 'Highlight was removed'
    }
  },
  tagControl: {
    home: 'Home',
    guest: 'Guest',
    deleteLast: 'Delete last tag'
  },
  messages: {
    error: 'Error occurred:'
  }
}
