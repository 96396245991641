import * as THREE from 'three'

export const createDrawingCylinder = (): THREE.Geometry => {
  const width = 100
  const radius = 100
  const height = 50

  // Compute angle, so that the ends of the partial circle have a distance of exactly width
  const angle = Math.asin(width / 2 / radius) * 2

  // Center of cylinder should always be at math.PI
  const startingAngle = Math.PI + angle / 2

  const angleOfVideoCornerOnUnitCircle = (
    Math.PI + angle
  ) / 2
  // Move partial circle towards camera by the remaining part of the radius, and
  const offset = radius * Math.sin(angleOfVideoCornerOnUnitCircle)

  const cylinder = new THREE.CylinderGeometry(radius, radius, height, 500, 1, true, startingAngle, -angle)
  // -25 just looked good; sorry! Height used to be 100, so it's half the delta to what it is now. Maybe that plays
  // a role :shrug:
  cylinder.translate(0, -25, offset)

  return cylinder
}
