import React from 'react'
import i18n from 'i18next'
import mobiscroll from '@mobiscroll/react'

import { EVENTSETTINGS } from '../../tagConfig'
import { postTag } from '../../../../api/api-tag'

import './timeMenu.scss'

export default class Timemenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      eventType: EVENTSETTINGS[this.props.eventType] ? this.props.eventType : 'soccer',
      disableButtons: false,
      openTimeInput: false,
      clickEvents: {}
    }

    this.handlePostTag = this.handlePostTag.bind(this)
  }

  handlePostTag (eventType, settings, index) {
    let timestampCalc
    let startClockWithSeconds =  settings && settings.startClockWithSeconds !== undefined ? settings.startClockWithSeconds : undefined
    if (!this.props.remote) {
      // onsite
      const cameraStart = new Date(this.props.cameraStart).getTime()
      const nowDate = new Date().getTime()
      const minCalc = Math.round((nowDate - cameraStart) / 1000)

      timestampCalc = minCalc
    } else {
      // remote
      // old timestampCalc = Math.round(this.props.videoRef.currentTime)
      timestampCalc = Math.round(this.props.videoRef.videoElement.nativeElement.currentTime)
    }

    // check soccer tags for instant highlight
    let highlight = 0
    const highlightTags = [12, 14, 15] // eventType list for direkt highlight
    if (this.state.eventType === 'soccer' && highlightTags.includes(eventType)) {
      highlight = 1
    }

    // set timeSeconds
    if (this.state.clickEvents[index]) {
      startClockWithSeconds = this.state.clickEvents[index] * 60
    }

    const body = {
      eventType: eventType,
      timestamp: timestampCalc,
      identifier: this.props.eventType,
      startClockWithSeconds: startClockWithSeconds,
      clockReverse: settings?.clockReverse ? true : undefined,
      stopClock: settings?.stopClock ? true : undefined,
      pauseClock: settings?.pauseClock ? true : undefined,
      period: settings.period,
      level: highlight
    }

    this.setState({
      disableButtons: true
    })

    postTag(body, this.props.matchId, undefined, this.props.user)
      .then((res) => {
        this.setState({
          disableButtons: false
        })
        this.props.getVideoTagsReload()
      })
      .catch((err) => {
        mobiscroll.toast({ message: err, display: 'bottom', color: 'warning' })
        this.setState({
          disableButtons: false
        })
        this.props.getVideoTagsReload()
      })
  }

  checkTagAvailable (id) {
    let check = false
    this.props.matchTags.map((tag) => {
      if(tag._aiTag) {
        return false
      }
      if (tag.eventType === id) {
        check = true
      }
      return null
    })
    return check
  }

  getTimeFromTag = (id) =>  {
    let time
    this.props.matchTags.map((tag) => {
      if(tag._aiTag) {
        return
      }
      if (tag.eventType === id && tag.startClockWithSeconds >= 0) {
        time = tag.startClockWithSeconds
      }
      return null
    })
    return time
  }

  openCloseTimeInputField = () => {
    this.setState({
      openTimeInput: !this.state.openTimeInput
    })
  }

  createBtnGroup () {
    const { clickEvents } = this.state
    const btnGroup = []
    EVENTSETTINGS[this.state.eventType].eventTime.map((itm, index) => {
      const eventTypesIdFirst = itm.eventTypes[0] && itm.eventTypes[0].id !== undefined ? itm.eventTypes[0].id : itm.eventTypes[0]
      const eventTypesIdSecond = itm.eventTypes[1] && itm.eventTypes[1].id !== undefined ? itm.eventTypes[1].id : itm.eventTypes[1]
      const checkAvailableTagOne = this.checkTagAvailable(eventTypesIdFirst)
      const checkAvailableTagTwo = this.checkTagAvailable(eventTypesIdSecond)

      // inputTimer
      const timeFromTag = this.getTimeFromTag(eventTypesIdFirst)
      const time = timeFromTag === undefined ? itm.eventTypes[0].startClockWithSeconds / 60 : timeFromTag / 60

      return btnGroup.push(
        <div key={index} className='container-wrapper-box'>
          <div className='container-box'>
            <button
              disabled={checkAvailableTagOne || this.state.disableButtons}
              style={this.state.disableButtons ? { backgroundColor: '#868686' } : {}}

              title={i18n.exists('eventTypeGeneric.' + this.state.eventType + '.' + eventTypesIdFirst) ? i18n.t('eventTypeGeneric.' + this.state.eventType + '.' + eventTypesIdFirst) : i18n.t('eventType.' + eventTypesIdFirst)}

              onClick={() => {
                this.handlePostTag(eventTypesIdFirst, itm.eventTypes[0], index)
              }}
            >
              [
            </button>
            <div className='text'>{i18n.t('eventTime.' + this.state.eventType + '.' + itm.id)}</div>
            {itm.eventTypes.length === 1 ? null : (
              <button
                disabled={checkAvailableTagTwo || this.state.disableButtons}
                style={this.state.disableButtons ? { backgroundColor: '#868686' } : {}}
                title={i18n.exists('eventTypeGeneric.' + this.state.eventType + '.' + eventTypesIdSecond) ? i18n.t('eventTypeGeneric.' + this.state.eventType + '.' + eventTypesIdSecond) : i18n.t('eventType.' + eventTypesIdSecond)}
                onClick={() => {
                  this.handlePostTag(eventTypesIdSecond, itm.eventTypes[1], index)
                }}
              >
              ]
              </button>
            )}
          </div>

          {this.state.openTimeInput ? (
            <div className='container-minutes'>
              <input
                type="number"
                value={clickEvents[index] === undefined ? time : clickEvents[index]}
                disabled={checkAvailableTagOne}
                onChange={(event) =>  this.setState({ clickEvents: {...clickEvents, [index]: event.target.value }})}
              /> Min
            </div>
          ) : null}         
        </div>
      )
    })

    return btnGroup
  }

  render () {
    return (
      <div className={this.props.cssName ? this.props.cssName : 'timeMenu'}>
        <div style={this.state.openTimeInput ? { marginTop: 36 } : {}} className='btn-timeInput' onClick={this.openCloseTimeInputField}>{!this.state.openTimeInput ? '⇧' : '⇩'}</div>
        <div className='timeMenu-container'>{this.createBtnGroup()}</div>
        <div style={this.state.openTimeInput ? { marginTop: 36 } : {}} className='btn-timeInput' onClick={this.openCloseTimeInputField}>{!this.state.openTimeInput ? '⇧' : '⇩'}</div>
      </div>
    )
  }
}
