import React from 'react'
import './Loader.css'

export const Loader = (props: {
  fallbackText?: string,
} = {
  fallbackText: 'Loading...',
}) => <div
  className={'loader-container'}
>
  <div className={'loader-dots'}>
    {props.fallbackText}
  </div>
</div>
