import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from '@soccerwatch/react-player'
import './videoStyles.css'
import { Point } from 'paper'

import { ControlBarForReactPlayer } from './controls/controlBarForReactPlayer'
import type { PluginCanvasController } from './pluginCanvasController/pluginCanvasController'
import { isAppleMobile, isIOS } from './deviceCategorisation'
import type HLS from 'hls.js'
import { Events } from 'hls.js'

enum PlayPauseOnOptions {
  spaceButtonPress = 'spaceButtonPress',
  leftClick = 'leftClick',
  rightClick = 'rightClick',
  none = 'none',
}

export type VideoControllerStateType = {
  isPlaying: boolean,
  isBuffering: boolean,
  currentTime: number,
  duration: number,
  disableControls: boolean,
  playbackRate: number,
}

export const VideoControllerPropTypes = {
  needAuthorization: PropTypes.bool,
  getAuthorization: PropTypes.func,
  src: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  tabIndex: PropTypes.number,
  autofocus: PropTypes.bool,
  onReady: PropTypes.func,
  disableSkipButtons: PropTypes.bool,
  rotateWithPosition: PropTypes.bool,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }).isRequired,
  onProgress: PropTypes.func,
  startTime: PropTypes.number,
  FullScreenTarget: PropTypes.instanceOf(HTMLElement),
  forceHLS: PropTypes.bool,
  pluginCanvasController: PropTypes.shape({ current: PropTypes.object }),
  disablePrecissionSeek: PropTypes.bool,
  displayMilliseconds: PropTypes.bool,
  showPlaybackRateSetter: PropTypes.bool,
  showQualityLevelSetter: PropTypes.bool,
  playbackRateSteps: PropTypes.arrayOf(PropTypes.number.isRequired),
  playbackRate: PropTypes.number,
}

export type VideoControllerPropsType = OverwriteProperties<PropTypes.InferProps<typeof VideoControllerPropTypes>, {
  pluginCanvasController: { current?: PluginCanvasController },
  skipDurations?: { slow: number, fast: number },
}>

export class VideoController extends Component<VideoControllerPropsType, VideoControllerStateType> {
  static playPauseOnOptions = PlayPauseOnOptions

  static propTypes = VideoControllerPropTypes

  static defaultProps: Partial<VideoControllerPropsType> = {
    autoplay: false,
    controls: false,
    // tabindex of -1 is focusable by JavaScript, but not by keyboard
    tabIndex: -1,
    autofocus: false,
    onReady: () => {},
    disableSkipButtons: false,
    rotateWithPosition: false,
    onProgress: () => {},
    startTime: 0,
    forceHLS: false,
    disablePrecissionSeek: false,
    playbackRate: 1,
  }

  videoRef = React.createRef<ReactPlayer>()
  seekListeners: Function[] = []
  bufferListener: {
    startListener: Function,
    endListener: Function,
  }[] = []
  resolveSeek: (value?: unknown) => void | null = null

  /**
   * ⚠ Warning: This doesn't cover all events! Seek and buffer events are still covered via custom methods
   * @private
   */
  private _events = new EventTarget()

  constructor(props: VideoControllerPropsType) {
    super(props)
    this.state = {
      isPlaying: false,
      isBuffering: false,
      currentTime: 0,
      duration: 0,
      disableControls: false,
      playbackRate: props.playbackRate,
    }
  }

  get video(): HTMLVideoElement {
    return this.videoRef.current ? this.videoRef.current.getInternalPlayer() as HTMLVideoElement : null
  }

  get videoDimensions(): paper.Point {
    if (!this.video) {
      return new Point(0, 0)
    }
    return new Point(this.video.videoWidth, this.video.videoHeight)
  }

  get container(): HTMLElement {
    return this.props.containerRef ? this.props.containerRef.current : null
  }

  get currentTime() {
    return this.video ? this.video.currentTime : -1
  }

  get isPlaying() {
    return this.state.isPlaying
  }

  get playbackRate() {
    return this.video ? this.video.playbackRate : 0
  }

  set playbackRate(value) {
    if (this.video) {
      this.video.playbackRate = value
    }
  }

  /**
   * ⚠ Warning: This doesn't cover all events! Seek and buffer events are still covered via custom methods
   * @private
   */
  get events(): EventTarget {
    return this._events
  }

  componentDidMount = () => {
    if (this.container && this.props.autofocus) {
      this.container.focus()
    }

    this.goToTime(this.props.startTime)
  }

  private lastInternalPlayer?: HLS

  private onQualityChanged = () => {
    // console.log('🔔 quality level switched', ...args)
    this.events.dispatchEvent(new Event('qualityLevelChange'))
  }

  componentDidUpdate = (lastProps: VideoControllerPropsType) => {
    if (lastProps.src !== this.props.src) {
      // Keep the time when VR mode is toggled (i.e. video src is changed without the player being re-mounted)
      this.goToTime(this.state.currentTime)
    }

    const currentInternalPlayer: HLS | undefined = this.getInternalPlayer()
    if (currentInternalPlayer) {

      if (!this.lastInternalPlayer || this.lastInternalPlayer !== currentInternalPlayer) {
        if (this.lastInternalPlayer) {
          this.lastInternalPlayer.off(Events.LEVEL_SWITCHED, this.onQualityChanged)
        }

        currentInternalPlayer.on(
          Events.LEVEL_SWITCHED,
          this.onQualityChanged,
        )
        this.lastInternalPlayer = currentInternalPlayer
      }
    }

    if (lastProps.playbackRate !== this.props.playbackRate) {
      this.setState({ playbackRate: this.props.playbackRate })
    }
  }

  private getInternalPlayer = () => {
    return this.videoRef.current?.getInternalPlayer('hls') as unknown as HLS | undefined
  }

  getQualityLevels = () => {
    const actualLevels = this.getInternalPlayer()?.levels?.map(level => level.height + 'p')

    const result: ['auto', ...string[]] = ['auto']
    if (actualLevels) {
      result.push(...actualLevels)
    }
    return result
  }

  getCurrentQualityLevel = (): 'auto' | string => {
    const internalPlayer = this.getInternalPlayer()
    if (
      internalPlayer === undefined || internalPlayer === null
      || internalPlayer.autoLevelEnabled
      || internalPlayer.levels[internalPlayer.currentLevel] === undefined
    ) {
      return 'auto'
    } else {
      return internalPlayer.levels[internalPlayer.currentLevel].height + 'p'
    }
  }

  setQualityLevel = (level: 'auto' | number) => {
    const internalPlayer = this.getInternalPlayer()

    if (internalPlayer) {
      if (level === 'auto') {
        // Resets level to auto
        internalPlayer.currentLevel = -1
      } else {
        internalPlayer.currentLevel = level
      }
    }
  }

  disableControls = () => {
    this.setState({ disableControls: true })
  }

  enableControls = () => {
    this.setState({ disableControls: false })
  }

  private videoIsReady = async () => {
    console.log('VC Video is Ready')
    const duration = this.getIosSaveDuration(this.video)
    this.setState({
      duration: duration,
    }, () => {
      if (this.props.autoplay) {
        this.play()
      }
      this.props.onReady()
    })
  }

  getIosSaveDuration(video: HTMLVideoElement) {
    let dur = video.duration
    if (dur !== undefined && !isNaN(dur) && isFinite(dur)) {
      return dur
    } else {
      dur = this.currentTime
      if (video.seekable && video.seekable.length > 0 && video.seekable.end(0) > dur) {
        dur = video.seekable.end(0)
      }
      if (video.buffered && video.buffered.length > 0 && video.buffered.end(0) > dur) {
        dur = video.buffered.end(0)
      }
      if (dur !== undefined && !isNaN(dur) && isFinite(dur)) {
        return dur
      }
      console.warn('Could not get Duration from Video', video)
      return 0
    }
  }

  forcePluginCanvasRerender = () => {
    this.props.pluginCanvasController?.current?.forceRerender()
  }

  onPlay = () => {
    this.setState({ isPlaying: true }, this.forcePluginCanvasRerender)
  }

  onPause = () => {
    this.setState({ isPlaying: false }, this.forcePluginCanvasRerender)
  }


  play = () => {
    if (this.video) {
      this.video.play()
    }
  }

  pause = () => {
    if (this.video) {
      this.video.pause()
    }
  }

  togglePlaying = () => {
    if (this.isPlaying) {
      this.pause()
    } else {
      this.play()
    }
  }

  setCurrentPlaybackTime = () => {
    if (this.video) {
      const currentTime = Math.floor(this.video.currentTime)

      if (currentTime !== this.state.currentTime) {
        this.setState({ currentTime })
        this.props.onProgress(currentTime)
      }
    }
  }

  goToTime = (time: number) => {
    if (this.video && time >= 0) {
      this.video.currentTime = time
    }
  }

  goToPercentageOfVideoTime = (percentage: number) => {
    if (this.video) {
      this.goToTime(this.video.duration * percentage)
    }
  }

  playUntilTime = ({ endTime, speed }: { endTime: number, speed: number }) => new Promise((resolve, reject) => {
    if (speed > 0) {
      let wasPlaying = false
      if (this.state.isPlaying) {
        console.warn('Video is already playing')
        wasPlaying = true
        this.pause()
      }

      try {
        const oldSpeed = this.video.playbackRate

        // sometimes this code seems to be executed twice per call; once before and once after endTime
        // Math.floor makes this function not break, but the actual bug is the second execution.
        // TODO: find said bug
        const direction = Math.sign(Math.floor(endTime) - Math.floor(this.currentTime))

        // if current time === end time do not play video
        if (direction !== 0) {
          const checkIfVideoShouldStop = () => {
            const timeToPlay = (endTime - this.currentTime)

            if (timeToPlay <= 0) {
              if (!wasPlaying) {
                this.pause()
              }

              this.video.playbackRate = oldSpeed
              this.setState({ playbackRate: oldSpeed })

              // this function is not fired frequently enough and so it can overshoot.
              // "undoing" that every time stops the extra time from adding up to a
              // whole second or even more
              this.goToTime(this.currentTime + timeToPlay * direction)

              resolve(undefined)
            } else {
              window.requestAnimationFrame(checkIfVideoShouldStop)
            }
          }

          window.requestAnimationFrame(checkIfVideoShouldStop)
          this.video.playbackRate = speed
          this.setState({ playbackRate: speed }, this.play)
        }
      } catch (e) {
        reject(e)
      }
    } else {
      reject(new Error('Video cannot be played back with a speed smaller than or equal to 0.'))
    }
  })

  seek = (time: number) => new Promise((resolve, reject) => {
    if (!this.video) {
      reject(new Error('Cant Seek if VideoPlayer is not Mounted'))
    } else {
      this.resolveSeek = resolve
      this.goToTime(time)
    }
  })

  private onSeekDone = () => {
    this.setCurrentPlaybackTime()
    if (this.resolveSeek) {
      this.resolveSeek()
      delete this.resolveSeek
    }

    if (this.seekListeners.length) {
      this.seekListeners.forEach((listener: Function) => {
        if (typeof listener === 'function') {
          listener()
        }
      })
    }
  }

  subscribeToSeek = (listener: Function) => {
    if (typeof listener !== 'function') {
      console.warn(`<VideoCanvas> Listener ${listener} of type ${typeof listener} can not be accepted as OnSeek callback`)
    } else {
      if (this.seekListeners.indexOf(listener) > -1) {
        console.warn('Listener is already subscribed to seek! It will be be called more than once when a seek happens.')
      }

      return this.seekListeners.push(listener)
    }
  }

  unsubscribeFromSeek = (listener: Function) => {
    if (typeof listener !== 'function') {
      console.warn(`<VideoCanvas> Listener ${listener} of type ${typeof listener} can not be accepted as OnSeek callback`)
    } else {
      const index = this.seekListeners.indexOf(listener)
      if (index > -1) {
        this.seekListeners.splice(index)
      }
    }
  }

  checkIosBuffering(lastPlayPos: number) {
    const offset = 0
    if (this.state.duration > 0) {
      if ((this.currentTime > (lastPlayPos + offset)
      || this.currentTime < (lastPlayPos - offset)
      ) && this.isPlaying) {
        if (this.state.isBuffering) {
          this.onBufferEnd()
        }
      } else {
        setTimeout(() => {
          this.checkIosBuffering(lastPlayPos)
        }, 50)
      }
    }
  }

  //TODO: complete types
  private onBuffer = () => {
    this.setState({ isBuffering: true })
    this.bufferListener.forEach((listener) => {
      listener.startListener()
    })
    if (isAppleMobile()) {
      this.checkIosBuffering(this.currentTime)
    }
  }

  private onBufferEnd = () => {
    this.setState({ isBuffering: false })
    this.bufferListener.forEach((listener) => {
      listener.endListener()
    })
  }

  subscribeToBuffering = (startListener: Function, endListener: Function) => {
    if (typeof startListener !== 'function') {
      console.warn(`<VideoCanvas> Listener ${startListener} of type ${typeof startListener} can not be accepted as OnBufferStart callback`)
    } else if (typeof endListener !== 'function') {
      console.warn(`<VideoCanvas> Listener ${endListener} of type ${typeof endListener} can not be accepted as OnBufferEnd callback`)
    } else {
      this.bufferListener.push({ startListener, endListener })
    }
  }

  unsubscribeFromBuffering = (startListener: Function, endListener: Function) => {
    this.bufferListener = this.bufferListener.filter(currListeners =>
      currListeners.startListener !== startListener ||
      currListeners.endListener !== endListener
    )
  }

  onSkip = (delta: number) => {
    if (this.video) {
      this.goToTime(this.currentTime + delta)
    }
  }

  private renderControls = () => {
    const targetRef = this.props.FullScreenTarget ? { current: this.props.FullScreenTarget } : this.props.containerRef

    if (this.props.controls) {
      return <ControlBarForReactPlayer
        reactPlayerRef={this.videoRef}
        disabled={this.state.disableControls}
        fullscreenTargetRef={targetRef}
        disableSkipButtons={this.props.disableSkipButtons}
        disablePrecissionSeek={this.props.disablePrecissionSeek}
        displayMilliseconds={this.props.displayMilliseconds}
        showPlaybackRateSetter={this.props.showPlaybackRateSetter}
        playbackRateSteps={this.props.playbackRateSteps}
        skipDurations={this.props.skipDurations}
        qualityLevels={this.getQualityLevels()}
        currentQualityLevel={this.getCurrentQualityLevel()}
        setQualityLevel={this.setQualityLevel}
        showQualityLevelSetter={this.props.showQualityLevelSetter}
      />
    }
  }

  render() {
    const forceHLS = this.props.forceHLS
    const hlsAuth = {
      xhrSetup: (xhr: XMLHttpRequest, url: string) => {
        if (this.props.needAuthorization && url.includes('.m3u8')) {
          xhr.setRequestHeader('Authorization', this.props.getAuthorization())
        }
      }
    }
    const style = isIOS() ? { top: window.innerHeight > window.innerWidth ? "25%" : '0', position: "relative", "maxHeight": (window.innerHeight - 56) + 'px', "maxWidth": "100%" } : { display: "none" }
    console.log('Render VC')
    return <>
      <ReactPlayer
        url={this.props.src}
        width={window.innerHeight > window.innerWidth ?  '100vw' : '100vw'}
        height={window.innerHeight > window.innerWidth ? 'auto' : '100%'}
        // re-mount element when src changes in order to re-attach HLS.js. Otherwise, it throws an error
        key={this.props.src}

        // playing={this.state.playing}
        onReady={this.videoIsReady}
        onSeek={this.onSeekDone}
        onBuffer={this.onBuffer}
        onBufferEnd={this.onBufferEnd}
        playsinline
        config={{
          file: {
            hlsOptions: {
              forceHLS,
              ...hlsAuth,
            },
            attributes: {
              preload: 'none',
              crossOrigin: 'anonymous',
            },
          },
        }}
        progressInterval={100}
        onProgress={this.setCurrentPlaybackTime}
        ref={this.videoRef}
        style={style}
        onError={(...args: unknown[]) => console.error('RVideo error', ...args)}
        playbackRate={this.state.playbackRate}
        onPlay={this.onPlay}
        onPause={this.onPause}
      />
      {this.renderControls()}
    </>
  }
}

export default VideoController
