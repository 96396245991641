import React from 'react'
import PropTypes from 'prop-types'
import { isEdge, isIOS } from '../deviceCategorisation'
// Imports from paper are weird with TypeScript: the `paper`
// object already is an instance of paper.PaperScope.
// For this reason, the imports need to be "hacked".
import paper from 'paper'
import '../videoStyles.css'
import { getPaperPointFromEvent, getVectorsFromRectangle } from '../videoTools'
import { VideoCanvasController, VideoCanvasControllerPropsType } from './videoCanvasController'

import { TouchEventManager } from '../touchHandlers'
import type { ModifiedTouch } from '../pluginCanvasController/paperjsPluginCanvasController'
import { VideoCanvasKeyboardEvent, VideoCanvasMouseEvent, VideoCanvasTouchEvent } from './VideoCanvasEvent'
import { executeAppropriateShortcuts } from './KeyboardShortcuts'
import { asyncRequestAnimationFrame } from '../helpers'

const { Point, Size, Layer, Raster } = (paper as any).__proto__

export const PaperjsVideoCanvasControllerPropTypes = {
  src: PropTypes.string,
  activateTouchControls: PropTypes.bool,
  rotateWithPosition: PropTypes.bool,
  recalculateScaleOnResize: PropTypes.bool,
}

export type PaperjsVideoCanvasControllerPropsType = PropTypes.InferProps<typeof PaperjsVideoCanvasControllerPropTypes>

export type PaperjsVideoCanvasControllerStateType = {
  scale: number,
  position: paper.Point,
  draggingVideo: boolean,
  videoSrcHasChanged: boolean,
  playbackRate: number,
  disableControls: boolean,
}

export class PaperjsVideoCanvasController extends VideoCanvasController<PaperjsVideoCanvasControllerPropsType, PaperjsVideoCanvasControllerStateType> {
  static propTypes = {
    ...VideoCanvasController.propTypes,
    ...PaperjsVideoCanvasControllerPropTypes,
  }

  static defaultProps: Partial<PaperjsVideoCanvasControllerPropsType> & typeof VideoCanvasController.defaultProps = {
    ...VideoCanvasController.defaultProps,
    // tabindex of -1 is focusable by JavaScript, but not by keyboard
    activateTouchControls: false,
    rotateWithPosition: false,
    recalculateScaleOnResize: true,
  }

  canvasRef = React.createRef<HTMLCanvasElement>()

  paperLayers: { videoLayer?: paper.Layer } = {}

  bufferListener: { startListener: Function, endListener: Function }[] = []

  mousePosition: {
    inCanvas: paper.Point,
    inVideo: paper.Point,
  } = {
    inCanvas: new Point(0, 0),
    inVideo: new Point(0, 0),
  }

  paper: paper.PaperScope
  touchEventManager: TouchEventManager
  drawAnimationFrameRequestId: number
  raster?: paper.Raster

  constructor(props: PaperjsVideoCanvasControllerPropsType & VideoCanvasControllerPropsType, context: unknown) {
    super(props, context)
    this.state = {
      scale: 1,
      position: new Point(0, 0),
      draggingVideo: false,
      videoSrcHasChanged: true,
      playbackRate: 1,
      disableControls: false,
    }

    // this.defaultKeyboardShortcuts = generateDefaultKeyboardShortcuts({
    //   togglePlaying: () => this.props.videoController ? this.props.videoController.togglePlaying() : console.warn('Video could not be paused because no video was found.'),
    //   shouldPlayPauseOnSpace: () => this.props.playPauseOn === PaperjsVideoCanvasController.playPauseOnOptions.spaceButtonPress,
    //   getRootContainer: () => this.rootContainer,
    //   videoCanvas: props.videoController
    // })
  }

  get video(): HTMLVideoElement | undefined {
    return this.props.videoController?.video ?? undefined
  }

  get videoDimensions(): paper.Point {
    if (!this.video) {
      return new Point(0, 0)
    }
    return new Point(this.video.videoWidth, this.video.videoHeight)
  }

  get canvas() {
    return this.canvasRef.current
  }

  get canvasDataAsVectors() {
    if (this.canvas) {
      return getVectorsFromRectangle(this.canvas.getBoundingClientRect())
    }
  }

  get canvasContext() {
    return this.canvas ? this.canvas.getContext('2d') : undefined
  }

  get paperView() {
    return this.paper ? this.paper.view : undefined
  }

  printUsefulInformation = () => {
    console.log({
      paperLayers: this.paperLayers,
      paper: this.paper,
      view: this.paperView,
      canvasRef: this.canvasRef,
      setScale: this.setScale,
      setPosition: this.setPosition,
      getScale: () => this.state.scale,
      getRaster: () => this.raster,
    })
  }

  componentDidMount = () => {
    this.paper = new paper.PaperScope()
    this.paper.setup(this.canvas)
    window.addEventListener('resize', this.adjustCanvasSize)

    if (this.props.container) {
      (this.props.container).addEventListener('keydown', this.onKeyDown)

      if (this.props.autofocus) {
        (this.props.container).focus()
      }
    }

    this.touchEventManager = new TouchEventManager({
      setPosition: this.setPosition,
      getCanvasDataAsVectors: () => this.canvasDataAsVectors,
      setScale: this.setScale,
      activateTouchControls: this.props.activateTouchControls,
      getCurrentScale: () => this.paperLayers.videoLayer.getScaling().x,
      ignoreSingleTouch: this.props.ignoreSingleTouch
    })

    this.canvas.addEventListener('touchmove', this.touchEventManager.onTouchMove, { passive: false })
    this.canvas.addEventListener('touchstart', this.touchEventManager.onTouchDown, { passive: false })
    this.canvas.addEventListener('touchend', this.touchEventManager.onTouchUp, { passive: false })
    this.canvas.addEventListener('touchcancel', this.touchEventManager.onTouchUp, { passive: false })

    // Manually trigger onReady event in case video has been mounted earlier
    if (this.video && this.video.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
      this.onVideoIsReady()
    }

    this.props.onDidMount()

    this.props.videoController?.events.addEventListener('qualityLevelChange', async () => {
      // console.log('🔔 Quality change event arrived in paperjs canvas controller')

      // await this.adjustCanvasSize()
      await this.adjustToNewVideoQuality()

      await asyncRequestAnimationFrame()
      this.logSizes()
    })

    // this.printUsefulInformation()
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.drawAnimationFrameRequestId)

    if (this.props.container) {
      (this.props.container).removeEventListener('keydown', this.onKeyDown)
    }

    window.removeEventListener('resize', this.adjustCanvasSize)

    if (this.canvas) {
      this.canvas.removeEventListener('touchmove', this.touchEventManager.onTouchMove)
      this.canvas.removeEventListener('touchstart', this.touchEventManager.onTouchDown)
      this.canvas.removeEventListener('touchend', this.touchEventManager.onTouchUp)
      this.canvas.removeEventListener('touchcancel', this.touchEventManager.onTouchUp)
    }

    if (this.paper) {
      this.paper.clear()
      this.paper.remove()
      delete this.paper
    }
  }

  componentWillReceiveProps = (nextProps: PaperjsVideoCanvasControllerPropsType & VideoCanvasControllerPropsType) => {
    if (nextProps.activateTouchControls !== this.props.activateTouchControls) {
      this.touchEventManager.activateTouchControls = nextProps.activateTouchControls
    }

    if (nextProps.src !== this.props.src) {
      this.setState({ videoSrcHasChanged: true })
    }
  }

  disableControls = () => {
    this.setState({ disableControls: true })
  }

  enableControls = () => {
    this.setState({ disableControls: false })
  }

  private configureRaster = (raster: paper.Raster) => {
    raster.setSize(this.videoDimensions)
    raster.position = this.videoDimensions.divide(2)
  }

  setupVideoLayer = () => {
    const setupVideoRaster = (oldMatrix?: paper.Matrix): paper.Raster => {
      const videoRaster: paper.Raster = new Layer().rasterize()
      try {
        this.configureRaster(videoRaster)

        if (oldMatrix) {
          videoRaster.matrix = oldMatrix
        }
      } catch (err) {
        console.warn('Could not set VideoRaster Size', err)
      }
      return videoRaster
    }

    const setupVideoLayer = (children: paper.Item[], oldMatrix?: paper.Matrix): paper.Layer => {
      const videoLayer: paper.Layer = new Layer()
      videoLayer.applyMatrix = false

      children.forEach(child => videoLayer.addChild(child))

      if (oldMatrix) {
        videoLayer.matrix = oldMatrix
      }

      return videoLayer
    }

    let oldVideoLayerMatrix: paper.Matrix
    let oldRasterMatrix: paper.Matrix

    if (this.paperLayers.videoLayer) {
      oldVideoLayerMatrix = this.paperLayers.videoLayer.matrix
      this.paperLayers.videoLayer.remove()
      delete this.paperLayers.videoLayer
    }
    if (this.raster) {
      oldRasterMatrix = this.raster.matrix
      this.raster.remove()
      delete this.raster
    }

    this.raster = setupVideoRaster(oldRasterMatrix)
    this.paperLayers.videoLayer = setupVideoLayer([this.raster], oldVideoLayerMatrix)

    this.paper.project.insertLayer(0, this.paperLayers.videoLayer)
    this.paperLayers.videoLayer.activate()
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (this.state.disableControls || !this.video || !this.canvas) {
      return
    }

    executeAppropriateShortcuts(this.keyboardShortcuts, event)
    this.props.onKeyDown(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? -1))
  }

  onKeyUp = (event: React.KeyboardEvent) => this.props.onKeyUp(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? -1))
  onKeyPress = (event: React.KeyboardEvent) => this.props.onKeyPress(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? -1))

  // Used to only start adjustment once the last adjustment has finished
  private lastQualityChangeAdjustmentPromise: Promise<unknown> = Promise.resolve()
  private videoSizeAfterLastQualityChange: paper.Size
  private adjustToNewVideoQuality = () => {
    const adjustmentFunction = async () => {
      if (this.canvas && this.paper && this.paperLayers?.videoLayer) {
        this.configureRaster(this.raster)

        const oldVideoSize = new paper.Size(this.videoDimensions)
        const targetScaleSize = this.videoSizeAfterLastQualityChange?.divide(oldVideoSize)

        if (targetScaleSize?.width !== targetScaleSize?.height) {
          console.warn('The target scale after a video resize isn\'t symmetrical!')
        }

        const targetScale = targetScaleSize?.width ?? 1

        // --- Useful debug information for console.log below
        // const oldScale = this.state.scale

        // Track which video pixel corresponds to this point in the canvas before and after re-scaling.
        // The change in quality = change of video dimensions = change which absolute position an object is at
        // must be compensated. Then, the old position must be restored.
        const pointOfReference = this.canvasDataAsVectors.dimensions.divide(2)

        const oldPositionOfPointOfReference = this.paperLayers.videoLayer.globalToLocal(pointOfReference)

        await this.setScale(targetScale)

        const newScale = this.state.scale
        const newPositionOfPointOfReference = this.paperLayers.videoLayer.globalToLocal(pointOfReference)
        const currentVideoSize = new paper.Size(this.videoDimensions)
        const sizeDifference = currentVideoSize.divide(this.videoSizeAfterLastQualityChange ?? currentVideoSize)

        if (sizeDifference.width !== sizeDifference.height) {
          console.warn('Size difference isn\'t uniform in both dimensions!', sizeDifference)
        }

        const sizeCorrectedOldPosition = oldPositionOfPointOfReference.clone().multiply(sizeDifference.width)

        // Delta is computed in video pixels but position change must be provided in scaled pixels
        const delta = sizeCorrectedOldPosition.clone().subtract(newPositionOfPointOfReference).multiply(-1)
        this.setPosition(delta.multiply(newScale))

        // --- Useful debug information
        // const topLeftAfterCorrecting = this.paperLayers.videoLayer.globalToLocal(new Point(0, 0))
        // console.log(JSON.stringify({
        //   oldPositionOfPointOfReference,
        //   sizeCorrectedOldPosition,
        //   delta,
        //   newPositionOfPointOfReference,
        //   oldScale,
        //   newScale,
        //   topLeftAfterCorrecting
        // }, null, 2))
      }

      this.videoSizeAfterLastQualityChange = new paper.Size(this.videoDimensions)
    }

    this.lastQualityChangeAdjustmentPromise = this.lastQualityChangeAdjustmentPromise.then(adjustmentFunction)
    return this.lastQualityChangeAdjustmentPromise
  }

  adjustCanvasSize = async () => {
    if (this.canvas && this.paper && this.paperLayers && this.paperLayers.videoLayer) {
      // Set all dimensions to 0 and force browser to render
      if (!isEdge()) {
        // Setting Style width and height to nothing results in Erroneous Resizing in Edgebrwoser
        this.paper.view.viewSize = new Size(0, 0)
        this.canvas.style.display = 'none'
        this.canvas.style.height = ''
        this.canvas.style.width = ''
      }

      await asyncRequestAnimationFrame()

      // Check again in case canvas has unmounted since last animation frame
      if (this.canvas && this.paper && this.paperLayers && this.paperLayers.videoLayer) {
        // Undo the reset and set dimensions to new values
        this.canvas.style.display = ''

        // If ever the width and size attibutes of the canvas are exactly double what they should be,
        // check if the canvas has the attribute hidpi="off" (and / or research said attribute).
        // The problem may lie in the view's pixelRatio automatically being set to 2.
        this.paper.view.viewSize = new Size(this.canvas.offsetWidth, this.canvas.offsetHeight)

        this.setScale(1, () => {
          this.setPosition(new Point(0, 0))

          if (this.paperLayers.videoLayer) {
            // fitBounds() "zooms out of video" --> go back to old zoom and position
            const canvasMitte = this.paperLayers.videoLayer.globalToLocal(this.canvasDataAsVectors.dimensions.divide(2))

            const testBounds = new this.paper.Rectangle(0, 0, this.canvas.offsetWidth, this.canvas.offsetHeight)
            this.paperLayers.videoLayer.fitBounds(testBounds)

            if (this.props.recalculateScaleOnResize) {
              const scaleDelta = this.state.scale / this.calculateMinAndMaxScale().min
              this.paperLayers.videoLayer.scale(
                new Point(scaleDelta, scaleDelta), this.paperLayers.videoLayer.localToGlobal(canvasMitte))
            } else {
              this.setState({ scale: this.calculateMinAndMaxScale().min })
            }
          }
        })
      }
    }
  }

  onVideoIsReady = () => {
    const handleNewVideo = () => {
      this.setState({
        videoSrcHasChanged: false
      }, () => {
        this.draw()
      })
    }

    this.setupVideoLayer()

    // if there is a new video, reset canvas before actually switching to the new video
    if (this.state.videoSrcHasChanged) {
      this.setScale(0, () => {
        this.adjustCanvasSize()
        handleNewVideo()
      })
    } else {
      handleNewVideo()
    }
  }

  drawVideoFrameInCanvas = () => {
    // Chrome crashes without an error message after a while when the canvas (and thus, this.raster) is invisible and the video is still drawn
    try {
    if (this.raster instanceof Raster && this.video instanceof HTMLVideoElement && this.canvas.offsetHeight > 0 && this.canvas.offsetWidth > 0) {
        this.raster.drawImage(this.video, new Point(0, 0))

        if (
          this.props.pluginCanvasController &&
          this.props.pluginCanvasController.canvas &&
          this.props.pluginCanvasController.canvas.width > 0 &&
          this.props.pluginCanvasController.canvas.height > 0
        ) {
          this.raster.drawImage(this.props.pluginCanvasController.canvas, new Point(0, 0))
        }
      }
    } catch (err) {
      console.error('Unexpected Error on Drawing Canvas:', err)
    }
  }

  draw = () => {
    cancelAnimationFrame(this.drawAnimationFrameRequestId)
    if (this.video && this.raster) {
      // console.log('🎨 Drawing...')
      this.drawVideoFrameInCanvas()

      this.drawAnimationFrameRequestId = requestAnimationFrame(this.draw)
    }
  }

  playUntilTime = ({ endTime, speed }: { endTime: number, speed: number }) => new Promise((resolve, reject) => {
    if (speed > 0) {
      let wasPlaying = false
      if (this.props.videoController.isPlaying) {
        console.warn('Video is already playing')
        wasPlaying = true
        this.props.videoController.pause()
      }

      try {
        const oldSpeed = this.props.videoController.playbackRate

        // sometimes this code seems to be executed twice per call; once before and once after endTime
        // Math.floor makes this function not break, but the actual bug is the second execution.
        // TODO: find said bug
        const direction = Math.sign(Math.floor(endTime) - Math.floor(this.props.videoController.currentTime))

        // if current time === end time do not play video
        if (direction !== 0) {
          const checkIfVideoShouldStop = () => {
            const timeToPlay = (endTime - this.props.videoController.currentTime)

            if (timeToPlay <= 0) {
              if (!wasPlaying) {
                this.props.videoController.pause()
              }

              this.props.videoController.playbackRate = oldSpeed

              // this function is not fired frequently enough and so it can overshoot.
              // "undoing" that every time stops the extra time from adding up to a
              // whole second or even more
              this.props.videoController.goToTime(this.props.videoController.currentTime + timeToPlay * direction)

              resolve(undefined)
            } else {
              window.requestAnimationFrame(checkIfVideoShouldStop)
            }
          }

          window.requestAnimationFrame(checkIfVideoShouldStop)
          this.props.videoController.playbackRate = speed
          this.props.videoController.play()
        }
      } catch (e) {
        reject(e)
      }
    } else {
      reject(new Error('Video cannot be played back with a speed smaller than or equal to 0.'))
    }
  })

  extendTouchEventAndCall = (event: React.TouchEvent | TouchEvent, onDone: (eventData: VideoCanvasTouchEvent) => any) => {
    [event.touches, event.targetTouches, event.changedTouches].forEach((touchList: React.TouchList | TouchList) => {
      for (let i = 0; i < touchList.length; i++) {
        const touch = touchList.item(i) as ModifiedTouch

        touch.point = getPaperPointFromEvent(touch)
        touch.positionInCanvas = touch.point.subtract(this.canvasDataAsVectors.topLeft)
        touch.positionInVideo = this.paperLayers.videoLayer ? this.paperLayers.videoLayer.globalToLocal(touch.positionInCanvas) : null
        touch.isOnDrawingCanvas = true
      }
    })

    const wrappedEvent = new VideoCanvasTouchEvent(event, this.props.videoController?.currentTime)
    onDone(wrappedEvent)
  }

  extendMouseEventAndCall = (event: React.MouseEvent | MouseEvent, onDone: (eventData: VideoCanvasMouseEvent, positionInVideo: paper.Point) => any) => {
    const eventPosition = getPaperPointFromEvent(event)

    const clickPositionInCanvas = eventPosition.subtract(this.canvasDataAsVectors.topLeft)

    const clickPositionInVideo = this.paperLayers.videoLayer ? this.paperLayers.videoLayer.globalToLocal(clickPositionInCanvas) : null

    const wrappedEvent = new VideoCanvasMouseEvent(event, this.props.videoController?.currentTime, clickPositionInVideo)
    onDone(wrappedEvent, clickPositionInVideo)
  }

  onCanvasClick = (event: React.MouseEvent) => {
    this.props.container?.focus()

    if (this.state.disableControls) {
      return
    }
    // Only toggle playing if the left mouse button was clicked
    if (event.button === 0) {
      if (this.props.playPauseOn === PaperjsVideoCanvasController.playPauseOnOptions.leftClick) {
        this.props.videoController.togglePlaying()
      } else {
        this.extendMouseEventAndCall(event, this.props.onLeftClick)
      }
    }
  }

  onCanvasRightClick = (event: React.MouseEvent) => {
    if (this.props.dragCanvasKey === 2) {
      // Canvas is going to be dragged, so prevent contextmenu from opening
      event.preventDefault()
    }

    if (this.props.playPauseOn === PaperjsVideoCanvasController.playPauseOnOptions.rightClick) {
      if (this.state.disableControls) {
        return
      }

      event.preventDefault()
      this.props.videoController.togglePlaying()
    } else {
      this.extendMouseEventAndCall(event, this.props.onRightClick)
    }
  }

  setCurrentMousePosition = (event: React.MouseEvent) => {
    const mousePosition = getPaperPointFromEvent(event)
    const angle = 0 // this.paperLayers.videoLayer.rotation

    this.mousePosition.inCanvas = mousePosition.subtract(this.canvasDataAsVectors.topLeft)

    if (this.paperLayers.videoLayer) {
      const inVideo = this.paperLayers.videoLayer.globalToLocal(this.mousePosition.inCanvas)
      this.mousePosition.inVideo = inVideo ? inVideo.rotate(-angle, this.paperLayers.videoLayer.position) : null
    }
  }

  private lastLoggedAt = Date.now()
  private logSizes = (force?: boolean) => {
    // if (force || this.lastLoggedAt + 1_000 < Date.now()) {
    if (force) {
      this.lastLoggedAt = Date.now()

      const videoSize = new Size(this.videoDimensions)
      const sizesAreEqual = this.raster.size.equals(videoSize)
      console.log('📏', JSON.stringify({
        raster: this.raster.size,
        view: this.paperView.size, // === this.paperView.viewSize
        videoBounds: this.paperLayers.videoLayer.bounds.size,
        videoSize,
        rasterEqualsVideo: sizesAreEqual
      }, null, 2))
    }
  }

  calculateMinAndMaxScale = () => {
    // const sizeToFitIntoCanvas = new paper.Size(this.videoDimensions)
    const sizeToFitIntoCanvas = this.raster?.size ?? new paper.Size(this.videoDimensions)

    return {
      // choose minimum scale so that every side fits onto canvas completely
      min: Math.min(
        this.canvasDataAsVectors.dimensions.x / sizeToFitIntoCanvas.width,
        this.canvasDataAsVectors.dimensions.y / sizeToFitIntoCanvas.height,
      ),
      max: 4,
    }
  }

  calculateMinAndMaxPositionDelta = () => {
    const bounds = this.raster.bounds
    const corners = [bounds.topLeft, bounds.topRight, bounds.bottomLeft, bounds.bottomRight].map(corner => this.paperLayers.videoLayer.localToGlobal(corner))
    const minPoint = corners.reduce(Point.min)
    const maxPoint = corners.reduce(Point.max)

    const result = {
      min: maxPoint.subtract(this.canvasDataAsVectors.dimensions).multiply(-1),
      max: minPoint.multiply(-1)
    }

    // centralize video if it is currently smaller than the canvas
    const globalVideoDimensions = this.videoDimensions.multiply(this.paperLayers.videoLayer.getScaling())
    const topLeftCornerPosition = result.min.add(result.max).divide(2)

    if (globalVideoDimensions.x < this.canvasDataAsVectors.dimensions.x) {
      result.min.x = topLeftCornerPosition.x
      result.max.x = topLeftCornerPosition.x
    }
    if (globalVideoDimensions.y < this.canvasDataAsVectors.dimensions.y) {
      result.min.y = topLeftCornerPosition.y
      result.max.y = topLeftCornerPosition.y
    }

    return result
  }

  computeRotationAtDestination = (dest: paper.Point) => {
    const bottomCenterOfVideo = this.videoDimensions.divide(new Point(2, 1))
    const distanceToBottomCenterInVideo = dest.subtract(bottomCenterOfVideo)
    const angle = Math.atan(distanceToBottomCenterInVideo.x / Math.abs(distanceToBottomCenterInVideo.y)) * -1

    // When dest === bottomCenterOfVideo, angle is NaN. Also, the angle is needed in deg
    return Number.isNaN(angle) ? 0 : angle / Math.PI * 180
  }

  rotateAccordingToPosition = () => {
    if (this.props.rotateWithPosition) {
      const centerOfCanvasInVideo = this.canvasDataAsVectors.dimensions.divide(2)
      const oldAngle = this.paperLayers.videoLayer.rotation
      const newAngle = this.computeRotationAtDestination(this.paperLayers.videoLayer.globalToLocal(centerOfCanvasInVideo))
      const angleDelta = newAngle - oldAngle
      this.paperLayers.videoLayer.rotate(angleDelta, centerOfCanvasInVideo)
    }
  }

  setPosition = (localPositionDelta: paper.Point) => {
    if (this.paperLayers.videoLayer) {
      const minAndMaxDelta = this.calculateMinAndMaxPositionDelta()

      localPositionDelta = Point.max(localPositionDelta, minAndMaxDelta.min)
      localPositionDelta = Point.min(localPositionDelta, minAndMaxDelta.max)

      this.paperLayers.videoLayer.translate(localPositionDelta)
      this.rotateAccordingToPosition()
    }
  }

  setPositionAnimated = (localPositionDelta: paper.Point, animationDuration: number) => {
    if (this.paperLayers.videoLayer) {
      const minAndMaxDelta = this.calculateMinAndMaxPositionDelta()

      localPositionDelta = Point.max(localPositionDelta, minAndMaxDelta.min)
      localPositionDelta = Point.min(localPositionDelta, minAndMaxDelta.max)
      this.paperLayers.videoLayer.tween({
        position: this.paperLayers.videoLayer.position.add(localPositionDelta)
      }, {
        duration: animationDuration
      })
    }
  }

  setScale = (scaleDelta: number, callback: (...args: any[]) => void = () => {}, center?: paper.Point) => {
    return new Promise((resolve) => {
      if (this.paperLayers.videoLayer) {
        let newScale = this.state.scale * scaleDelta
        const { min, max } = this.calculateMinAndMaxScale()

        newScale = Math.max(min, newScale)
        newScale = Math.min(max, newScale)

        // re-calculate scaleDelta in case newScale is min or max
        scaleDelta = newScale / this.state.scale
        this.paperLayers.videoLayer.scale(new Point(scaleDelta, scaleDelta), center)
        this.setState({ scale: newScale }, () => {
          callback()
          resolve(undefined)
        })
      } else {
        resolve(undefined)
      }
    })
  }

  zoomVideo = (scaleFactor: number) => {
    if (!this.props.disableZoom) {
      this.setScale(scaleFactor, () => {
        this.setPosition(new Point(0, 0))
      }, this.mousePosition.inCanvas)
    }
  }

  onCanvasMousewheelScroll = (event: React.WheelEvent) => {
    const scaleFactor = 1 + (event.deltaY > 0 ? -0.3 : 0.3)
    this.zoomVideo(scaleFactor)
  }

  startDragging = () => {
    this.setState({ draggingVideo: true })
  }

  stopDragging = () => {
    this.setState({ draggingVideo: false })
  }

  onDragVideo = (event: React.MouseEvent) => {
    const delta = new Point(event.nativeEvent.movementX, event.nativeEvent.movementY)

    this.setPosition(delta)
  }

  onCanvasTouchStart = (event: React.TouchEvent) => {
    event.preventDefault()
    if (this.props.activateTouchControls) {
      this.startDragging()
    }

    this.extendTouchEventAndCall(event, this.props.onTouchStart)
  }

  onCanvasTouchMove = (event: React.TouchEvent) => {
    this.extendTouchEventAndCall(event, this.props.onTouchMove)
  }

  onCanvasTouchEnd = (event: React.TouchEvent) => {
    event.preventDefault()
    if (this.state.draggingVideo) {
      this.stopDragging()
    }

    this.extendTouchEventAndCall(event, this.props.onTouchEnd)
  }

  onCanvasTouchCancel = (event: React.TouchEvent) => {
    if (this.state.draggingVideo) {
      this.stopDragging()
    }

    this.extendTouchEventAndCall(event, this.props.onTouchCancel)
  }

  onCanvasMouseDown = (event: React.MouseEvent) => {
    if (!this.isTouchEvent(event)) {
      if (event.button === this.props.dragCanvasKey) {
        this.startDragging()
      }

      this.extendMouseEventAndCall(event, (wrappedEvent) => {
        this.props.onMouseDown(wrappedEvent, wrappedEvent.positionOnDrawingSurface)
      })
    }
  }

  onCanvasMouseUp = (event: React.MouseEvent) => {
    if (!this.isTouchEvent(event)) {
      if (event.button === this.props.dragCanvasKey) {
        this.stopDragging()
      }

      this.extendMouseEventAndCall(event, (wrappedEvent) => {
        this.props.onMouseUp(wrappedEvent, wrappedEvent.positionOnDrawingSurface)
      })
    }
  }

  isTouchEvent = (event: React.MouseEvent | React.TouchEvent): event is React.TouchEvent => Boolean((event as React.TouchEvent).touches)

  onCanvasMouseMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (!this.isTouchEvent(event)) {
      this.setCurrentMousePosition(event)

      if (this.state.draggingVideo) {
        this.onDragVideo(event)
      }

      // if any button is pressed during the mouse move the event essentially is a drag
      if (event.buttons > 0) {
        this.extendMouseEventAndCall(event, (wrappedEvent) => {
          wrappedEvent.type = 'mousedrag'
          this.props.onMouseDrag(wrappedEvent, wrappedEvent.positionOnDrawingSurface)
        })
      }

      this.extendMouseEventAndCall(event, (wrappedEvent) => {
        this.props.onMouseMove(wrappedEvent, wrappedEvent.positionOnDrawingSurface)
      })
    }
  }

  render() {
    const style = !isIOS() ? { touchAction: 'none' } : { touchAction: 'none', visibility: "hidden", position: "absolute", zIndex: -999999 }
    return <>
      <canvas
        ref={this.canvasRef}
        onClick={this.onSingleClick(this.onCanvasClick)}
        onContextMenu={this.onCanvasRightClick}
        onWheel={this.onCanvasMousewheelScroll}
        onMouseDown={this.onCanvasMouseDown}
        onMouseUp={this.onCanvasMouseUp}
        onMouseMove={this.onCanvasMouseMove}
        onKeyUp={this.onKeyUp}
        onKeyPress={this.onKeyPress}
        onTouchStart={this.onCanvasTouchStart}
        onTouchMove={this.onCanvasTouchMove}
        onTouchEnd={this.onCanvasTouchEnd}
        onTouchCancel={this.onCanvasTouchCancel}
        // @ts-ignore
        style={style}
        // Otherwise, some screens (like smart phones) would recieve a pixel ratio of 2
        // (meaning canvas width and height double)
        data-paper-hidpi='off'
      />
      <div className='children'>
        {this.props.children}
      </div>
    </>
  }
}

export default PaperjsVideoCanvasController
