import React, { useState } from 'react'
import PropTypes, { Requireable } from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './qualityLevelButton.css'

const propTypes = {
  setQualityLevel: PropTypes.func as Requireable<(level: 'auto' | number) => void>,
  qualityLevels: PropTypes.arrayOf(PropTypes.string.isRequired),
  showQualityLevelSetter: PropTypes.bool,
  currentQualityLevel: PropTypes.string,
}

export const QualityLevelButton: React.FC<PropTypes.InferProps<typeof propTypes>> = ({
  setQualityLevel,
  qualityLevels,
  showQualityLevelSetter,
  currentQualityLevel,
}) => {
  const [currentlySelectingQualityLevel, setCurrentlySelectingQualityLevel] = useState(false)

  const clickHandler = (newQualityLevel: number) => {
    // index === 0 -> auto, otherwise one higher than actual index
    setQualityLevel(newQualityLevel === 0 ? 'auto' : newQualityLevel - 1)
    setCurrentlySelectingQualityLevel(false)
  }

  return (
    showQualityLevelSetter
    && qualityLevels !== undefined
    && setQualityLevel !== undefined
    && currentQualityLevel !== undefined
  ) ?
    <>
      <ControlsButton
        className='quality-level'
        onClick={() => setCurrentlySelectingQualityLevel(!currentlySelectingQualityLevel)}
      >
        {currentQualityLevel}
      </ControlsButton>
      {currentlySelectingQualityLevel ? <div
        className={'quality-level-selection-container'}
      >
        {qualityLevels.map((qualityLevel, index) => <div
          key={index}
          onClick={() => clickHandler(index)}
          className={`quality-level-option ${currentQualityLevel === qualityLevel ? 'selected' : ''}`}
        >
          {qualityLevel}
        </div>)}
      </div> : null}
    </> : null
}

QualityLevelButton.propTypes = propTypes

export default QualityLevelButton
