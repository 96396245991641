import React from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import paper from 'paper'
import { DeviceOrientationController, MOVE_CAMERA_ON_OPTIONS } from '../vr-player/DeviceOrientationController'
import VideoCanvasController, { VideoCanvasControllerPropsType } from './videoCanvasController'
import { Corners, enforceRectangle } from '../vr-player/enforceRectangle'
import { VideoCanvasKeyboardEvent, VideoCanvasMouseEvent, VideoCanvasTouchEvent } from './VideoCanvasEvent'
import type { ModifiedTouch } from '../pluginCanvasController/paperjsPluginCanvasController'
import { calculateGlobalPositionFromBoxUv } from '../vr-player/threeUvToGlobal'
import { addVideoToScene, setupPluginCanvas, setupScene } from './threejsUtils/setupScene'
import { executeAppropriateShortcuts } from './KeyboardShortcuts'
import { isMobile } from '../deviceCategorisation'

const { Point } = (paper as any).__proto__

const adjustCanvasSize = (camera: THREE.PerspectiveCamera, renderer: THREE.Renderer, canvas: HTMLCanvasElement) => {
  if (camera && canvas && renderer) {
    requestAnimationFrame(() => {
      // Set all dimensions to 0 and force browser to render
      renderer.setSize(0, 0)
      canvas.style.display = 'none'
      canvas.style.height = ''
      canvas.style.width = ''

      requestAnimationFrame(() => {
        // Undo the reset and set dimensions to new values
        canvas.style.display = ''

        // If ever the width and size attibutes of the canvas are exactly double what they should be,
        // check if the canvas has the attribute hidpi="off" (and / or research said attribute).
        // The problem may lie in the view's pixelRatio automatically being set to 2.
        renderer.setSize(canvas.offsetWidth, canvas.offsetHeight)

        camera.aspect = canvas.offsetWidth / canvas.offsetHeight
        camera.updateProjectionMatrix()
      })
    })
  }
}

const setTextureToVideo = (
  mesh: THREE.Mesh,
  video: HTMLVideoElement,
  isQuarterBoxPanorama: boolean,
): THREE.VideoTexture => {
  if (video && mesh) {
    const videoTexture = new THREE.VideoTexture(video)

    mesh.material = new THREE.MeshBasicMaterial({
      map: videoTexture,
      transparent: true,
      depthTest: false,
      side: isQuarterBoxPanorama ? THREE.BackSide : THREE.FrontSide,
    })

    return videoTexture
  } else {
    return new THREE.Texture()
  }
}

const calculateCameraRotationMatrixForZoom = ({
  newFov,
  camera,
  extendMouseEvent,
  event,
  fovDelta,
}: {
  newFov: number,
  camera: THREE.PerspectiveCamera,
  extendMouseEvent: InstanceType<typeof ThreejsVrVideoCanvasController>['extendMouseEvent'],
  event: WheelEvent,
  fovDelta: number,
}) => {
  const relativeFov = newFov / camera.fov

  // Calculate camera rotation
  let matrix = camera.matrix.clone()
  const eventExtensionResult = extendMouseEvent(event)
  if (eventExtensionResult?.positionInThreeWorld) {
    matrix.lookAt(camera.position, eventExtensionResult.positionInThreeWorld, new THREE.Vector3(0, 1, 0))

    let quaternion = new THREE.Quaternion().setFromRotationMatrix(matrix)

    // Rotate in opposite direction when zooming out
    if (fovDelta > 0) {
      quaternion = quaternion.conjugate()
    }

    // Apply new FOV to rotation
    const actualAngleToTarget = quaternion.angleTo(camera.quaternion)
    const angleToTargetForNewFov = actualAngleToTarget * relativeFov
    quaternion.rotateTowards(camera.quaternion, angleToTargetForNewFov)

    matrix.makeRotationFromQuaternion(quaternion)
  }
  return matrix
}

const registerEventHandlers = ({ camera, renderer, getDisableZoom, extendMouseEvent }: {
  camera: THREE.PerspectiveCamera,
  renderer: THREE.Renderer,
  getDisableZoom: () => boolean,
  extendMouseEvent: InstanceType<typeof ThreejsVrVideoCanvasController>['extendMouseEvent'],
}) => {
  const canvas = renderer.domElement
  const onWindowResize = () => adjustCanvasSize(camera, renderer, canvas)
  window.addEventListener( 'resize', onWindowResize, false )

  const onMouseWheel = (event: WheelEvent) => {
    if (!getDisableZoom()) {
      let fovDelta = 0

      if (event.wheelDeltaY) { // WebKit
        fovDelta = -event.wheelDeltaY * 0.05
      } else if (event.wheelDelta) { // Opera / IE9
        fovDelta = -event.wheelDelta * 0.05
      } else if (event.detail) { // Firefox
        fovDelta = event.detail * 1.0
      }

      const oldFov = camera.fov
      const newFov = Math.max(
        DeviceOrientationController.minimumFov,
        Math.min(DeviceOrientationController.maximalFov, oldFov + fovDelta)
      )
      const matrix = calculateCameraRotationMatrixForZoom({ newFov, camera, extendMouseEvent, event, fovDelta })

      camera.fov = newFov

      // Don't rotate camera if zoom was stopped by min or max
      if (oldFov !== camera.fov) {
        camera.setRotationFromMatrix(matrix)
      }

      camera.updateProjectionMatrix()
    }
  }

  canvas.addEventListener('mousewheel', onMouseWheel as EventHandlerNonNull, false)
  canvas.addEventListener('DOMMouseScroll', onMouseWheel, false)

  const unregister = () => {
    window.removeEventListener('resize', onWindowResize)
    canvas.removeEventListener('mousewheel', onMouseWheel as EventHandlerNonNull)
    canvas.removeEventListener('DOMMouseScroll', onMouseWheel)
  }

  return unregister
}

const missingDependenciesForSetupError = new Error(`Can't set up three js canvas due to missing dependencies!`)

const ThreejsVrVideoCanvasControllerRuntimePropTypes = {
  onPluginCanvasDimensionsUpdate: PropTypes.func,
  vrGridOpacity: PropTypes.number.isRequired,
  playingFieldCornersInVideo: PropTypes.shape({
    corners: PropTypes.arrayOf(PropTypes.number.isRequired),
    panorama_corners: PropTypes.arrayOf(PropTypes.number.isRequired),
    quarterBoxPanorama: PropTypes.arrayOf(PropTypes.number.isRequired),
    // This is set if there was an error while fetching corners
    error: PropTypes.any,
  }),
  drawOnField: PropTypes.bool.isRequired,
  drawOnCylinder: PropTypes.bool.isRequired,
  showOriginalCornerPositions: PropTypes.bool.isRequired,
  isQuarterBoxPanorama: PropTypes.bool,
}

type ThreejsVrVideoCanvasControllerPropTypes = OverwriteProperties<VideoCanvasControllerPropsType, PropTypes.InferProps<typeof ThreejsVrVideoCanvasControllerRuntimePropTypes>>

type ThreejsVrVideoCanvasControllerStateType = {
  videoIsReady: boolean
}

export class ThreejsVrVideoCanvasController extends VideoCanvasController<ThreejsVrVideoCanvasControllerPropTypes, ThreejsVrVideoCanvasControllerStateType> {
  private canvasRef = React.createRef<HTMLCanvasElement>()
  private raycaster = new THREE.Raycaster()
  private paperjsBox: THREE.Mesh | null = null
  private videoBox: THREE.Mesh | null = null
  private camera = new THREE.PerspectiveCamera()
  private scene = new THREE.Scene()
  private paperjsCanvasTexture = new THREE.Texture()
  private renderAnimationFrameRequest: number
  private recalibrating: boolean = false
  private clickedCorners: Array<THREE.Vector3> = []
  private paperjsCanvasWireframe: THREE.LineSegments | undefined
  private unregisterCanvasEventHandlers = () => {}
  private videoTexture: THREE.VideoTexture | undefined

  private lastAnimationTimestamp = Date.now()
  private overdraftLimit = 250
  private overdraft = 0
  private lastVideoState = false

  private renderer: THREE.Renderer
  private controls: DeviceOrientationController
  private hasThreeSetupBeenCompleted = false
  private hasPluginCanvasSetupBeenCompleted = false
  private updatePluginCanvasOnRender: () => void | undefined

  state = {
    videoIsReady: false,
  }

  static propTypes = {
    ...ThreejsVrVideoCanvasControllerRuntimePropTypes,
    ...VideoCanvasController.propTypes,
  }

  static defaultProps: Partial<ThreejsVrVideoCanvasControllerPropTypes> = {
    ...VideoCanvasController.defaultProps,
    disableSkipButtons: false,
    onPluginCanvasDimensionsUpdate: async () => {},
    vrGridOpacity: 0,
    drawOnField: false,
    isQuarterBoxPanorama: false,
  }

  get canvas() {
    return this.canvasRef.current
  }

  constructor(props: Readonly<ThreejsVrVideoCanvasControllerPropTypes>, context?: any) {
    super(props, context)
  }

  componentDidMount = () => {
    if (this.props.pluginCanvasController && this.props.playingFieldCornersInVideo) {
      try {
        this.setupThree()
      } catch (e) {
        // If VR Mode is forced, neither video nor canvas are ready at this time and setup fails. This is no problem
        // though, because the setup just takes place when all dependencies are available.
        if (e !== missingDependenciesForSetupError) {
          throw e
        }
      }
    }

    this.props.onDidMount()

    this.registerKeyboardEventListeners()
  }

  componentDidUpdate = (oldProps: PropTypes.InferProps<typeof ThreejsVrVideoCanvasControllerRuntimePropTypes> & VideoCanvasControllerPropsType) => {
    // Run setup if dependencies exist and it hasn't been setup yet
    if (
      this.props.pluginCanvasController &&
      this.props.videoController?.video &&
      // If shouldn't draw on field, don't wait for field coordinates
      (!this.props.drawOnField || this.props.playingFieldCornersInVideo) &&
      this.canvas &&
      !this.hasThreeSetupBeenCompleted
    ) {
      this.setupThree()
    }
    if (this.props.playingFieldCornersInVideo && !this.hasPluginCanvasSetupBeenCompleted) {
      this.setupPluginCanvas()
    }

    if (this.props.dragCanvasKey !== oldProps.dragCanvasKey || this.props.activateTouchControls !== oldProps.activateTouchControls) {
      // Always enable drag on desktop devices because then dragCanvasKey is considered. On mobile, however, the
      // touch events cannot be filtered by any key, so manualDrag has to be enabled by a separate flag.
      this.controls.enableManualDrag = !isMobile() || this.props.activateTouchControls
      this.controls.moveCameraOn = this.props.dragCanvasKey ?? MOVE_CAMERA_ON_OPTIONS.ANY
    }

    if (this.props.disableZoom !== oldProps.disableZoom) {
      this.controls.enableManualZoom = !this.props.disableZoom
    }

    this.registerKeyboardEventListeners(oldProps.container)
  }

  componentWillUnmount = () => {
    this.unregisterCanvasEventHandlers()
    this.unregisterKeyboardEventListeners()

    cancelAnimationFrame(this.renderAnimationFrameRequest)
    if(this.controls) {
      this.controls.disconnect()
    }
  }

  private onKeyDown = (event: KeyboardEvent | React.KeyboardEvent) => {
    const nativeEvent = (event as React.KeyboardEvent).nativeEvent ?? (event as KeyboardEvent)
    executeAppropriateShortcuts(this.keyboardShortcuts, nativeEvent)

    this.props.onKeyDown(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? NaN))
  }

  private onKeyUp = (event: KeyboardEvent | React.KeyboardEvent) => {
    this.props.onKeyDown(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? NaN))
  }

  private onKeyPress = (event: KeyboardEvent | React.KeyboardEvent) => {
    this.props.onKeyDown(new VideoCanvasKeyboardEvent(event, this.props.videoController?.currentTime ?? NaN))
  }

  private registerKeyboardEventListeners = (oldContainer?: HTMLElement | null): void => {
    if (oldContainer !== this.props.container) {
      this.unregisterKeyboardEventListeners(oldContainer)

      this.props.container?.addEventListener('keydown', this.onKeyDown)
      this.props.container?.addEventListener('keyup', this.onKeyUp)
      this.props.container?.addEventListener('keypress', this.onKeyPress)
    }
  }

  private unregisterKeyboardEventListeners = (container = this.props.container) => {
    container?.removeEventListener('keydown', this.onKeyDown)
    container?.removeEventListener('keyup', this.onKeyUp)
    container?.removeEventListener('keypress', this.onKeyPress)
  }

  getMousePositionAsNormalDeviceCoordinates = (mousePositionInCanvas: THREE.Vector2): THREE.Vec2 => {
    return new THREE.Vector2().copy(mousePositionInCanvas)
      // scale vector to range [-1, 1]
      .multiply(new THREE.Vector2(2, -2)) // flip y because y axis is inverted in three js
      .add(new THREE.Vector2(-1, 1))
  }

  getVideoAndWorldPositionFromEvent = (event: MouseEvent | React.MouseEvent | Touch | React.Touch): {
    positionInThreeWorld: THREE.Vector3,
    positionInCanvas: paper.Point,
    positionOnDrawingSurface: paper.Point,
    actualPositionInVideo?: paper.Point,
  } | void => {
    if (this.renderer?.domElement) {
      const canvas = this.renderer.domElement
      const rect = canvas.getBoundingClientRect()
      const clickPositionInCanvas = new THREE.Vector2((
        event.clientX - rect.left
      ) / rect.width, (
        event.clientY - rect.top
      ) / rect.height)
      const mousePosition = this.getMousePositionAsNormalDeviceCoordinates(clickPositionInCanvas)

      if (mousePosition && this.paperjsBox && this.camera) {
        this.raycaster.setFromCamera(mousePosition, this.camera)
        const intersects = this.raycaster.intersectObject(this.recalibrating ? this.videoBox : this.paperjsBox)
        // const intersects = this.raycaster.intersectObject(this.videoBox)
        // const intersects = this.raycaster.intersectObjects(this.scene.children)

        if (intersects[0]?.uv) {
          const uv = intersects[0].uv

          const fromRelativeToAbsoluteVideoPosition = (point: paper.Point): paper.Point => point
            // flip y axis because it is flipped in paper.js
            .multiply(new Point(1, -1))
            .add(new Point(0, 1))
            // map to video dimensions
            .multiply(new Point(
              this.props.pluginCanvasController.canvas.width,
              this.props.pluginCanvasController.canvas.height,
            ))

          const positionOnDrawingSurface = fromRelativeToAbsoluteVideoPosition(new Point(uv))

          const result: ReturnType<ThreejsVrVideoCanvasController['getVideoAndWorldPositionFromEvent']> & { positionInVideo: paper.Point } = {
            positionOnDrawingSurface,

            // Export positionOnDrawingSurface as positionInVideo for legacy reasons, but this is deprecated!
            positionInVideo: positionOnDrawingSurface,

            positionInThreeWorld: intersects[0].point,
            positionInCanvas: new Point(clickPositionInCanvas.x, clickPositionInCanvas.y),
          }

          const intersectionWithVideo = this.raycaster.intersectObject(this.videoBox)
          if (intersectionWithVideo[0]?.uv) {
            const uv = intersectionWithVideo[0]?.uv

            result.actualPositionInVideo = fromRelativeToAbsoluteVideoPosition(new Point(uv))
          }

          return result
        }
      } else {
        console.warn(
          'Tried to get threejs positions but failed (maybe because threejs video player was not initialized yet)'
        )
      }
    }
  }

  extendMouseEvent = (event: React.MouseEvent | MouseEvent) => {
    const positions = this.getVideoAndWorldPositionFromEvent(event)
    if (positions) {
      const { positionOnDrawingSurface, actualPositionInVideo: positionInVideo } = positions
      const wrappedEvent = new VideoCanvasMouseEvent(
        event,
        this.props.videoController?.currentTime ?? NaN,
        positionOnDrawingSurface,
        positionInVideo,
      )
      return {
        ...positions,
        wrappedEvent,
      }
    }
  }

  extendMouseEventAndCall = (
    event: React.MouseEvent | MouseEvent,
    onDone: (
      modifiedEvent: VideoCanvasMouseEvent,
      point: paper.Point,
    ) => any = () => {},
  ) => {
    const result = this.extendMouseEvent(event)
    if (result) {
      onDone(result.wrappedEvent, result.positionOnDrawingSurface)
    }
  }

  extendTouchEvent = (event: React.TouchEvent) => {
    const touchLists = [event.touches, event.targetTouches, event.changedTouches]

    touchLists.forEach((touchList: React.TouchList) => {
      for (let i = 0; i < touchList.length; i++) {
        const touch = touchList.item(i) as ModifiedTouch
        const positions = this.getVideoAndWorldPositionFromEvent(touch)

        if (positions) {
          const { positionOnDrawingSurface, positionInCanvas } = positions
          touch.positionInCanvas = positionInCanvas
          touch.positionInVideo = positionOnDrawingSurface
          touch.isOnDrawingCanvas = true
        } else {
          touch.isOnDrawingCanvas = false
        }
      }
    })

    return new VideoCanvasTouchEvent(event, this.props.videoController?.currentTime ?? NaN)
  }

  extendTouchEventAndCall = (event: React.TouchEvent, onDone: (modifiedEvent: VideoCanvasTouchEvent) => any = () => {}) => {
    onDone(this.extendTouchEvent(event))
  }

  onMouseMove = (event: React.MouseEvent) => {
    this.extendMouseEventAndCall(event, (modifiedEvent) => {
      if (event.buttons > 0) {
        modifiedEvent.type = 'mousedrag'
        this.props.onMouseDrag(modifiedEvent, modifiedEvent.positionOnDrawingSurface)
      }

      this.props.onMouseMove(modifiedEvent, modifiedEvent.positionOnDrawingSurface)
    })
  }

  onMouseDown = (event: React.MouseEvent) => {
    this.extendMouseEventAndCall(event, this.props.onMouseDown)
  }

  onMouseUp = (event: React.MouseEvent) => {
    this.extendMouseEventAndCall(event, this.props.onMouseUp)
  }

  onTouchStart = (event: React.TouchEvent) => {
    event.preventDefault()
    this.extendTouchEventAndCall(event, this.props.onTouchStart)
  }

  onTouchMove = (event: React.TouchEvent) => {
    this.extendTouchEventAndCall(event, this.props.onTouchMove)
  }

  onTouchEnd = (event: React.TouchEvent) => {
    event.preventDefault()
    this.extendTouchEventAndCall(event, this.props.onTouchEnd)
  }

  onClick = (event: React.MouseEvent) => {
    this.extendMouseEventAndCall(event, (event) => {
      if (!this.recalibrating) {
        this.props.onLeftClick(event, event.positionOnDrawingSurface)
      } else {
        // this.clickedCorners.push(positionInThreeWorld)

        if (this.clickedCorners.length === 4) {
          this.finishFieldRecalibration()
        } else {
          console.log(`awaiting corner ${this.clickedCorners.length + 1}`)
        }
      }
    })
  }

  onRightClick = (event: React.MouseEvent) => {
    this.extendMouseEventAndCall(event, (event) => {
      this.props.onRightClick(event, event.positionOnDrawingSurface)
    })
  }

  zoomIntoUV = (uv: { x: number, y: number }): void => {
    this.camera.lookAt(calculateGlobalPositionFromBoxUv(this.videoBox, new THREE.Vector2(uv.x, uv.y)))
  }

  startFieldRecalibration = () => {
    this.recalibrating = true
    this.clickedCorners = []
  }

  private finishFieldRecalibration = () => {
    const currentFieldCorners: Corners = {
      topLeft: this.clickedCorners[0],
      topRight: this.clickedCorners[1],
      bottomRight: this.clickedCorners[2],
      bottomLeft: this.clickedCorners[3],
    }

    console.log('clicked corners', currentFieldCorners)

    const {topLeft, topRight, bottomRight, bottomLeft} = enforceRectangle(currentFieldCorners, this.camera.position)
    const wireframeGeometry = this.paperjsCanvasWireframe?.geometry as THREE.BufferGeometry | undefined

    if (wireframeGeometry) {
      wireframeGeometry.setFromPoints([topLeft, topRight, bottomRight, bottomLeft])
      wireframeGeometry.computeVertexNormals()
      wireframeGeometry.computeBoundingBox()
      if (wireframeGeometry.attributes.position instanceof THREE.BufferAttribute) {
        wireframeGeometry.attributes.position.needsUpdate = true
      }
    }

    this.recalibrating = false
  }

  private setupThree = () => {
    if (!this.canvas || !this.props.videoController?.video) {
      throw missingDependenciesForSetupError
    }

    this.videoBox = addVideoToScene(this.scene, this.props.isQuarterBoxPanorama)

    const { renderer, controls } = setupScene(
      this.scene,
      this.camera,
      this.canvas,
      this.props.dragCanvasKey ?? MOVE_CAMERA_ON_OPTIONS.ANY,
    )

    this.setupPluginCanvas()

    this.renderer = renderer
    this.controls = controls
    this.controls.enableManualZoom = !this.props.disableZoom

    this.firePaperjsBoxDimensionsUpdateHandler()

    adjustCanvasSize(this.camera, renderer, renderer.domElement)

    // Draw!
    this.renderThree()

    this.unregisterCanvasEventHandlers()
    this.unregisterCanvasEventHandlers = registerEventHandlers({
      camera: this.camera,
      renderer,
      getDisableZoom: this.getDisableZoom,
      extendMouseEvent: this.extendMouseEvent,
    })

    // console.log(this.startFieldRecalibration)
    this.hasThreeSetupBeenCompleted = true

    // Perhaps video was ready before video canvas was ready, so the video texture should be set again. Just in case...
    if (this.state.videoIsReady) {
      this.setVideoTexture()
    }
  }

  private setupPluginCanvas() {
    // Retrieve panoramaCorners, compute index of first invalid corner (if no corners exist, 0)
    // and then set panoramaCornersAsVectors if all corners exist and are valid
    // const panoramaCorners = this.props.playingFieldCornersInVideo?.panorama_corners
    const panoramaCorners = this.props.isQuarterBoxPanorama ? this.props.playingFieldCornersInVideo?.quarterBoxPanorama : this.props.playingFieldCornersInVideo?.panorama_corners
    const indexOfFirstInvalidCorner = panoramaCorners?.findIndex((coordinate?: number | null) => coordinate && (coordinate < 0 || coordinate > 1)) ?? 0

    const panoramaCornersAsVectors = indexOfFirstInvalidCorner === -1 && panoramaCorners ?
      // All coordinates are valid (i.e. they're clamped between 0 and 1)
      [
        new THREE.Vector2(panoramaCorners[0], panoramaCorners[1]),
        new THREE.Vector2(panoramaCorners[2], panoramaCorners[3]),
        new THREE.Vector2(panoramaCorners[4], panoramaCorners[5]),
        new THREE.Vector2(panoramaCorners[6], panoramaCorners[7]),
      ] :
      undefined

    // Setup plugin canvas if corners either exist or are ignored anyway
    if (!(this.props.drawOnField || this.props.drawOnCylinder) || panoramaCornersAsVectors) {
      const { paperjsBox, paperjsCanvasTexture, paperjsCanvasWireframe, onRender } = setupPluginCanvas({
        scene: this.scene,
        camera: this.camera,
        videoBox: this.videoBox,
        pluginCanvas: this.props.pluginCanvasController.canvas,
        showOriginalCornerPositions: this.props.showOriginalCornerPositions,
        isQuarterBoxPanorama: this.props.isQuarterBoxPanorama,
        vrGridOpacity: this.props.vrGridOpacity,
        positionsInVideoAsVectors: panoramaCornersAsVectors,
        drawOnField: this.props.drawOnField,
        drawOnCylinder: this.props.drawOnCylinder,
      })

      this.paperjsBox = paperjsBox
      this.paperjsCanvasTexture = paperjsCanvasTexture
      this.paperjsCanvasWireframe = paperjsCanvasWireframe
      this.updatePluginCanvasOnRender = onRender

      this.hasPluginCanvasSetupBeenCompleted = true
    }
  }

  private getDisableZoom = () => this.props.disableZoom

  private renderThree = () => {
    this.controls.update()
    const fps = window.fps ?? 30
    const fpsThreshold = 1000 / fps

    if (this.lastVideoState !== this.props.videoController?.isPlaying) {
      this.overdraft = 0
      this.lastVideoState = this.props.videoController?.isPlaying ?? false
    }

    const overdraftRendering = this.overdraft < this.overdraftLimit

    const now = Date.now()
    const elapsedRealTime = now - this.lastAnimationTimestamp
    const renderThisFrame =  elapsedRealTime > fpsThreshold

    if (renderThisFrame) {
      this.lastAnimationTimestamp = now

      if (this.videoTexture && (overdraftRendering || (this.videoTexture.image.HAVE_CURRENT_DATA && this.props.videoController?.isPlaying))) {
        this.videoTexture.needsUpdate = true
      }
      if (overdraftRendering || !this.props.videoController?.isPlaying) {
        // TODO: Render for One or More Viable Frames just after Playing Resumed to corectly Clear Vr-Plugin Canvas
        this.paperjsCanvasTexture.needsUpdate = renderThisFrame //this.props.videoController?.isPlaying ?? true
      }

      this.updatePluginCanvasOnRender?.()

      this.renderer.render(this.scene, this.camera)
    }

    this.renderAnimationFrameRequest = requestAnimationFrame(this.renderThree)
  }

  private firePaperjsBoxDimensionsUpdateHandler = (): void => {
    if (this.paperjsBox) {
      const geometry = this.paperjsBox.geometry as THREE.Geometry

      const width = geometry.vertices[0].distanceTo(geometry.vertices[1])
      const height = geometry.vertices[1].distanceTo(geometry.vertices[2])

      this.props.onPluginCanvasDimensionsUpdate?.({ width, height })
    }
  }

  private setVideoTexture = () => {
    if (this.videoBox) {
      this.videoTexture =
        setTextureToVideo(this.videoBox, this.props.videoController!.video, this.props.isQuarterBoxPanorama)
    }
  }

  onVideoIsReady = () => {
    this.setState({ videoIsReady: true })

    this.setVideoTexture()
  }

  render = () => {
    return (
      <>
        <canvas
          ref={this.canvasRef}
          onClick={this.onSingleClick(this.onClick)}
          onContextMenu={this.onRightClick}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseMove={this.onMouseMove}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          style={{ touchAction: 'none' }}
          // Otherwise, some screens (like smart phones) would recieve a pixel ratio of 2
          // (meaning canvas width and height double)
          data-paper-hidpi='off'
        />
        <div className='children'>
          {this.props.children}
        </div>
      </>
    )
  }
}

export default ThreejsVrVideoCanvasController
