import * as THREE from 'three'
import type { Corners } from './enforceRectangle'

// The next three functions are from
// https://stackoverflow.com/questions/41661751/three-js-calculate-3d-coordinates-from-uv-coordinates/41920581#41920581

// Recursively traverse through the model.
const traversePolygonsForGeometries = (node: THREE.Mesh, uv: THREE.Vec2): THREE.Vector3 => {
  if (node.geometry) {
    // Return a list of triangles that have the point within them.
    // The returned objects will have the x,y,z barycentric coordinates of the point inside the respective triangle
    const geometry = node.geometry as THREE.Geometry
    const baryData = annotationTest(uv, geometry.faceVertexUvs)
    if (baryData) {
      const face = geometry.faces[baryData[0]]
      // In my case I only want to return materials with certain names.
      // Find the vertices corresponding to the triangle in the model
      const vertexA = geometry.vertices[face.a]
      const vertexB = geometry.vertices[face.b]
      const vertexC = geometry.vertices[face.c]

      // Sum the barycentric coordinates and apply to the vertices to get the coordinate in local space
      const worldX = vertexA.x * baryData[1] + vertexB.x * baryData[2] + vertexC.x * baryData[3]
      const worldY = vertexA.y * baryData[1] + vertexB.y * baryData[2] + vertexC.y * baryData[3]
      const worldZ = vertexA.z * baryData[1] + vertexB.z * baryData[2] + vertexC.z * baryData[3]
      const vector = new THREE.Vector3(worldX, worldY, worldZ)
      // Translate to world space
      return vector.applyMatrix4(node.matrixWorld)
    } else {
      throw new Error(`No bary data found for UV (${uv.x}, ${uv.y})`)
    }
  }

  for (let i = 0; i < node.children?.length ?? 0; i++) {
    const worldVectorPoint = traversePolygonsForGeometries(node.children[i] as THREE.Mesh, uv)
    if (worldVectorPoint) {
      return worldVectorPoint
    }
  }
}

// Loops through each face vertex UV item and tests if it is within the triangle.
const annotationTest = (uv: THREE.Vec2, faceVertexUvArray: THREE.Vec2[][][]) => {
  for (let i = 0; i < faceVertexUvArray[0].length; i++) {
    const result = ptInTriangle(
      uv,
      faceVertexUvArray[0][i][0],
      faceVertexUvArray[0][i][1],
      faceVertexUvArray[0][i][2],
    )
    if (result.length) {
      return [i, result[0], result[1], result[2]]
    }
  }
}

// This is a standard barycentric coordinate function.
const ptInTriangle = (p: THREE.Vec2, p0: THREE.Vec2, p1: THREE.Vec2, p2: THREE.Vec2): number[] => {
  const x0 = p.x
  const y0 = p.y
  const x1 = p0.x
  const y1 = p0.y
  const x2 = p1.x
  const y2 = p1.y
  const x3 = p2.x
  const y3 = p2.y

  const b0 = (x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1)
  const b1 = ((x2 - x0) * (y3 - y0) - (x3 - x0) * (y2 - y0)) / b0
  const b2 = ((x3 - x0) * (y1 - y0) - (x1 - x0) * (y3 - y0)) / b0
  const b3 = ((x1 - x0) * (y2 - y0) - (x2 - x0) * (y1 - y0)) / b0

  if (b1 > 0 && b2 > 0 && b3 > 0) {
    return [b1, b2, b3]
  } else {
    return []
  }
}

export const calculateGlobalPositionFromBoxUv = (videoBox: THREE.Mesh, uv: THREE.Vector2): THREE.Vector3 => {
  return traversePolygonsForGeometries(videoBox, new THREE.Vector2(uv.x, 1 - uv.y))
}

export const videoBoxUvsToGameFieldPosition = (videoBox: THREE.Mesh, cornersAsUVs: THREE.Vector2[]): Corners => {
  const cornersAsGlobalCoordinates = cornersAsUVs.map(
    cornerAsUv => calculateGlobalPositionFromBoxUv(videoBox, cornerAsUv),
  )

  return {
    bottomLeft: cornersAsGlobalCoordinates[0],
    topLeft: cornersAsGlobalCoordinates[1],
    topRight: cornersAsGlobalCoordinates[2],
    bottomRight: cornersAsGlobalCoordinates[3],
  }
}
