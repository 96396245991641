import paper, { Point } from 'paper'
import {
  getPaperPointFromEvent,
} from './videoTools'

type TouchEventCacheItem = {
  first: Touch,
  curr: Touch,
  prev?: Touch,
}

export class TouchEventManager {
  touchEventCache: TouchEventCacheItem[] = []
  scaleBeforePinch: number = NaN
  setPosition: (position: paper.Point) => any
  getCanvasDataAsVectors: () => { topLeft: paper.Point }
  setScale: (scale: number, callback?: () => void, center?: paper.Point) => void
  _activateTouchControls: boolean
  getCurrentScale: () => number
  ignoreSingleTouch: boolean

  constructor ({
    setPosition = () => {},
    getCanvasDataAsVectors = () => ({ topLeft: new Point(0, 0) }),
    setScale = () => {},
    activateTouchControls = true,
    getCurrentScale = () => NaN,
    ignoreSingleTouch = false,
  }: {
    setPosition: (position: paper.Point) => any,
    getCanvasDataAsVectors: () => { topLeft: paper.Point },
    setScale: (scale: number, callback?: () => void, center?: paper.Point) => void,
    activateTouchControls: boolean,
    getCurrentScale: () => number,
    ignoreSingleTouch: boolean,
  }) {
    this.setPosition = setPosition
    this.getCanvasDataAsVectors = getCanvasDataAsVectors
    this.setScale = setScale
    this._activateTouchControls = activateTouchControls
    this.getCurrentScale = getCurrentScale
    this.ignoreSingleTouch = ignoreSingleTouch
  }

  calcScaleAndPositionAfterTouch = () => {
    this.dragVideo()
    // Only zooms if touches.length === 2
    this.zoomVideo()
  }

  private dragVideo() {
    if ((this.touchEventCache.length === 1 && !this.ignoreSingleTouch) || this.touchEventCache.length >= 2) {
      // Drag by average of touch movement without zooming
      const delta = this.touchEventCache.reduce((addedDelta, currEvent) => {
        let result = addedDelta

        if (currEvent.prev && currEvent.curr) {
          result = result.add(getPaperPointFromEvent(currEvent.curr).subtract(getPaperPointFromEvent(currEvent.prev)))
        }

        return result
      }, new Point(0, 0)).divide(this.touchEventCache.length)
      this.setPosition(delta)
    }
  }

  private zoomVideo() {
    const touchesContainAllNeededData = this.touchEventCache.reduce(
      (containAllDataSoFar, currEvent) =>
        Boolean(currEvent?.first && currEvent?.prev && currEvent?.curr && containAllDataSoFar)
      , true
    )

    if (this.touchEventCache.length === 2 && touchesContainAllNeededData) {
      const firstTouchPositions = this.touchEventCache.map(cacheItem => getPaperPointFromEvent(cacheItem.first))
      const currTouchPositions = this.touchEventCache.map(cacheItem => getPaperPointFromEvent(cacheItem.curr))

      const firstDistance = firstTouchPositions[0].getDistance(firstTouchPositions[1])
      const currDistance = currTouchPositions[0].getDistance(currTouchPositions[1])

      const scalingFactor = (currDistance / firstDistance)

      const centerOfTouches = currTouchPositions.reduce((pointA, pointB) => pointA.add(pointB)).divide(currTouchPositions.length)
      const currTouchCenterInCanvas = centerOfTouches.subtract(this.getCanvasDataAsVectors().topLeft)

      // Reset scale to what it was before the pinch and then set it to the new scale (because the
      // new scale is calculated absolutely instead of relatively to the last tick)
      this.setScale(
        scalingFactor * this.scaleBeforePinch / this.getCurrentScale(),
        () => this.setPosition(new Point(0, 0)),
        currTouchCenterInCanvas,
      )
    }
  }

  _handleTouchEvent = (handler: (event: Touch) => void) => (event: TouchEvent) => {
    if (this.activateTouchControls) {
      event.preventDefault()
      for (let i = 0; i < event.changedTouches.length; i++) {
        const actualEvent = event.changedTouches[i]
        handler(actualEvent)
      }
    }
  }

  _onTouchDown = (event: Touch) => {
    this.touchEventCache.push({ first: event, curr: event })
    if (this.touchEventCache.length === 2) {
      this.scaleBeforePinch = this.getCurrentScale()
    }
  }

  onTouchDown = this._handleTouchEvent(this._onTouchDown)

  _onTouchUp = (event: Touch) => {
    if (this.touchEventCache.length === 2) {
      this.scaleBeforePinch = NaN
    }

    // Remove touch from cache
    this.touchEventCache = this.touchEventCache.filter(({first: currEvent}) => currEvent.identifier !== event.identifier)
  }

  onTouchUp = this._handleTouchEvent(this._onTouchUp)

  _onTouchMove = (event: Touch): void => {
    const correspondingCachedTouch = this.touchEventCache.find((currCacheItem) =>
      currCacheItem.first && currCacheItem.first.identifier === event.identifier
    )

    if (!correspondingCachedTouch) {
      console.error('Recieved touch move event before touch was started')
    } else {
      if (correspondingCachedTouch.curr) {
        correspondingCachedTouch.prev = correspondingCachedTouch.curr
      }

      correspondingCachedTouch.curr = event
    }
  }

  onTouchMove = (event: TouchEvent) => {
    this._handleTouchEvent(this._onTouchMove)(event)
    this.calcScaleAndPositionAfterTouch()
  }

  get activateTouchControls () {
    return this._activateTouchControls
  }

  set activateTouchControls (newValue: boolean) {
    if (!newValue) {
      this.touchEventCache.forEach(cacheItem => this.onTouchDown(cacheItem.first as any))
    }

    this._activateTouchControls = newValue
  }
}

export default TouchEventManager
