import * as THREE from 'three'
import type { Corners } from '../../vr-player/enforceRectangle'
import { rotateBoxMesh } from './setupScene'

export type DevHelpersConfig = {
  videoGridOpacity: number,
  renderOrder: number,
  showOriginalCornerPositions: boolean,
  drawingSurfaceGeometry: THREE.Geometry,
  isQuarterBoxPanorama: boolean,
  corners: Corners,
}

const addAxesHelpersToScene = (scene: THREE.Scene, camera: THREE.PerspectiveCamera, config: DevHelpersConfig) => {
  // Add visualization for which axis points where
  const axesHelper = new THREE.AxesHelper(0.3)

  if (config.videoGridOpacity > 0) {
    console.log('An axes helper is being displayed next to you: red = x, green = y, blue = z')

    const localToCameraAxesPlacement = new THREE.Vector3(0.45 * camera.aspect, -0.7, -2)
    axesHelper.position.add(localToCameraAxesPlacement)
    const axesMaterial = axesHelper.material as THREE.Material
    axesMaterial.opacity = config.videoGridOpacity
    axesMaterial.depthTest = false
    axesMaterial.transparent = true
    scene.add(axesHelper)

    if (config.renderOrder) {
      axesHelper.renderOrder = config.renderOrder + 2
    }
  }

  const onRender = () => {
    if (config.videoGridOpacity) {
      camera.updateMatrixWorld()
      // FOV (in deg) = 20 --> max zoom; FOV = 100 --> min zoom
      // Since FOV is given in deg, the distance we should be able to compute the desired distance to the camera
      // with trigonometric functions. This specific implementation doesn't work perfectly yet, but something
      // similar might.
      const distanceFromCamera = Math.sin(THREE.Math.degToRad(120 + camera.getEffectiveFOV() / 2)) * 6.5
      const localToCameraAxesPlacement = new THREE.Vector3(0.5 * camera.aspect, -0.7, -distanceFromCamera)

      const axesPlacement = camera.localToWorld(localToCameraAxesPlacement)
      axesHelper.position.copy(axesPlacement)
    }
  }

  return { axesHelper, onRender }
}

export const addDevToolsToScene = (scene: THREE.Scene, camera: THREE.PerspectiveCamera, config: DevHelpersConfig) => {
  // Add wireframe for drawing surface
  const wireframe = new THREE.WireframeGeometry(config.drawingSurfaceGeometry)
  const lineMaterial = new THREE.LineBasicMaterial(
    { color: 0xFF0000, depthTest: false, opacity: config.videoGridOpacity, transparent: true },
  )
  const wireframeMesh = new THREE.LineSegments(wireframe, lineMaterial)
  rotateBoxMesh(wireframeMesh, config.isQuarterBoxPanorama)

  scene.add(wireframeMesh)

  const { onRender } = addAxesHelpersToScene(scene, camera, config)

  if (config.renderOrder) {
    wireframeMesh.renderOrder = config.renderOrder + 1
  }

  if (config.showOriginalCornerPositions) {
    addOriginalCornersToScene(scene, config.corners, config.renderOrder + 3)
  }

  return { wireframe: wireframeMesh, onRender }
}

/**
 * Adds an orange sphere to the scene for each corner. Very useful for debugging purposes
 * @param scene
 * @param corners
 * @param renderOrder
 */
const addOriginalCornersToScene = (scene: THREE.Scene, corners: Corners, renderOrder: number) => {
  const cornerNames = Object.keys(corners) as Array<keyof Corners>
  const cornersArray = cornerNames.map(name => corners[name])
  const sphereGeometry = new THREE.SphereGeometry(0.1, 16, 16)
  const sphereMaterial = new THREE.MeshBasicMaterial({
    color: 0xFA4F02,
    depthTest: false,
    transparent: true,
    opacity: 1,
  })

  cornersArray.forEach(corner => {
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)
    sphere.position.set(corner.x, corner.y, corner.z)
    // renderOrder seems to be necessary for consistent rendering.
    // Otherwise, the spheres disappear at some camera angles
    sphere.renderOrder = renderOrder
    scene.add(sphere)
  })
}
