export default {
  nav: {
    dashboard: 'Dashboard',
    account: 'Account',
    login: 'Login',
    remote: 'Remote',
    onsite: 'vor Ort'
  },
  login: {
    login: 'Einloggen',
    logout: 'Ausloggen',
    register: 'Registrieren',
    noAcc: 'Keinen Staige.tv Account ?',
    hasAcc: 'Du hast einen Staige.tv Account ?',
    passLost: 'Passwort vergessen?',
    messages: {
      success: 'login erfolgreich',
      failed: 'login fehlgeschlagen',
      logout: 'erfolgreich ausgeloggt',
      userNotFound: 'Benutzer konnte nicht gefunden werden',
      tooManyRequests: 'Zu viele Login versuche, probieren sie es später erneut',
      wrongMailPw: 'Passwort ist Falsch'
    }
  },
  dashboard: {
    toVideo: {
      title: 'Gebe eine Spiel ID ein, um die Tags zu bearbeiten.',
      inputPlaceholder: 'Spiel ID',
      btn: 'vor ort',
      btnRemote: 'remote'
    }
  },
  sidebar: {
    btn: {
      delete: 'Löschen',
      highlight: 'Highlight',
      goalOfWeek: 'Tor der Woche'
    },
    swapTeam: 'Wechsel Team Tags',
    swapAiTags: 'Wechsel Team KI-Tags',
    clubALeftInfoText: 'Auf welche seite spielt die Heimmanschaft?',
    clubALeft: 'Links',
    clubARight: 'Rechts',
    messages: {
      successSwap: 'Team Tags wurden getauscht',
      successDeleteTag: 'Tag wurde gelöscht',
      errorDeleteTag: 'Beim löschen ist ein Fehler aufgetreten',
      setHighlightTag: 'Highlight wurde gesetzt',
      removeHighlightTag: 'Highlight wurde entfernt'
    }
  },
  tagControl: {
    home: 'Heim',
    guest: 'Gast',
    deleteLast: 'Letzten löschen'
  },
  messages: {
    error: 'Fehler aufgetreten:'
  }
}
