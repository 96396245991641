import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import './controlsButton.css'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disableControls: PropTypes.bool.isRequired,
}

export const ControlsButton: React.FC<InferProps<typeof propTypes>> = ({ onClick, children, className, disableControls }) => {
  return <button
    className={`controls-button ${className}`}
    onClick={(e) => {
      if (!disableControls) {
        onClick(e)
      }
    }}
  >
    {children}
  </button>
}

ControlsButton.propTypes = propTypes

ControlsButton.defaultProps = {
  onClick: () => {},
  className: '',
  disableControls: false,
}

export default ControlsButton
