import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import VideoController from './videoController'
import PaperjsVideoCanvasController from './videoCanvasControllers/paperjsVideoCanvasController'
import VideoCanvasController, {
  playPauseOnOptions,
  dragCanvasKey,
  VideoCanvasControllerPropsType,
} from './videoCanvasControllers/videoCanvasController'
import { PaperjsPluginCanvasController } from './pluginCanvasController/paperjsPluginCanvasController'
import { PluginCanvasController, OfferedEventHandlerNames as PluginCanvasControllerEventHandlerNames, OfferedEventHandlers as PluginCanvasControllerEventHandlers } from './pluginCanvasController/pluginCanvasController'
import ThreejsVrVideoCanvasController from './videoCanvasControllers/threejsVrVideoCanvasController'
import { Point } from 'paper'
import { LoaderForVideo } from './Loader/LoaderForVideo'

const VideoPlayerPropTypes = {
  activateTouchControls: PropTypes.bool,
  autofocus: PropTypes.bool,
  autoplay: PropTypes.bool,
  disableSkipButtons: PropTypes.bool,
  disablePrecissionSeek: PropTypes.bool,
  rotateWithPosition: PropTypes.bool,
  onProgress: PropTypes.func,
  onReady: PropTypes.func,
  src: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  needAuthorization: PropTypes.bool,
  forceHLS: PropTypes.bool,
  getAuthorization: PropTypes.func,
  controls: PropTypes.bool,
  disableDefaultShortcuts: PropTypes.bool,
  dragCanvasKey: PropTypes.number,
  evaluatePlugins: PropTypes.func,
  ignoreSingleTouch: PropTypes.bool,
  vrGridOpacity: PropTypes.number,

  // TODO: fix proptype!
  keyboardShortcuts: PropTypes.any,

  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  playPauseOn: PropTypes.oneOf(Object.values(playPauseOnOptions)),
  useVrPlayer: PropTypes.bool,
  vrSrc: PropTypes.string,
  vrGridCorners: PropTypes.shape({
    corners: PropTypes.arrayOf(PropTypes.number),
    panorama_corners: PropTypes.arrayOf(PropTypes.number),
    quarterBoxPanorama: PropTypes.arrayOf(PropTypes.number),
  }),
  isQuarterBoxPanorama: PropTypes.bool,
  drawOnField: PropTypes.bool.isRequired,
  drawOnCylinder: PropTypes.bool.isRequired,
  disableZoom: PropTypes.bool,
  recalculateScaleOnResize: PropTypes.bool,
  displayMilliseconds: PropTypes.bool,
  playbackRate: PropTypes.number,
  showPlaybackRateSetter: PropTypes.bool,
  showQualityLevelSetter: PropTypes.bool,
  playbackRateSteps: PropTypes.arrayOf(PropTypes.number.isRequired),
  showLoadingSpinner: PropTypes.bool,
  showOriginalCornerPositions: PropTypes.bool,
  shouldLogPluginCanvasSizeChange: PropTypes.bool,
}

export type VideoPlayerPropsType = OverwriteProperties<PropTypes.InferProps<typeof VideoPlayerPropTypes>, {
  skipDurations?: { slow: number, fast: number }
}>

const useVideoCanvasController = (
  videoControllerRef: React.RefObject<VideoController>,
  containerRef: React.RefObject<HTMLDivElement>,
  pluginCanvasControllerRef: React.RefObject<PluginCanvasController>,
  props: React.PropsWithChildren<VideoPlayerPropsType>,
  onDidMount: () => void,
  mouseEvents: PluginCanvasControllerEventHandlers,
  setPluginCanvasSize: (size: paper.Point) => void,
  drawOnField: boolean,
  drawOnCylinder: boolean,
): [React.ReactElement<VideoCanvasController>, () => void, React.RefObject<VideoCanvasController>] => {
  let element: React.ReactElement<VideoCanvasController> | null = null
  const videoCanvasControllerRef = useRef<VideoCanvasController>(null)

  const onVideoIsReady = useCallback(() => {
    // Resize plugin canvas according to new video dimensions
    setPluginCanvasSize(videoControllerRef.current?.videoDimensions)

    videoCanvasControllerRef.current?.onVideoIsReady()
    props.onReady()
  }, [props.onReady, videoCanvasControllerRef])

  useEffect(() => {
    if (!props.useVrPlayer) {
      setPluginCanvasSize(videoControllerRef.current?.videoDimensions)
    }
  }, [props.useVrPlayer, videoControllerRef.current?.videoDimensions.x, videoControllerRef.current?.videoDimensions.y])

  const onPluginCanvasDimensionsUpdate = useCallback(
    ({ width, height }: { width: number, height: number }) => setPluginCanvasSize(new Point(width, height).multiply(15)),
    [setPluginCanvasSize]
  )

  if (videoControllerRef.current) {
    const videoCanvasControllerProps: VideoCanvasControllerPropsType = {
      ...mouseEvents,
      disableDefaultShortcuts: props.disableDefaultShortcuts,
      keyboardShortcuts: props.keyboardShortcuts,
      videoController: videoControllerRef.current,
      autofocus: props.autofocus,
      container: containerRef.current,
      controls: props.controls,
      disableSkipButtons: props.disableSkipButtons,
      dragCanvasKey: props.dragCanvasKey,
      ignoreSingleTouch: props.ignoreSingleTouch,
      onDidMount: onDidMount,
      pluginCanvasController: pluginCanvasControllerRef.current,
      playPauseOn: props.playPauseOn,
      tabIndex: props.tabIndex,
      onLeftClick: props.onLeftClick,
      onRightClick: props.onRightClick,
      activateTouchControls: props.activateTouchControls,
      disableZoom: props.disableZoom,
    }

    if(!props.useVrPlayer) {
      element = <PaperjsVideoCanvasController
        {...videoCanvasControllerProps}
        ref={videoCanvasControllerRef as React.RefObject<PaperjsVideoCanvasController>}
        rotateWithPosition={props.rotateWithPosition}
        recalculateScaleOnResize={props.recalculateScaleOnResize}
      >
        {props.children}
      </PaperjsVideoCanvasController>
    } else {
      element = <ThreejsVrVideoCanvasController
        {...videoCanvasControllerProps}
        ref={videoCanvasControllerRef as React.RefObject<ThreejsVrVideoCanvasController>}
        onPluginCanvasDimensionsUpdate={onPluginCanvasDimensionsUpdate}
        vrGridOpacity={props.vrGridOpacity}
        showOriginalCornerPositions={props.showOriginalCornerPositions}
        playingFieldCornersInVideo={props.vrGridCorners}
        drawOnField={drawOnField}
        drawOnCylinder={drawOnCylinder}
        isQuarterBoxPanorama={props.isQuarterBoxPanorama}
        // drawCubeAroundMouse={true}
      >
        {props.children}
      </ThreejsVrVideoCanvasController>
    }
  }

  return [element, onVideoIsReady, videoCanvasControllerRef]
}

export const VideoPlayer: React.FC<VideoPlayerPropsType> & {
  playPauseOnOptions?: typeof playPauseOnOptions,
  dragCanvasKey?: typeof dragCanvasKey,
} = (props) => {
  const [randomState, setRandomState] = useState(0)
  const onVideoCanvasControllerDidMount = () => setRandomState(randomState + 1)

  const containerRef = useRef<HTMLDivElement>(null)
  const videoControllerRef = useRef<VideoController>(null)
  const pluginCanvasControllerRef = useRef<PluginCanvasController>(null)

  const eventHandlers = useMemo(
    () => {
      return Object.values(PluginCanvasControllerEventHandlerNames).reduce((handlers, currentEventName) => {
        type CurrentEventType = Parameters<PluginCanvasControllerEventHandlers[typeof currentEventName]>[0]

        // TODO: replace the as any castings with actual types! The problem here is that
        // TS needs to understand that the two types are related and that seems to be quite
        // difficult
        handlers[currentEventName] = ((event: CurrentEventType, position: paper.Point) => {
          pluginCanvasControllerRef.current?.[currentEventName]?.(event as any, position)
        }) as any

        return handlers
      }, {} as PluginCanvasControllerEventHandlers)
    },
    [PluginCanvasControllerEventHandlerNames, pluginCanvasControllerRef],
  )

  const [pluginCanvasSize, setPluginCanvasSize] = useState(new Point(0, 0))
  const setAndLogPluginCanvasSize = useCallback((newSize: paper.Point) => {
    if (props.shouldLogPluginCanvasSizeChange) {
      console.groupCollapsed('Setting canvas size', { x: newSize.x, y: newSize.y })
      console.trace()
      console.groupEnd()
    }

    setPluginCanvasSize(newSize)
  }, [props.shouldLogPluginCanvasSizeChange])

  const [videoCanvasControllerNode, notifyCanvasControllerWhenVideoReady, videoCanvasControllerRef] = useVideoCanvasController(
    videoControllerRef,
    containerRef,
    pluginCanvasControllerRef,
    props,
    onVideoCanvasControllerDidMount,
    eventHandlers,
    setAndLogPluginCanvasSize,
    props.drawOnField,
    props.drawOnCylinder,
  )

  const [videoIsReady, setVideoIsReady] = useState(false)
  const onVideoReady = () => {
    notifyCanvasControllerWhenVideoReady()
    setVideoIsReady(true)
  }

  // tabIndex is required in order to make root element focusable
  return <div className={`video-player`} ref={containerRef} tabIndex={props.tabIndex}>
    <VideoController
      ref={videoControllerRef}
      containerRef={containerRef}
      autofocus={props.autofocus}
      autoplay={props.autoplay}
      disableSkipButtons={props.disableSkipButtons}
      onProgress={props.onProgress}
      onReady={onVideoReady}
      rotateWithPosition={props.rotateWithPosition}
      src={props.useVrPlayer && props.vrSrc ? props.vrSrc : props.src}
      tabIndex={props.tabIndex}
      needAuthorization={props.needAuthorization}
      forceHLS={props.forceHLS}
      getAuthorization={props.getAuthorization}
      controls={props.controls}
      pluginCanvasController={pluginCanvasControllerRef}
      disablePrecissionSeek={props.disablePrecissionSeek}
      displayMilliseconds={props.displayMilliseconds}
      playbackRate={props.playbackRate}
      showPlaybackRateSetter={props.showPlaybackRateSetter}
      showQualityLevelSetter={props.showQualityLevelSetter}
      playbackRateSteps={props.playbackRateSteps}
      skipDurations={props.skipDurations}
    />

    <LoaderForVideo
      videoController={videoControllerRef.current}
      videoIsReady={videoIsReady}
      showLoaderForVideo={props.showLoadingSpinner}
    />
    
    { videoCanvasControllerNode }

    { videoCanvasControllerRef.current ?
      <PaperjsPluginCanvasController
        ref={pluginCanvasControllerRef as React.RefObject<PaperjsPluginCanvasController>}
        evaluatePlugins={props.evaluatePlugins}
        size={pluginCanvasSize}
        videoCanvasController={videoCanvasControllerRef.current}
        videoController={videoControllerRef.current}
      /> : null
    }
  </div>
}

VideoPlayer.playPauseOnOptions = playPauseOnOptions
VideoPlayer.dragCanvasKey = dragCanvasKey
VideoPlayer.defaultProps = {
  autofocus: false,
  onReady: () => {},
  useVrPlayer: false,
  // tabIndex is required in order to make root element focusable
  tabIndex: 0,
  vrGridOpacity: 0,
  drawOnField: false,
  showOriginalCornerPositions: false,
  isQuarterBoxPanorama: false,
  drawOnCylinder: false,
  showQualityLevelSetter: false,
}
