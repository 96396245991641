import { VideoPlayer as Video } from './newVideoPlayer'

export { Video }
export * as VideoTools from './videoTools'
export { ControlBar } from './controls/controlBar'
export { ControlBarForHtmlVideo } from './controls/controlBarForHtmlVideo'
export { PluginCanvasController, PluginCanvasControllerPropsType, PluginCanvasControllerStateType, OfferedEventHandlers } from './pluginCanvasController/pluginCanvasController'
export { VideoCanvasController, VideoCanvasControllerPropsType, VideoCanvasControllerStateType } from './videoCanvasControllers/videoCanvasController'
export { VideoController, VideoControllerPropsType, VideoControllerStateType } from './videoController'
export { KeyboardShortcuts } from './videoCanvasControllers/KeyboardShortcuts'

export default Video
