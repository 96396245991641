import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward, faFastForward, faBackward, faFastBackward, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import PropTypes, { InferProps } from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './skipButton.css'

const propTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']),
  speed: PropTypes.oneOf(['fast', 'slow']),
  onSkip: PropTypes.func.isRequired,
  disableControls: PropTypes.bool,
  skipDuration: PropTypes.number.isRequired,
}

export const SkipButton: React.FC<InferProps<typeof propTypes>> = ({
  direction = 'forward',
  speed = 'slow',
  onSkip = () => {},
  disableControls = false,
  skipDuration,
}) => {
  let icon: IconDefinition | null = null
  let className = 'skip skip-' + direction + '-' + speed
  let currTimeDelta = skipDuration * (direction === 'forward' ? 1 : -1)

  if (direction === 'forward') {
    if (speed === 'slow') {
      icon = faForward
    } else {
      icon = faFastForward
    }
  } else {
    if (speed === 'slow') {
      icon = faBackward
    } else {
      icon = faFastBackward
    }
  }

  return <ControlsButton
    className={className}
    onClick={() => { if (!disableControls) onSkip(currTimeDelta) }}
  >
    <FontAwesomeIcon icon={icon} />

  </ControlsButton>
}

SkipButton.propTypes = propTypes

export default SkipButton
