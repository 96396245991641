import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VideoController from '../videoController'
import type {
  MouseEventHandler,
  OfferedEventHandlers as PluginCanvasControllerEventHandlers,
  PluginCanvasController,
} from '../pluginCanvasController/pluginCanvasController'
import type { KeyboardShortcuts } from './KeyboardShortcuts'
import { generateShortcuts } from './defaultKeyboardShortcuts'

type FilterFlags<Base, Condition> = {
  [Key in keyof Base]:
    Base[Key] extends Condition ? Key : never
}

type AllowedNames<Base, Condition> =
  FilterFlags<Base, Condition>[keyof Base]

type SubType<Base, Condition> =
  Pick<Base, AllowedNames<Base, Condition>>

export enum playPauseOnOptions {
  spaceButtonPress = 'spaceButtonPress',
  leftClick = 'leftClick',
  rightClick = 'rightClick',
  none = 'none',
}

export enum dragCanvasKey {
  leftMouseButton = 0,
  middleMouseButton = 1,
  rightMouseButton = 2,
}

export type VideoCanvasControllerStateType = {

}

export const VideoCanvasControllerPropsTypes = {
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDrag: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchCancel: PropTypes.func,
  playPauseOn: PropTypes.oneOf(Object.values(playPauseOnOptions)),
  controls: PropTypes.bool,
  tabIndex: PropTypes.number,
  autofocus: PropTypes.bool,

  // TODO: fix type
  keyboardShortcuts: PropTypes.any,

  disableDefaultShortcuts: PropTypes.bool,
  ignoreSingleTouch: PropTypes.bool,
  dragCanvasKey: PropTypes.number,
  videoController: PropTypes.instanceOf(VideoController),
  pluginCanvasController: PropTypes.instanceOf(React.Component),
  onDidMount: PropTypes.func,
  container: PropTypes.instanceOf(HTMLElement),
  disableSkipButtons: PropTypes.bool,
  activateTouchControls: PropTypes.bool.isRequired,
  disableZoom: PropTypes.bool.isRequired,
}

type MoreDetailedPropTypes = PluginCanvasControllerEventHandlers &
{
  pluginCanvasController: PluginCanvasController,
  keyboardShortcuts: KeyboardShortcuts,
  onLeftClick: MouseEventHandler,
  onRightClick: MouseEventHandler,
  onDidMount: () => void,
}

export type VideoCanvasControllerPropsType = OverwriteProperties<PropTypes.InferProps<typeof VideoCanvasControllerPropsTypes>, MoreDetailedPropTypes>

export abstract class VideoCanvasController<P = {}, S = {}, SS = any> extends Component<P & VideoCanvasControllerPropsType, S & VideoCanvasControllerStateType, SS> {
  static playPauseOnOptions = playPauseOnOptions

  static dragCanvasKey = dragCanvasKey

  static propTypes = VideoCanvasControllerPropsTypes

  static defaultProps: Partial<VideoCanvasControllerPropsType> = {
    onLeftClick: () => {},
    onRightClick: () => {},
    onMouseUp: () => {},
    onMouseDown: () => {},
    onMouseMove: () => {},
    onMouseDrag: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    onKeyPress: () => {},
    onTouchStart: () => {},
    onTouchMove: () => {},
    onTouchEnd: () => {},
    onTouchCancel: () => {},
    playPauseOn: playPauseOnOptions.leftClick,
    controls: false,
    // tabindex of -1 is focusable by JavaScript, but not by keyboard
    tabIndex: -1,
    autofocus: false,
    keyboardShortcuts: {},
    disableDefaultShortcuts: false,
    ignoreSingleTouch: false,
    dragCanvasKey: dragCanvasKey.middleMouseButton,
    videoController: null,
    onDidMount: () => {},
    activateTouchControls: true,
    disableZoom: false,
  }

  abstract canvas: HTMLCanvasElement | null
  abstract onVideoIsReady: () => void

  protected readonly defaultKeyboardShortcuts: KeyboardShortcuts

  protected constructor(props: P & VideoCanvasControllerPropsType, context: any) {
    super(props, context)

    this.defaultKeyboardShortcuts = generateShortcuts({
      videoCanvas: props.videoController,
      getRootContainer: () => this.props.container,
      shouldPlayPauseOnSpace: () => this.props.playPauseOn === VideoCanvasController.playPauseOnOptions.spaceButtonPress,
      togglePlaying: () => props.videoController?.togglePlaying(),
    })
  }

  onSingleClick = (callback: React.MouseEventHandler): React.MouseEventHandler => {
    let timeout: NodeJS.Timeout | null = null

    return (event, ...otherArgs) => {
      // React "recycles" the event object so its data must be saved manually
      const eventCopy = { ...event }
      if (timeout !== null) {
        // first click was not cleared yet --> double click!
        clearTimeout(timeout)
        timeout = null
      } else {
        // first click --> wait a moment before 'firing event'
        timeout = setTimeout(() => {
          if (timeout !== null) {
            clearTimeout(timeout)
            timeout = null
          }
          callback(eventCopy, ...otherArgs)
        }, 200)
      }
    }
  }

  protected get keyboardShortcuts() {
    return {
      ...(this.props.disableDefaultShortcuts ? {} : this.defaultKeyboardShortcuts),
      ...this.props.keyboardShortcuts,
    }
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  // From here on, everything is legacy code to support old plugins.
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  // TODO: remove any[] type
  private passAlongVideoControllerFunction = (functionName: Exclude<keyof SubType<VideoController, Function>, undefined>) => (...args: any[]) => {
    console.warn(`Using VideoCanvasController.${functionName} is deprecated! Please switch to the equivalent method of VideoController.`)
    if (this.props.videoController && this.props.videoController[functionName] && typeof this.props.videoController[functionName] === 'function') {
      return this.props.videoController[functionName](...args)
    }
  }

  /**
   * @deprecated
   */
  subscribeToSeek = this.passAlongVideoControllerFunction('subscribeToSeek')

  /**
   * @deprecated
   */
  unsubscribeFromSeek = this.passAlongVideoControllerFunction('unsubscribeFromSeek')

  /**
   * @deprecated
   */
  subscribeToBuffering = this.passAlongVideoControllerFunction('subscribeToBuffering')

  /**
   * @deprecated
   */
  pauseVideo = this.passAlongVideoControllerFunction('pause')

  /**
   * @deprecated
   */
  playVideo = this.passAlongVideoControllerFunction('play')
}

export default VideoCanvasController
