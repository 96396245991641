import React from 'react'

import './player.scss'

export default class AiswPlayer extends React.Component {
  playerRef = React.createRef()

  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.createPlayer(this.props.matchId)
  }

  createPlayer = (videoId) => {
    if (this.playerRef.current) {
      // This is only the player element, not the actual instance with all the methods etc.
      const playerElement = document.createElement('aisw-player')
      playerElement.options = {
        gameId: videoId,
        volume: 0,
        autoplay: false,
        overlayControl: true,
        login: false,
        chat: false,
        // callAs: 'admin',
        adFree: true,
        paywallFree: true,
        events: false,
        embed: false,
        media: false,
        lazy: true,
        seekButtons: [-5,-1,1,5]
      }

      playerElement.addEventListener('hello', async (event) => {
        const actualPlayer = event.detail

        actualPlayer.gameService.matchCache = { [this.props.matchId]: this.props.video }

        this.props.evaluatePlugins(undefined, undefined, actualPlayer)

        actualPlayer.timeupdate.subscribe((event) => {
          this.props.onVideoProgress(event.srcElement.currentTime)
        })

        actualPlayer.switchToVideo(playerElement.options)

        await actualPlayer.userService.firstAuthDone
      })

      this.playerRef.current.append(playerElement)

      this.setState({
        player: playerElement
      })
    }
  }

  render () {
    return (
      <div className='staige-player'>
        <div className='content-player' ref={this.playerRef} />
        {this.props.children}
      </div>
    )
  }
}
