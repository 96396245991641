import * as THREE from 'three'
import nerdamer from 'nerdamer'
import 'nerdamer/Algebra'
import 'nerdamer/Calculus'
import 'nerdamer/Solve'

export type Corners = {
  topLeft: THREE.Vector3,
  topRight: THREE.Vector3,
  bottomLeft: THREE.Vector3,
  bottomRight: THREE.Vector3,
}

export type line = (parameter: number) => THREE.Vector3

export const createLineThroughTwoPoints = (pointA: THREE.Vector3, pointB: THREE.Vector3): line =>
  (parameter) => pointA.clone().add(pointB.clone().sub(pointA).multiplyScalar(parameter))

export const saveInputToNerdamer = (corners: Corners, cameraPosition: THREE.Vector3) => {
  nerdamer.setVar('camera', `vector(${cameraPosition.toArray().join(', ')})`)

  nerdamer.setVar('fakeTopLeft', `vector(${corners.topLeft.toArray().join(', ')})`)
  nerdamer.setVar('fakeTopRight', `vector(${corners.topRight.toArray().join(', ')})`)
  nerdamer.setVar('fakeBottomLeft', `vector(${corners.bottomLeft.toArray().join(', ')})`)
  nerdamer.setVar('fakeBottomRight', `vector(${corners.bottomRight.toArray().join(', ')})`)
}

/**
 * Setup lines that go through the fake corners and the camera
 * (and thus will also contain the real corners)
 */
export const setLinesThroughOtherCorners = () => {
  const lineTemplate = (pointName: string) => `camera + l * (${pointName} - camera)`
  nerdamer.setFunction('lineTopRight', ['l'], lineTemplate('fakeTopRight'))
  nerdamer.setFunction('lineBottomLeft', ['l'], lineTemplate('fakeBottomLeft'))
  nerdamer.setFunction('lineBottomRight', ['l'], lineTemplate('fakeBottomRight'))
}

export const nerdamerVectorToThreeVector = (nerdamerVector: nerdamer.Expression): THREE.Vector3 => {
  let coordinates = nerdamerVector.text()
    .split(/[\[\],]+/gm)
    .map(s => parseFloat(s))
    .filter(n => !Number.isNaN(n))

  return new THREE.Vector3(...coordinates)
}

export const findClosestPointOnLine = (linePointA: THREE.Vector3, linePointB: THREE.Vector3, otherPoint: THREE.Vector3): THREE.Vector3 => {
  // code from https://stackoverflow.com/questions/52791641/distance-from-point-to-line-3d-formula
  const richtungsVektor = linePointB.clone().sub(linePointA).normalize();
  const d = otherPoint.clone().sub(linePointA).dot(richtungsVektor);
  return linePointA.clone().add(richtungsVektor.clone().multiplyScalar(d));
}

/**
 * Calculates error as distance from resulting bottomLeft to camera viewing vector with actual bottomLeft.
 * @param parameterForOptimalBottomLeft
 * @param cameraPosition
 * @param corners
 */
const calculateErrorForChosenBottomLeftParam = (parameterForOptimalBottomLeft: number, cameraPosition: THREE.Vector3, corners: Corners): number => {
  const { bottomLeft: idealBottomLeft } = calculateCornersFromParam(parameterForOptimalBottomLeft)

  const closestBottomLeft = findClosestPointOnLine(cameraPosition, corners.bottomLeft, idealBottomLeft)
  const delta = idealBottomLeft.clone().sub(closestBottomLeft).length()

  return delta
}

/**
 * Get perfect rectangle coords based on param for top right corner. Bottom left corner is calculated to complete
 * rectangle
 * @param param
 */
const calculateCornersFromParam = (param: number): Corners => {
  const topLeft = nerdamerVectorToThreeVector(nerdamer('topLeft'))
  const topRight = nerdamerVectorToThreeVector(nerdamer(`topRight(${param})`))
  const bottomRight = nerdamerVectorToThreeVector(nerdamer(`bottomRight(${param})`))
  const bottomLeft = topLeft.clone().add(bottomRight).sub(topRight)

  return { topLeft, topRight, bottomLeft, bottomRight }
}

export const optimizeValueUsingErrorFunction = (errorFunction: (param: number) => number, learningRate: number = 0.001): number => {
  let currentValue = 1

  for (let i = 0; i < 100; i++) {
    // Calculate distance to view line for currentValue and currentValue + learningRate
    const errorAtCurrentValue = errorFunction(currentValue)
    const errorAfterDelta = errorFunction(currentValue + learningRate)

    // Determine whether learningRate improved or worsened the distance
    const errorDifference = errorAtCurrentValue - errorAfterDelta

    // Move currentValue by current error times learningRate in needed distance determined by sign of errorDifference
    currentValue = currentValue + Math.sign(errorDifference) * errorAfterDelta * learningRate
  }

  return currentValue
}

const calculateOptimalCorners = (corners: Corners, cameraPosition: THREE.Vector3) => {
  const optimalParam = optimizeValueUsingErrorFunction((num: number) => calculateErrorForChosenBottomLeftParam(num, cameraPosition, corners))

  return calculateCornersFromParam(optimalParam)
}

export const enforceRectangle = (
  corners: Corners,
  cameraPosition: THREE.Vector3,
): Corners => {
  saveInputToNerdamer(corners, cameraPosition)

  // We're keeping the top left corner, as we could rotate our view of the rectangle
  // to fit this perspective anyway
  nerdamer.setVar('topLeft', 'fakeTopLeft')

  setLinesThroughOtherCorners()

  // Next, we will try to define all other corners relatively to one
  // single variable, which we will then optimize
  nerdamer.setFunction('topRight', ['l'], 'lineTopRight(l)')

  nerdamer.setFunction('lineTopLeftTopRight', ['l'], 'lineTopRight(l) - topLeft')
  nerdamer.setFunction('lineTopRightBottomRight', ['l', 'm'], 'lineBottomRight(m) - lineTopRight(l)')
  const bottomRightParameter = nerdamer.solveEquations('dot(lineTopLeftTopRight(l), lineTopRightBottomRight(l, m)) = 0', 'm')
  nerdamer.setFunction('bottomRight', ['l'], `lineBottomRight(${bottomRightParameter.toString()})`)

  return calculateOptimalCorners(corners, cameraPosition)
}
