import React from "react";
import "./loadingSpinner.scss";

export const loadingSpinner = props => {
  return (
    <div className="loader">
      <div className="inner one" />
      <div className="inner two" />
      <div className="inner three" />
    </div>
  );
};

export default loadingSpinner;
