import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { createLoginHeader } from '../../helper/globalHelper'

rax.attach()

export const getMeta = async id => {
  return axios.get(`${discovery.API_VIDEO}/meta/${id}/ `, await createLoginHeader())
}

export const postMetaId = async (json, id) => {
  return axios.post(discovery.API_VIDEO + '/meta/' + id, JSON.stringify(json), await createLoginHeader())
}
