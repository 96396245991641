export type KeyboardShortcuts = {
  [k: string]: {
    code: string,
    requiresOtherPressedKeys: {
      ctrlKey?: boolean,
      shiftKey?: boolean,
      altKey?: boolean,
      metaKey?: boolean,
    },
    handler: (event: KeyboardEvent) => void,
  }
}

export const executeAppropriateShortcuts = (keyboardShortcuts: KeyboardShortcuts, event: KeyboardEvent) => {
  Object.values(keyboardShortcuts).forEach(shortcut => {
    if (shortcut.code === event.code) {
      const isAdditionalConditionFulfilled = (keyName: keyof typeof shortcut.requiresOtherPressedKeys) => !(
        shortcut.requiresOtherPressedKeys &&
        typeof shortcut.requiresOtherPressedKeys[keyName] === 'boolean' &&
        shortcut.requiresOtherPressedKeys[keyName] !== event[keyName]
      )

      const controlConditionFulfilled = isAdditionalConditionFulfilled('ctrlKey')
      const shiftConditionFulfilled = isAdditionalConditionFulfilled('shiftKey')
      const altConditionFulfilled = isAdditionalConditionFulfilled('altKey')
      const metaConditionFulfilled = isAdditionalConditionFulfilled('metaKey')

      if (controlConditionFulfilled && shiftConditionFulfilled && altConditionFulfilled && metaConditionFulfilled) {
        shortcut.handler(event)
      }
    }
  })
}
