import React from "react";
import i18n from "i18next";
import AppHeader from "../helper/appHeader";

import InputToMatch from "./container/inputToMatch";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AppHeader title={i18n.t("nav.dashboard")}>
        <InputToMatch />
      </AppHeader>
    );
  }
}
