import * as THREE from 'three'
import type { Corners } from '../../vr-player/enforceRectangle'

// Eishockeyfeld
export const soccerFieldCorners1: Corners = {
  topLeft: new THREE.Vector3(11.442673381989549, -7.2549510357401665, -51.7858567206194100),
  topRight: new THREE.Vector3(52.531927400686520, -4.9227296676742580, 2.8240357218394854),
  bottomLeft: new THREE.Vector3(-12.491849503841975, -10.5223029798034520, -50.2622654872150800),
  bottomRight: new THREE.Vector3(47.072510025322565, -7.8108443071515160, 24.3333702176184200),
}

// Fußballfeld
export const soccerFieldCorners2: Corners = {
  bottomLeft: new THREE.Vector3(-33.033582576532396, -11.990895432901212, -49.57744957962695),
  bottomRight: new THREE.Vector3(42.85248885067871, -8.232848397787553, 44.842183501067595),
  topRight: new THREE.Vector3(53.458790786643476, -3.3572229375796656, 1.4780597976906944),
  topLeft: new THREE.Vector3(10.955438067846277, -5.697871425035114, -52.51193486744818),
}

// Maxima Fußballfeld
export const rectangleCalculatedByMaxima: Corners = {
  topLeft: new THREE.Vector3(10.955438067846277, -5.697871425035114, -52.51193486744818),
  bottomLeft: new THREE.Vector3(-18.717612672496, -6.910380539956301, -29.09940802195865),
  bottomRight: new THREE.Vector3(23.78574004630119, -4.569732052500854, 24.89058664318026),
  topRight: new THREE.Vector3(53.45879078664351, -3.35722293757967, 1.478059797690695),
}

export const clickedCornersForCenterSquareInHockeyField: Corners = {
  topLeft: new THREE.Vector3(39.16718661662227, -12.219209504908545, -49.47098497938539),
  topRight: new THREE.Vector3(56.54435899638236, -12.057839653282706, -32.837681791938735),
  bottomRight: new THREE.Vector3(46.96153230799634, -24.65228250823374, -11.220072366289452),
  bottomLeft: new THREE.Vector3(16.886354258540166, -24.592343324374543, -43.70129792399179),
}

export const clickedCornersInHockeyField: Corners = {
  topLeft: new THREE.Vector3(12.819628041136792, -9.20273089682413, -50.87759205499588),
  topRight: new THREE.Vector3(52.931574047685956, -7.746731487676283, -5.25999696110793),
  bottomRight: new THREE.Vector3(46.128335161782005, -12.39257487627633, 19.298877493370682),
  bottomLeft: new THREE.Vector3(-11.642789069860797, -14.810696395595418, -48.26255479624981),
}

export const quarterBoxPanorama51121: Corners = {
  topLeft: new THREE.Vector3(-37.20443309072883, -13.328048347444676, -50.00000000000001),
  topRight: new THREE.Vector3(42.00291001838892, -13.098292365950881, -50),
  bottomRight: new THREE.Vector3(50, -9.29344886739929, -6.8838697299648475),
  bottomLeft: new THREE.Vector3(-50, -10.282282821048039, -6.776537297572307),
}

export const positionsInVideoAsArray: number[] = [
  7.3195023536682127e-02, 5.4277264404296877e-01,// UL
  2.4598182678222658e-01, 3.3467358398437502e-01,// OL
  7.4441207885742189e-01, 3.2133059692382815e-01,// OR
  9.2849716186523434e-01, 5.3526721191406246e-01,// UR
]

// computed for 36317
export const serverDataFor36317: number[] = [
  1.2743986129760743e-01, 5.1410839843750000e-01,
  2.4300460815429686e-01, 3.4849066162109377e-01,
  7.3710639953613277e-01, 3.3405999755859372e-01,
  8.5920104980468748e-01, 5.0711279296875000e-01,
]

// export constructed via clicks for 36317
export const manualDataInServerFormatFor36317: number[] = [
  0.12615015433232926, 0.5170860001780402,
  0.24229607581623147, 0.3354917434654503,
  0.739318910601643, 0.32171082660899175,
  0.8628897199874288, 0.5110469489349779,
]
