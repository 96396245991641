import paper from 'paper'
const { Point } = (paper as any).__proto__
import type React from 'react'

export class VideoCanvasEvent<T extends Event, K extends React.SyntheticEvent<Element, T>> {
  private _type: string

  constructor(
    private _event: T | K,
    private _currentPlaybackTime: number,
  ) {
    this._type = this._event.type
  }

  public isSyntheticEvent = (event: Event | React.SyntheticEvent): event is React.SyntheticEvent => Boolean((event as React.SyntheticEvent).nativeEvent)

  public get event(): T | K {
    return this._event
  }

  public get nativeEvent(): T {
    return this.isSyntheticEvent(this.event) ? this.event.nativeEvent : this.event
  }

  public get currentPlaybackTime(): number {
    return this._currentPlaybackTime
  }

  public get type(): string {
    return this._type
  }

  public set type(newType: string) {
    this._type = newType
  }
}

export class VideoCanvasMouseEvent extends VideoCanvasEvent<MouseEvent, React.MouseEvent> {
  constructor(
    _event: MouseEvent | React.MouseEvent,
    _currentPlaybackTime: number,
    private _positionOnDrawingSurface: paper.Point,
    private _positionInVideo?: paper.Point,
  ) {
    super(_event, _currentPlaybackTime)
  }

  public get positionInVideo(): paper.Point {
    return this._positionInVideo ? this._positionInVideo.clone() : new Point(0, 0)
  }

  public get positionOnDrawingSurface(): paper.Point {
    return this._positionOnDrawingSurface ? this._positionOnDrawingSurface.clone() : new Point(0, 0)
  }
}

export class VideoCanvasKeyboardEvent extends VideoCanvasEvent<KeyboardEvent, React.KeyboardEvent> {}

export class VideoCanvasTouchEvent extends VideoCanvasEvent<TouchEvent, React.TouchEvent> {}
