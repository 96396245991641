import React from "react";
import i18n from "i18next";
import { Link } from "react-router-dom";
import mobiscroll from "@mobiscroll/react";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: "",
    };

    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(event) {
    this.setState({
      inputId: event.target.value,
    });
  }

  render() {
    return (
      <mobiscroll.Form className="mbsc-form-grid" theme="ios" themeVariant="light">
        <div className="mbsc-grid">
          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <mobiscroll.FormGroupTitle>{i18n.t("dashboard.toVideo.title")}</mobiscroll.FormGroupTitle>
            </div>
          </div>
          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <mobiscroll.Input
                inputStyle="box"
                type="number"
                placeholder={i18n.t("dashboard.toVideo.inputPlaceholder")} // Enter your email address
                value={this.state.inputId}
                onChange={this.handleInput}
              ></mobiscroll.Input>
            </div>
          </div>
          <div className="mbsc-row">
            <div className="mbsc-col-6">
              <div className="mbsc-btn-group-block">
                <Link to={"/match/" + this.state.inputId + "/onsite"} style={{ textDecoration: "none" }}>
                  <mobiscroll.Button disabled={this.state.inputId.length <= 0}>{i18n.t("dashboard.toVideo.btn")}</mobiscroll.Button>
                </Link>
              </div>
            </div>
            <div className="mbsc-col-6">
              <div className="mbsc-btn-group-block">
                <Link to={"/match/" + this.state.inputId} style={{ textDecoration: "none" }}>
                  <mobiscroll.Button disabled={this.state.inputId.length <= 0}>{i18n.t("dashboard.toVideo.btnRemote")}</mobiscroll.Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          DevLink:
          <Link to={"/match/43158"} style={{ textDecoration: "none" }}>
            <mobiscroll.Button>Testgame - 43158</mobiscroll.Button>
          </Link>
        </div>
      </mobiscroll.Form>
    );
  }
}
