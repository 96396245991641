import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import './progressbar.css'

function getFormattedTimeObject(tInSeconds: number) {
  const sum = Math.round(tInSeconds)
  const seconds = sum % 60
  const tmpMinutes = (sum - seconds) / 60
  const minutes = tmpMinutes % 60
  const hours = (tmpMinutes - minutes) / 60
  return {seconds, minutes, hours}
}

function getFormattedTimeString (tInSeconds: number, showHours = true) {
  const {seconds, minutes, hours} = getFormattedTimeObject(tInSeconds)

  const paddedSeconds = seconds.toString().padStart(2, '0')
  const paddedMinutes = minutes.toString().padStart(2, '0')
  const paddedHours = hours.toString().padStart(2, '0')
  if (!showHours) {
    return `${paddedMinutes}:${paddedSeconds}`
  } else {
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
  }
}

const propTypes = {
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  disableControls: PropTypes.bool.isRequired,
  disablePrecissionSeek: PropTypes.bool
}

export const Progressbar: React.FC<InferProps<typeof propTypes>> = ({ duration, currentTime, onClick, disableControls, disablePrecissionSeek }) => {
  const [mouseIsDown, setMouseIsDown] = React.useState(false)
  const [lastMouseX, setLastMouseX] = React.useState(null)
  const [lastMouseY, setLastMouseY] = React.useState(null)
  const [currentHeight, setCurrentHeight] = React.useState(10)
  const [zoomFactor, setZoomFactor] = React.useState(10)
  const [leftBound, setLeftBound] = React.useState(0.0)
  const [rightBound, setRightBound] = React.useState(duration)
  const [seekZoomed, setSeekZommed] = React.useState(null)
  const [zoomedRelTime, setZoomedRelTime] = React.useState(null)

  const cleanUpAfterDrag: Function = () => {
    setLastMouseX(null)
    setLastMouseY(null)
    setCurrentHeight(10)
    setZoomedRelTime(null)
  }

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (!disableControls) {
      setMouseIsDown(false)
      if (seekZoomed && currentHeight > 20) {
        const timeInVideo = seekZoomed / duration
        onClick(timeInVideo)
        setSeekZommed(null)
        cleanUpAfterDrag()
        return
      }
      if (!e) { return }
      const target = e.target as HTMLProgressElement
      const rect = target.getBoundingClientRect()
      const percentage = (e.pageX - rect.left) / (rect.right - rect.left)
      onClick(percentage)
    }
  }

  const handleMouseDown: React.MouseEventHandler<HTMLElement> = (e) => {
    if (!disableControls && !disablePrecissionSeek) {
      setMouseIsDown(true)
      setLastMouseY(90)
      const right = currentTime + ( (zoomFactor / 2) * stepPerPerc ) < duration ? currentTime + ( 5 * stepPerPerc ) : duration
      const left = currentTime - ( (zoomFactor / 2) * stepPerPerc) > 0 ? currentTime - ( 5 * stepPerPerc) : 0
      setRightBound(right)
      setLeftBound(left)
    }
  }

  const handleTouchOrMouseMove = (event: React.Touch | React.MouseEvent<HTMLElement>, target: HTMLProgressElement) => {
    const rect = target.getBoundingClientRect()
    const y = event.clientY - rect.top
    const x = event.clientX - rect.left
    const shouldSeek = lastMouseX !== x && (event as React.MouseEvent).currentTarget//Math.abs( lastMouseX - x ) > 5
    setLastMouseY(y)
    setLastMouseX(x)
    const currentHeight = rect.height - y
    // clamp currentHeight between 0 and 100
    setCurrentHeight(Math.min(100, Math.max(currentHeight, 0)))
    if ((rect.height - y) > 20) {
      const percentage = (event.pageX - rect.left) / (rect.right - rect.left)
      const timeSlot = rightBound - leftBound
      const stepPerPerc = timeSlot / 100
      const relTime = ((percentage * 100) * stepPerPerc)
      const time = leftBound + relTime
      setSeekZommed(time)
      setZoomedRelTime(relTime)
      if (shouldSeek) {
        onClick(seekZoomed / duration)
      }
    }
  }

  const handleMouseMove: React.MouseEventHandler<HTMLElement> = (e) => {
    if (!mouseIsDown || disableControls ) {
      return
    }
    const target = e.currentTarget as HTMLProgressElement
    handleTouchOrMouseMove(e, target)

  }

  const handleTouchStart: React.TouchEventHandler<HTMLElement> = (e) => {
    handleMouseDown(null)
  }

  const handleTouchMove: React.TouchEventHandler<HTMLElement> = (e) => {
    const touch = e.changedTouches[0]
    if (!touch) {
      return
    }
    const target = e.currentTarget as HTMLProgressElement
    handleTouchOrMouseMove(touch, target)
  }

  const handleTouchEnd: React.TouchEventHandler<HTMLElement> = (e) => {
    handleClick(null)
  }

  // const handleMouseUp: React.MouseEventHandler<HTMLElement> = (e) => {
  //   if (!disableControls) {

  //   }
  // }

  const progressInPercent = currentTime / duration * 100 || 0
  const style = mouseIsDown ? {
    height: (currentHeight),
    maxHeight: '100px',
    minHeight: '10px',
    bottom: 10
  } : {bottom: 10}
  const indicatorStyle = mouseIsDown ? {
    bottom: 20 + (currentHeight)
  } : {}
  const indicatorTopStyle = { ...indicatorStyle, left: lastMouseX + 'px' }
  const stepPerPerc = duration / 100
  let pValue = currentHeight > 20 ? zoomedRelTime : currentTime
  let pMax = currentHeight > 20 ? (rightBound - leftBound) : duration
  // let offset = leftBound % 30
  const timeIndicators = []
  if (currentHeight > 20) {
    const timeObj = getFormattedTimeObject(leftBound)
    const offset = timeObj.seconds > 30 ? 60 - timeObj.seconds : 30 - timeObj.seconds
    let count = 0
    for (let i = leftBound + offset; i < rightBound; i = i + 30) {
      const divider = count % 60 === 0 ? 4 : 6
      // const border = count % 60 === 0 ? 'solid black thick' : 'solid black'
      const border = 'solid black'
      const boundPerc = (((i - leftBound) / pMax) * 100) + '%'
      timeIndicators.push(<div className='TimeIndicator' style={{ border, left: boundPerc, height: currentHeight / divider + 'px' }}></div>)
      count += 30
    }
  }

  return <div className='progress-container'>
    <div
      className='position-indicator'
      style={{
        left: progressInPercent + '%'
      }}
    />
    <div className={`ZoomWrapper ${mouseIsDown && currentHeight > 20 ? 'active' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      // onMouseUp={handleMouseUp}
      >
    <div style={indicatorTopStyle} className='Indicator top'>{getFormattedTimeString(seekZoomed, true)}</div>
    <div style={indicatorStyle} className='Indicator left'>{getFormattedTimeString(leftBound, true)}</div>
    <div style={indicatorStyle} className='Indicator right'>{getFormattedTimeString(rightBound, true)}</div>
    {timeIndicators}
    <progress
      className='progress'
      value={pValue}
      max={pMax}
      onClick={handleClick}
      //@ts-ignore
      style={style}
    />
    </div>
     <div id='ProgressBarPlugins' />
  </div>
}

Progressbar.propTypes = propTypes

Progressbar.defaultProps = {
  onClick: () => {},
  duration: 1,
  currentTime: 0,
  disableControls: false,
  disablePrecissionSeek: false
}

export default Progressbar
