import React from 'react'
import PropTypes, { Requireable } from 'prop-types'

import PlayPauseButton from './playPauseButton/playPauseButton'
import SoundButton from './soundButton/soundButton'
import ProgressText from './progressText/progressText'
import Progressbar from './progressbar/progressbar'
import FullscreenButton from './fullscreenButton/fullscreenButton'
import SkipButton from './skipButton/skipButton'
import PlaybackRateButton from './playbackRateButton/playbackRateButton'
import QualityLevelButton from './qualityLevelButton/qualityLevelButton'

export const defaultSkipButtons = (disabled: boolean, onSkip: (...args: any[]) => void, skipDurations = {
  slow: 1,
  fast: 5,
}) => {
  const skipButtons = []

  const speeds: ['fast', 'slow'] = ['fast', 'slow']
  const directions: ['forward', 'backward'] = ['forward', 'backward']

  for (const speed of speeds) {
    for (const direction of directions) {
      skipButtons.push(<SkipButton
        speed={speed}
        skipDuration={skipDurations[speed]}
        direction={direction}
        onSkip={onSkip}
        key={skipButtons.length}
        disableControls={disabled}
      />)
    }
  }

  return skipButtons
}

const propTypes = {
  fullscreenTargetRef: PropTypes.any,
  togglePlaying: PropTypes.func,
  toggleMute: PropTypes.func,
  disabled: PropTypes.bool,
  goToPercentageOfVideoTime: PropTypes.func,
  onSkip: PropTypes.func,
  onEnterFullscreen: PropTypes.func,
  onExitFullscreen: PropTypes.func,
  isMuted: PropTypes.bool,
  isPlaying: PropTypes.bool,
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  disableSkipButtons: PropTypes.bool,
  disablePrecissionSeek: PropTypes.bool,
  displayMilliseconds: PropTypes.bool,
  showPlaybackRateSetter: PropTypes.bool,
  setPlaybackRate: PropTypes.func,
  playbackRateSteps: PropTypes.arrayOf(PropTypes.number.isRequired),
  currentPlaybackRate: PropTypes.number,
  showQualityLevelSetter: PropTypes.bool,
  qualityLevels: PropTypes.arrayOf(PropTypes.string.isRequired),
  currentQualityLevel: PropTypes.string,
  setQualityLevel: PropTypes.func as Requireable<(level: 'auto' | number) => void>,
}

// Overwrite types due to PropTypes issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34692
type ControlBarPropTypes = OverwriteProperties<PropTypes.InferProps<typeof propTypes>, {
  skipDurations?: { slow: number, fast: number }
}>

export const ControlBar: React.FC<ControlBarPropTypes> = ({
  onSkip = () => {},
  disabled = false,
  fullscreenTargetRef = { current: null },
  togglePlaying = () => {},
  toggleMute = () => {},
  goToPercentageOfVideoTime = () => {},
  onEnterFullscreen = () => {},
  onExitFullscreen = () => {},
  isMuted = false,
  duration = 0,
  currentTime = 0,
  isPlaying = false,
  disableSkipButtons = false,
  disablePrecissionSeek = false,
  displayMilliseconds = false,
  showPlaybackRateSetter = false,
  setPlaybackRate = () => {},
  playbackRateSteps = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  currentPlaybackRate = 1,
  skipDurations,
  currentQualityLevel,
  qualityLevels,
  setQualityLevel,
  showQualityLevelSetter = false,
}) => {
  if (!disabled) {
    const skipButtons = disableSkipButtons ? null : defaultSkipButtons(disabled, onSkip, skipDurations)

    return <div className='controlsContainer'>
      <div className='controls'>
        <PlayPauseButton
          isPlaying={isPlaying}
          onClick={togglePlaying}
          disableControls={disabled}
        />

        {skipButtons}

        <SoundButton
          muted={isMuted}
          onClick={toggleMute}
        />

        <ProgressText
          duration={duration}
          currentTime={currentTime}
          displayMilliseconds={displayMilliseconds}
        />
        <Progressbar
          duration={duration}
          currentTime={currentTime}
          onClick={goToPercentageOfVideoTime}
          disableControls={disabled}
          disablePrecissionSeek={disablePrecissionSeek}
        />
        <PlaybackRateButton
          setPlaybackRate={setPlaybackRate}
          playbackRateSteps={playbackRateSteps}
          showPlaybackRateSetter={showPlaybackRateSetter}
          currentPlaybackRate={currentPlaybackRate}
        />
        <QualityLevelButton
          currentQualityLevel={currentQualityLevel}
          qualityLevels={qualityLevels}
          setQualityLevel={setQualityLevel}
          showQualityLevelSetter={showQualityLevelSetter}
        />
        <FullscreenButton
          targetRef={fullscreenTargetRef}
          onEnterFullscreen={onEnterFullscreen}
          onExitFullscreen={onExitFullscreen}
        />
      </div>
    </div>
  } else {
    return null
  }
}

ControlBar.propTypes = propTypes

export default ControlBar
