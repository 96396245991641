const emptyFunction = () => {}

export const enterFullscreen = (element: HTMLElement) => {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  }
}

export const exitFullscreen = (): void => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

export const fullscreenIsActive = (): boolean => {
  return Boolean(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement)
}

export const toggleFullscreen = ({
  target = document.documentElement,
  onEnterFullscreen = emptyFunction,
  onExitFullscreen = emptyFunction,
}: {
  target?: HTMLElement,
  onEnterFullscreen?: Function,
  onExitFullscreen?: Function,
}): void => {
  if (fullscreenIsActive()) {
    exitFullscreen()
    onExitFullscreen()
  } else {
    enterFullscreen(target)
    onEnterFullscreen()
  }
}
