import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import de from './de'
import en from './en'

import { initReactI18next } from 'react-i18next'

import { EVENTTYPELIST, EVENTSETTINGS, EVENTGROUPS, GENERICLang } from '../components/pages/editor/tagConfig'

// import helper for extern translate data
const addEventTranslation = (obj, subobject, name, type, de, en) => {
  const translateDe = {}
  const translateEn = {}

  if (!Array.isArray(obj)) {
    for (const eventType in obj) {
      const checkSubObject = !subobject ? obj[eventType] : obj[eventType][subobject]
      checkSubObject.map((itm) => {
        translateDe[eventType] = translateDe[eventType] ? translateDe[eventType] : {}
        translateEn[eventType] = translateEn[eventType] ? translateEn[eventType] : {}
        translateDe[eventType][itm[type]] = itm.name
        translateEn[eventType][itm[type]] = itm.nameEn
        return null
      })
    }
  } else {
    obj.map((itm) => {
      translateDe[itm[type]] = itm.name
      translateEn[itm[type]] = itm.nameEn
      return null
    })
  }

  return { de: { ...de, [name]: translateDe }, en: { ...en, [name]: translateEn } }
}

// merge eventTypes / eventCategorys / eventGroups "tag module"
let addLanguage = addEventTranslation(EVENTTYPELIST, null, 'eventType', 'eventType', de, en)
addLanguage = addEventTranslation(EVENTSETTINGS, 'eventCategory', 'eventCategory', 'id', addLanguage.de, addLanguage.en)
addLanguage = addEventTranslation(EVENTSETTINGS, 'eventTime', 'eventTime', 'id', addLanguage.de, addLanguage.en)
addLanguage = addEventTranslation(EVENTGROUPS, null, 'eventGroups', 'id', addLanguage.de, addLanguage.en)
const mergeDe = { ...addLanguage.de, eventTypeGeneric: GENERICLang.de }
const mergeEn = { ...addLanguage.en, eventTypeGeneric: GENERICLang.en }
// merge end

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: mergeEn
      },
      de: {
        translations: mergeDe
      }
    },
    fallbackLng: 'en',
    // debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
