import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import PropTypes, { InferProps } from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './playPauseButton.css'

const propTypes = {
  isPlaying: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disableControls: PropTypes.bool,
}

export const PlayPauseButton: React.FC<InferProps<typeof propTypes>> = ({ isPlaying, onClick, disableControls }) => {
  const clickHandler = useCallback((event: React.MouseEvent) => {
    if (!disableControls) {
      onClick(event)
    }
  }, [disableControls, onClick])

  return <ControlsButton
    className='play-pause'
    onClick={clickHandler}
  >
    {
      isPlaying
        ? (<FontAwesomeIcon icon={faPause} />)
        : (<FontAwesomeIcon icon={faPlay} />)
    }
  </ControlsButton>
}

PlayPauseButton.propTypes = propTypes

PlayPauseButton.defaultProps = {
  onClick: () => {},
}

export default PlayPauseButton
