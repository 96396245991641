import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './soundButton.css'

const propTypes = {
  muted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export const SoundButton: React.FC<PropTypes.InferProps<typeof propTypes>> = ({
  muted,
  onClick = () => {},
}) => {
  let icon = muted ? faVolumeMute : faVolumeUp

  return <ControlsButton onClick={onClick} className="sound-button" >
    <FontAwesomeIcon icon={icon} />
  </ControlsButton>
}

SoundButton.propTypes = propTypes

export default SoundButton
