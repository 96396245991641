import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ControlBar } from './controlBar'

function getIosSaveDuration(video: HTMLVideoElement) {
  let dur = video.duration
  if(dur !== undefined && !isNaN(dur) && isFinite(dur)) {
    return dur
  } else {
    dur = video.currentTime
    if(video.seekable && video.seekable.length > 0 && video.seekable.end(0) > dur){
      dur = video.seekable.end(0)
    }
    if(video.buffered && video.buffered.length > 0 && video.buffered.end(0) > dur){
      dur = video.buffered.end(0)
    }
    if(dur !== undefined && !isNaN(dur) && isFinite(dur)) {
      return dur
    }
    console.warn('Could not get Duration from Video', video)
    return 0
  }
}

const ControlBarForHtmlVideoPropTypes = {
  ...ControlBar.propTypes,
  fullscreenTargetRef: PropTypes.any,
  disabled: PropTypes.bool,
  videoRef: PropTypes.shape({ current: PropTypes.oneOfType([ PropTypes.instanceOf(HTMLVideoElement) ]) }),
  getVideo: PropTypes.func,
  video: PropTypes.instanceOf(HTMLVideoElement),//shape(HTMLVideoElement),
  showPlaybackRateSetter: PropTypes.bool,
  playbackRateSteps: PropTypes.arrayOf(PropTypes.number.isRequired),
}

type ControlBarForHtmlVideoPropsType = PropTypes.InferProps<typeof ControlBarForHtmlVideoPropTypes>

const getVideo = (props: ControlBarForHtmlVideoPropsType): HTMLVideoElement | null => {
  if (props.video) {
    return props.video
  } else if (props.getVideo) {
    return props.getVideo()
  } else if (props.videoRef) {
    return props.videoRef.current
  } else {
    return null
  }
}

const useVideoEventHandlers = ({ setIsPlaying, setIsMuted, setDuration, setCurrentTime, setCurrentPlaybackRate, video }: {
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>,
  setDuration: React.Dispatch<React.SetStateAction<number>>,
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>,
  setCurrentPlaybackRate: React.Dispatch<React.SetStateAction<number>>,
  video: HTMLVideoElement,
}) => {
  const onPlay = useCallback(() => setIsPlaying(true), [])

  const onPause = useCallback(() => setIsPlaying(false), [])

  const updateVideoStats = useCallback(() => {
    if (video) {
      setIsPlaying(!video.paused)

      setIsMuted(video.muted)

      setDuration(getIosSaveDuration(video))

      if (!Number.isNaN(video.currentTime)) {
        setCurrentTime(video.currentTime)
      }

      if (!Number.isNaN(video.playbackRate)) {
        setCurrentPlaybackRate(video.playbackRate)
      }
    }
  }, [video, setIsMuted, setIsPlaying, setDuration, setCurrentTime, setCurrentPlaybackRate])

  const eventListenerConfig = useMemo(() => [
    { eventName: 'canplay', handler: updateVideoStats },
    { eventName: 'durationchange', handler: updateVideoStats },
    { eventName: 'play', handler: onPlay },
    { eventName: 'pause', handler: onPause },
    { eventName: 'progress', handler: updateVideoStats },
    { eventName: 'timeupdate', handler: updateVideoStats },
    { eventName: 'ratechange', handler: updateVideoStats },
  ], [updateVideoStats, onPlay, onPause])

  useEffect(updateVideoStats, [])

  useEffect(() => {
    if (video) {
      eventListenerConfig.forEach(({ eventName, handler }) => video.addEventListener(eventName, handler))

      return () => {
        eventListenerConfig.forEach(({ eventName, handler }) => video.removeEventListener(eventName, handler))
      }
    }
  }, [video, eventListenerConfig])
}

export const ControlBarForHtmlVideo: React.FC<ControlBarForHtmlVideoPropsType> = (props) => {
  const [isMuted, setIsMuted] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState(1)

  const video: HTMLVideoElement | null = getVideo(props)

  const onSkip = useCallback((delta: number) => {
    if (video) {
      video.currentTime += delta
      video.playbackRate = 1
    }
  }, [video])

  const togglePlaying = useCallback(() => {
    if(video) {
      if (!video.paused) {
        video.pause()
      } else {
        video.play()
      }
    }
  }, [video])

  const toggleMute = useCallback(() => {
    if (video) {
      setIsMuted(!video.muted)
      video.muted = !video.muted
    }
  }, [video, setIsMuted])

  const goToPercentageOfVideoTime = useCallback((percentage: number) => {
    if (video) {
      video.currentTime = duration * percentage
    }
  }, [video, duration])

  const setPlaybackRate = useCallback((playbackRate: number) => {
    if (playbackRate <= 0) {
      throw new Error(`Playback rate must be larger than 0`)
    } else if (!video) {
      throw new Error(`Can't set playback rate because video hasn't been mounted`)
    } else {
      video.playbackRate = playbackRate
    }
  }, [video])

  useVideoEventHandlers({ setIsPlaying, setIsMuted, setDuration, setCurrentTime, setCurrentPlaybackRate, video })

  return <ControlBar
    {...props}
    isMuted={isMuted}
    duration={duration}
    currentTime={currentTime}
    isPlaying={isPlaying}
    onSkip={onSkip}
    togglePlaying={togglePlaying}
    toggleMute={toggleMute}
    goToPercentageOfVideoTime={goToPercentageOfVideoTime}
    disablePrecissionSeek={props.disablePrecissionSeek}
    showPlaybackRateSetter={props.showPlaybackRateSetter}
    setPlaybackRate={setPlaybackRate}
    playbackRateSteps={props.playbackRateSteps}
    currentPlaybackRate={currentPlaybackRate}
    skipDurations={props.skipDurations}
  />
}

export default ControlBarForHtmlVideo
