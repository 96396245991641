import { toggleFullscreen } from '../controls/fullscreenButton/toggleFullscreen'
import type { VideoController } from '../videoController'
import type { KeyboardShortcuts } from './KeyboardShortcuts'

export const generateShortcuts = ({ togglePlaying, shouldPlayPauseOnSpace, getRootContainer, videoCanvas }: {
  togglePlaying: () => void,
  shouldPlayPauseOnSpace: () => boolean,
  getRootContainer: () => HTMLElement,
  videoCanvas: VideoController,
}): KeyboardShortcuts => {
  const animateSkipBySeconds = (secondsToSkip: number, speed: number) => {
    videoCanvas.playUntilTime({
      endTime: Math.min(videoCanvas.state.currentTime + secondsToSkip, videoCanvas.state.duration),
      // Speed must be a whole number between 0 and 16 (both included)
      speed: Math.min(16, Math.max(speed * secondsToSkip, 0))
    })
  }

  const skipBySeconds = (secondsToSkip: number) => {
    videoCanvas.goToTime(Math.min(videoCanvas.state.currentTime + secondsToSkip, videoCanvas.state.duration))
    videoCanvas.playbackRate = 1
  }

  const createAnimateSkipBySecondsHandler = (secondsToSkip: number, speed: number) => () => animateSkipBySeconds(secondsToSkip, speed)

  const createSkipBySecondsHandler = (secondsToSkip: number) => () => skipBySeconds(secondsToSkip)

  return {
    'one-second-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: false,
        altKey: false
      },
      handler: createSkipBySecondsHandler(1)
    },
    'animate-one-second-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: true,
        altKey: false
      },
      handler: createAnimateSkipBySecondsHandler(1, 4)
    },
    'one-second-skip-backward': {
      code: 'KeyA',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        altKey: false
      },
      handler: createSkipBySecondsHandler(-1)
    },
    'five-seconds-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: false,
        altKey: true
      },
      handler: (event) => {
        // Alt + D = chrome focuses on URL
        event.preventDefault()
        event.stopPropagation()

        skipBySeconds(5)
      }
    },
    'animate-five-seconds-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: true,
        altKey: true
      },
      handler: createAnimateSkipBySecondsHandler(5, 2)
    },
    'five-seconds-skip-backward': {
      code: 'KeyA',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        altKey: true
      },
      handler: createSkipBySecondsHandler(-5)
    },
    'twenty-seconds-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: true,
        shiftKey: false,
        altKey: false
      },
      handler: (e) => {
        e.preventDefault()
        e.stopPropagation()

        skipBySeconds(20)
      }
    },
    'animate-twenty-seconds-skip-forward': {
      code: 'KeyD',
      requiresOtherPressedKeys: {
        ctrlKey: true,
        shiftKey: true,
        altKey: false
      },
      handler: (e) => {
        e.preventDefault()
        e.stopPropagation()

        animateSkipBySeconds(20, 16)
      }
    },
    'twenty-seconds-skip-backward': {
      code: 'KeyA',
      requiresOtherPressedKeys: {
        ctrlKey: true,
        altKey: false
      },
      handler: (e) => {
        e.preventDefault()
        e.stopPropagation()

        skipBySeconds(-20)
      }
    },
    'toggle-fullscreen-f': {
      code: 'KeyF',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: false,
        altKey: false
      },
      handler: () => toggleFullscreen({ target: getRootContainer() })
    },
    'play-pause-space': {
      code: 'Space',
      requiresOtherPressedKeys: {
        ctrlKey: false,
        shiftKey: false,
        altKey: false
      },
      handler: (event) => {
        if (shouldPlayPauseOnSpace()) {
          if (typeof event.preventDefault === 'function') {
            event.preventDefault()
          }

          togglePlaying()
        }
      }
    }
  }
}
