import React from "react";
import mobiscroll from "@mobiscroll/react";
import i18n from "i18next";
import Loader from "../helper/loadingSpinner";

import { Link } from "react-router-dom";

import "./appHeader.scss";
import { postMetaId } from "../../api/api-video";

export default class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: i18n.language,
    };
    this.handleLanguage = this.handleLanguage.bind(this);
  }

  handleLanguage() {
    const lang = this.state.lang === "de" ? "en" : "de";
    i18n.changeLanguage(lang);
    this.setState({
      lang: lang,
    });
  }

  handleStopRecording = async (result) => {
    if (result) {
      let msg = ''
      const body = {
        RowKey: String(this.props.videoId),
        stopRequested: true
      }

      await postMetaId(body, this.props.videoId).then(res => {
        if (res.status === 200) {
          msg =  'Aufnahme Erfolgreich beendet' 
        } else {
          msg = 'Fehler - Aufnahme konnte nicht beendet werden' 
        }
      }).catch(err => {
        msg = 'Error - Aufnahme konnte nicht beendet werden'
      })
    }
  }

  handleConfirmStopRecording = () => {
    mobiscroll.confirm({
      title: 'Aufnahme Beenden',
      message: 'Sind sie sicher?',
      okText: 'Ja',
      cancelText: 'Nein',
      callback: this.handleStopRecording
    })
  }

  render() {
    const title = this.props.title;
    const col = this.props.mbscCol;

    return (
      <>
        <div className="app-header">
          {this.props.onBackBtn ? (
            <div className="header-btnContainer-left">
              {this.props.menu ? <span onClick={this.props.menu} className="mbsc-ic mbsc-ic-material-menu" /> : null}

              <Link to={this.props.onBackBtn.to}>
                <span className="mbsc-ic mbsc-ic-arrow-left2" />
              </Link>
            </div>
          ) : null}

          <h3>{title}</h3>
          <div className="header-btnContainer">
            {this.props.loading ? (
              <div>
                <Loader />
              </div>
            ) : null}
            {this.props.fullContent &&  this.props.videoState && this.props.videoState !== 'done' ? (
              <mobiscroll.Button outline className="btnContainer-btn" color="dark" onClick={this.handleConfirmStopRecording}>
                Aufnahme Beenden
              </mobiscroll.Button>
            ) : undefined}
            <mobiscroll.Button outline className="btnContainer-btn" color="dark" onClick={this.handleLanguage}>
              {this.state.lang}
            </mobiscroll.Button>
          </div>
        </div>
        {this.props.fullContent ? (
          <div style={{ paddingTop: 42 }}>
            <div>
              <div className={col}>{this.props.children}</div>
            </div>
          </div>
        ) : (
          <div className="mbsc-grid mbsc-no-padding" style={{ paddingTop: 60 }}>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className={col}>{this.props.children}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}
