import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './fullscreenButton.css'
import { toggleFullscreen } from './toggleFullscreen'

const propTypes = {
  onEnterFullscreen: PropTypes.func,
  onExitFullscreen: PropTypes.func,
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }),
}

export const FullscreenButton: React.FC<PropTypes.InferProps<typeof propTypes>> = ({ targetRef = { current: document.documentElement }, onEnterFullscreen = () => {}, onExitFullscreen = () => {} }) => {
  const clickHandler = () => {
    toggleFullscreen({
      target: targetRef.current,
      onEnterFullscreen,
      onExitFullscreen
    })
  }

  return <ControlsButton
    className='fullscreen'
    onClick={clickHandler}
  >
    <FontAwesomeIcon icon={faExpand} />
  </ControlsButton>
}

FullscreenButton.propTypes = propTypes

export default FullscreenButton
