import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ControlsButton from '../controlsButton/controlsButton'
import './playbackRateButton.css'

const propTypes = {
  setPlaybackRate: PropTypes.func.isRequired,
  playbackRateSteps: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  showPlaybackRateSetter: PropTypes.bool.isRequired,
  currentPlaybackRate: PropTypes.number.isRequired,
}

export const PlaybackRateButton: React.FC<PropTypes.InferProps<typeof propTypes>> = ({
  setPlaybackRate,
  playbackRateSteps,
  showPlaybackRateSetter,
  currentPlaybackRate,
}) => {
  const [currentlySelectingPlaybackRate, setCurrentlySelectingPlaybackRate] = useState(false)

  const clickHandler = (newPlaybackRate: number) => {
    setPlaybackRate(newPlaybackRate)
    setCurrentlySelectingPlaybackRate(false)
  }

  return showPlaybackRateSetter ? <>
    <ControlsButton
      className='playback-rate'
      onClick={() => setCurrentlySelectingPlaybackRate(!currentlySelectingPlaybackRate)}
    >
      {currentPlaybackRate}x
    </ControlsButton>
    {currentlySelectingPlaybackRate ? <div
      className={'playback-rate-selection-container'}
    >
      {playbackRateSteps.map((step, index) => <div
        key={index}
        onClick={() => clickHandler(step)}
        className={`playback-rate-option ${currentPlaybackRate === step ? 'selected' : ''}`}
      >
        {step}x
      </div>)}
    </div> : null}
  </> : null
}

PlaybackRateButton.propTypes = propTypes

export default PlaybackRateButton
