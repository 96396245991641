import React from "react";
import i18n from "i18next";
import AppHeader from "../helper/appHeader";
import Loader from "../helper/loadingSpinner";
import Sidebar from "./container/sidebar/sidebar";
import Tagcontrol from "./container/tagcontrol/tagcontrol";
import Overlay from "./container/overlay/editoroverlay";
import mobiscroll from "@mobiscroll/react";
import * as VC from "@soccerwatch/videocanvas";
import AiswPlayer from "./container/player/player";

import { Swipeable } from "react-swipeable";
import { getMeta } from "../../api/api-video";
import { deleteTag, getTags, getAiTags } from "../../api/api-tag";

import { calculateEventTime, mergeTagsCalcMeta, swapAiTags } from '@soccerwatch/common'

import "./editor.scss";

const VideoCanvas = VC.Video;
const PlayPauseOnOptions = VC.VideoController.playPauseOnOptions

const brakepoint = {
  tablet: 1200,
};

const config = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: true, // track mouse input
  rotationAngle: 0, // set a rotation angle
};

export default class Match extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remote: true,
      loadingEditor: true,
      loading: false,
      id: this.getVideoIdFromUrl() || 0, //test video 43158 / 10252 // this.getVideoIdFromUrl() || 0,
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      tablet: brakepoint.tablet <= window.innerWidth,
      matchInfo: undefined,
      matchTags: undefined,
      ratioWidth: 0,
      videoCurrentTime: 0,
      activeTag: undefined,
      teamColor: {
        home: "#005380",
        guest: "#ff0000",
      },
      mobileSidebarOpen: false,
      tz: 0,
      tempDeltaX: 0,
    };

    this.videoReady = this.videoReady.bind(this);
    this.handleLoadingState = this.handleLoadingState.bind(this);
    this.getVideoTagsReload = this.getVideoTagsReload.bind(this);
    this.deleteLastTags = this.deleteLastTags.bind(this);
    this.getVideoInformationsAndTags = this.getVideoInformationsAndTags.bind(this);
    this.openCloseSidebar = this.openCloseSidebar.bind(this);
    this.handleRealTimeNotifier = this.handleRealTimeNotifier.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.deliverPlugins = this.deliverPlugins.bind(this)
  }

  handleRealTimeNotifier(){
    if((this.state.matchInfo||{}).cameraStart!== undefined){
      let videoTime = (new Date().getTime()-this.state.matchInfo.cameraStart)/1000
      this.notifyVideoProgress(videoTime)
    }
  }

  async getVideoInformationsAndTags() {
    const matchId = this.state.id;

    // get video information
    getMeta(matchId)
      .then((res) => {
        const matchInfo = res.data;
        // check if prop:cameraStart is present
        if (!this.state.remote && matchInfo.cameraStart === undefined) {
          //error cameraStart not defined
          mobiscroll.toast({ message: "videoinfo error: cameraStart not available", display: "bottom", color: "warning" });
          this.props.history.push("/");
          return;
        }

        // get tags from video
        getTags(matchId)
          .then((res) => {
            let matchTags = res;
            matchTags = matchTags.map(tag => {
              if(tag.eventType === 1001) {
                return { ...tag, timestamp: 0 }
              }
              return tag
            })
            matchTags.sort((a,b) => a.timestamp - b.timestamp)
            // setState video information & tags
            this.setState({
              matchInfo,
              // matchTags,
              // loadingEditor: false,
              // loading: false,
            });

            this.getAiTagsFromKI(matchId, matchTags)
          })
          .catch((err) => {
            this.props.history.push("/");
            mobiscroll.toast({ message: err, display: "bottom", color: "warning" });
            console.log("tags not found ", err);
          });
      })
      .catch((err) => {
        this.props.history.push("/");
        mobiscroll.toast({ message: err, display: "bottom", color: "warning" });
        console.log("info not found ", err);
      });
  }

  async getAiTagsFromKI(matchId, normalTags) {
    getAiTags(matchId).then((res) =>  {
      res = res.map(tag => {
        tag._aiTag = true
        return tag
      })

      const clubALeftVote = this.state.matchInfo.clubALeftVote
      let clubALeft = this.state.matchInfo.clubALeft
      if(this.state.matchInfo.clubALeft === undefined && clubALeftVote !== undefined) {
        clubALeft = clubALeftVote
      }

      const resSwap = swapAiTags(res, clubALeft)
      const newTags = normalTags.concat(resSwap).sort((a,b) => a.timestamp - b.timestamp)

      this.setState({
        matchTags: newTags,
        loadingEditor: false,
        loading: false,
      })
    }).catch(err => {
      this.setState({
        matchTags: normalTags,
        loadingEditor: false,
        loading: false,
      })
    })
  }

  async getVideoTagsReload() {
    const matchId = this.state.id;

    // set loadingspinner
    this.setState({
      loading: true,
    });

    // get video Tags
    getTags(matchId)
      .then((res) => {
        let matchTags = res;
        matchTags = matchTags.map(tag => {
          if(tag.eventType === 1001) {
            return { ...tag, timestamp: 0 }
          }
          return tag
        })
        matchTags.sort((a,b) => a.timestamp - b.timestamp)

        // setState video information & tags
        this.setState({
          // matchTags,
          // loadingEditor: false,
          // loading: false,
        });

        this.getAiTagsFromKI(matchId, matchTags)
      })
      .catch((err) => {
        this.props.history.push("/");
        mobiscroll.toast({ message: err, display: "bottom", color: "warning" });
        console.log("tags not found ", err);
      });
  }

  async deleteLastTags() {
    if(!this.state.matchTags || this.state.matchTags.length ===0){return}
    let newest = this.state.matchTags.reduce((prev,cur)=>{
      return cur.timestamp>prev.timestamp?cur:prev
    },this.state.matchTags[0])
    const matchId = this.state.id;
    await deleteTag(matchId, newest.id, this.props.user)
    this.getVideoTagsReload()
  }

  componentDidMount() {
    const checkOnsite = this.props.location.pathname.search("/onsite") >= 0;
    if (checkOnsite) {
      this.setState({ remote: false });
    }

    this.props.onEditorActive(true);
    this.getVideoInformationsAndTags();
    window.addEventListener("resize", this.updateDimensions);

    // set liveload all 10 sec
    this.timeout = setInterval(
      function () {
        this.getVideoTagsReload()
      }.bind(this),
      10000
    );
    if(checkOnsite){
      this.realTimeUpdateInterval = setInterval(
        function () {
          this.handleRealTimeNotifier()
        }.bind(this),
        1000
      );
    }

  }

  componentWillUnmount() {
    this.props.onEditorActive(false);
    // remove removeEventListener
    window.removeEventListener("resize", this.updateDimensions);
    // remove liveload
    clearInterval(this.timeout)
    clearInterval(this.realTimeUpdateInterval)
  }

  updateDimensions() {
    this.setState({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      tablet: brakepoint.tablet <= window.innerWidth ? true : false,
    });
  };

  getVideoIdFromUrl() {
    const pathname = this.props.location.pathname;
    const path = this.props.match.path;
    let id = pathname.replace(path, "").replace(/[^0-9]/g, "");
    return id.length > 0 ? id : this.props.history.push("/");
  }

  deliverPlugins(videoParent, videoState, video, paper, drawingLayer) {
    if (video && (!this.videoRef || this.videoRef.src !== video.src)) {
      this.videoRef = video;
    }    
  };

  handleSeekTo = (time) => {
    if (this.videoRef) {
      this.videoRef.jumpToTimestamp(time)
    }
  };

  handleLoadingState() {
    this.setState({
      loading: true,
    })
  }

  notifyVideoProgress = (currentTime) => {
    const { matchTags, activeTag } = this.state;
    const tagsMerged = mergeTagsCalcMeta(matchTags.filter(a => !a._aiTag), matchTags.filter(a => a._aiTag))
    const matchTime = calculateEventTime(currentTime*1000, tagsMerged)

    if (matchTime) {
      this.setState({
        currentTagTime: matchTime,
      })
    }

    // search and set active Tag
    if (activeTag !== undefined && matchTags.length > 0 && matchTags[0].timestamp > currentTime) {
      this.setState({ activeTag: undefined });
    }

    matchTags.map((tag, i) => {
      if (matchTags[i + 1]) {
        if (!(tag.timestamp > currentTime) && !(matchTags[i + 1].timestamp <= currentTime)) {
          if (activeTag && tag.RowKey !== activeTag.RowKey) {
            this.setState({ activeTag: tag });
          } else if (activeTag === undefined && !(tag.timestamp > currentTime)) {
            this.setState({ activeTag: tag });
          }
        }
      } else {
        if (activeTag && !(tag.timestamp > currentTime) && tag.RowKey !== activeTag.RowKey) {
          this.setState({ activeTag: tag });
        }
      }

      return null;
    });
  };

  videoReady() {
    if (!this.state.videoReady) {
      this.setState({
        videoReady: true,
      });
    }
  }

  onSwiping(event) {
    const leftOrRightCheck = this.state.tempDeltaX - event.deltaX;
    const beginningByPixel = 50;

    if (event.absX > beginningByPixel && !this.state.tablet && this.state.tz <= 300) {
      if (leftOrRightCheck < 0) {
        this.setState({
          tempDeltaX: event.deltaX,
          lastMove: "left",
          tz: this.state.tz + leftOrRightCheck,
        });
      } else {
        this.setState({
          tempDeltaX: event.deltaX,
          lastMove: "right",
          tz: this.state.tz + leftOrRightCheck,
        });
      }
    }
  }

  onSwiped(event) {
    if (!this.state.tablet) {
      if (this.state.lastMove === "left") {
        this.setState({
          tz: 0,
        });
      } else {
        this.setState({
          tz: 300,
        });
      }
    }
  }

  openCloseSidebar() {
    if (this.state.tz > 250) {
      this.setState({ tz: 0 });
    } else {
      this.setState({ tz: 300 });
    }
  }

  render() {
    const { matchTags, matchInfo, activeTag, remote } = this.state;
    const remoteControlHeight = remote ? 250 : "100%";

    return (
      <Swipeable
        onSwipedLeft={(event) => this.onSwiped(event)}
        onSwipedRight={(event) => this.onSwiped(event)}
        /*onSwiped={(event) => {
          this.onSwiped(event);
        }}*/
        onSwiping={(event) => {
          this.onSwiping(event);
        }}
        {...config}
      >
        <div className="ts" />
        <AppHeader
          title={(remote ? i18n.t("nav.remote") : i18n.t("nav.onsite")) + " : " + this.state.id + ' : ' + this.state.matchInfo?.eventType || "soccer"}
          onBackBtn={{ to: "/" }}
          fullContent
          loading={this.state.loading}
          menu={!this.state.tablet ? this.openCloseSidebar : undefined}
          videoId={this.state.id}
          videoState={this.state.matchInfo?.state || ''}
        >
          {this.state.loadingEditor ? (
            <div className="match" style={{ height: this.state.innerHeight - 42 }}>
              <Loader />
            </div>
          ) : (
              <div
                className="match"
                style={{
                  height: this.state.innerHeight - 42,
                }}
              >
                <div className="match-container" style={{ overflow: "hidden" }}>
                  {this.state.tablet || this.state.tz >= 0 ? (
                    <div
                      className="container-sidebar"
                      id="container-sidebar"
                      style={this.state.tablet ? { flex: "0 0 300px" } : { flex: "0 0 " + this.state.tz + "px" }}
                    >
                      <Sidebar
                        matchId={this.state.id}
                        tags={this.state.matchTags}
                        activeTag={activeTag}
                        onSeekTo={this.handleSeekTo}
                        getVideoTagsReload={this.getVideoTagsReload}
                        onLoadingState={this.handleLoadingState}
                        teamColor={this.state.teamColor}
                        eventType={this.state.matchInfo.eventType || "soccer"}
                        user={this.props.user}
                        matchInfo={this.state.matchInfo}
                        getVideoInformationsAndTags={this.getVideoInformationsAndTags}
                      />
                    </div>
                  ) : null}
                  <div
                    className="container-content"
                    style={{
                      height: remote ? (this.state.innerHeight <= 600 ? this.state.innerHeight - 42 : this.state.innerHeight - 292) : "100%",
                    }}
                  >

                    {remote&& true ? (
                      <AiswPlayer
                        matchId={this.state.id}
                        video={matchInfo}
                        onVideoProgress={this.notifyVideoProgress}
                        evaluatePlugins={this.deliverPlugins}
                      >
                        <Overlay
                          matchTags={matchTags}
                          matchInfo={matchInfo}
                          matchId={this.state.id}
                          getVideoTagsReload={this.getVideoTagsReload}
                          videoRef={this.videoRef}
                          eventType={this.state.matchInfo.eventType || "soccer"}
                          currentTagTime={this.state.currentTagTime}
                          remote={this.state.remote}
                          user={this.props.user}
                        />
                      </AiswPlayer>
                    ) : null}

                    {!remote || this.state.innerHeight >= 600 ? (
                      <div
                        style={{
                          height: remoteControlHeight,
                          backgroundColor: "#fcfcfc",
                        }}
                      >
                        {!remote ? (
                          <div>
                            <Overlay
                              cameraStart={matchInfo.cameraStart}
                              matchTags={matchTags}
                              matchInfo={matchInfo}
                              matchId={this.state.id}
                              getVideoTagsReload={this.getVideoTagsReload}
                              videoRef={this.videoRef}
                              remote={this.state.remote}
                              eventType={this.state.matchInfo.eventType || "soccer"}
                              currentTagTime={this.state.currentTagTime}
                              user={this.props.user}
                            />
                           </div>
                          ) : null}
                        <Tagcontrol
                          teamColor={this.state.teamColor}
                          matchId={this.state.id}
                          getVideoTagsReload={this.getVideoTagsReload}
                          deleteLastTags={this.deleteLastTags}
                          videoRef={this.videoRef}
                          eventType={matchInfo.eventType || "soccer"}
                          did={matchInfo.did}
                          remote={this.state.remote}
                          cameraStart={matchInfo.cameraStart}
                          user={this.props.user}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
        </AppHeader>
      </Swipeable>
    );
  }
}
